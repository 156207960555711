import React from 'react';
import { IIconProps } from '..';

const QueueingSettingBrand: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.7164 7.61019C14.7781 7.61019 15.6388 6.57817 15.6388 5.3051C15.6388 4.03203 14.7781 3 13.7164 3C12.6547 3 11.7939 4.03203 11.7939 5.3051C11.7939 6.57817 12.6547 7.61019 13.7164 7.61019Z" stroke="currentColor" strokeMiterlimit="10" />
        <path d="M15.9052 8.34156C15.8249 8.32241 15.7409 8.32539 15.6622 8.35018C15.5835 8.37498 15.513 8.42067 15.4582 8.48237C15.2339 8.70225 14.9652 8.8717 14.6701 8.97939C14.375 9.08709 14.0603 9.13055 13.747 9.10686C13.4342 9.13239 13.1195 9.09099 12.8239 8.98542C12.5283 8.87985 12.2586 8.71254 12.0327 8.49462C11.9799 8.42822 11.9091 8.37846 11.8288 8.35131C11.7484 8.32417 11.6619 8.32078 11.5797 8.34156C10.3552 8.6936 10.6215 8.74258 10.7776 8.81605C12.0909 9.42829 14.7848 10.9589 14.7388 12.7007V14.0354H17.7756C18.4521 14.0354 19.0001 13.3742 19.0001 12.5599V10.8701C19.0031 9.7436 17.7327 8.77625 15.9052 8.34156Z" stroke="currentColor" strokeMiterlimit="10" />
        <path d="M7.06447 9.68928C8.28851 9.68928 9.28079 8.49963 9.28079 7.03214C9.28079 5.56464 8.28851 4.375 7.06447 4.375C5.84043 4.375 4.84814 5.56464 4.84814 7.03214C4.84814 8.49963 5.84043 9.68928 7.06447 9.68928Z" stroke="currentColor" strokeMiterlimit="10" />
        <path d="M9.55304 10.4045C9.4609 10.3833 9.36478 10.3869 9.27445 10.4148C9.18413 10.4428 9.10281 10.4942 9.03876 10.5637C8.7942 10.8479 8.49119 11.0759 8.15043 11.2322C7.80966 11.3885 7.43918 11.4694 7.06427 11.4694C6.68937 11.4694 6.31889 11.3885 5.97812 11.2322C5.63736 11.0759 5.33435 10.8479 5.08979 10.5637C5.02527 10.4939 4.94342 10.4424 4.85256 10.4144C4.76169 10.3865 4.66505 10.3831 4.57244 10.4045C2.46939 10.9035 1 12.0178 1 13.3158V15.2658C1 16.2056 1.63061 16.9678 2.41122 16.9678H11.7173C12.4949 16.9678 13.1255 16.2056 13.1255 15.2658V13.3158C13.1255 12.0178 11.6592 10.9035 9.55304 10.4045Z" stroke="currentColor" strokeMiterlimit="10" />
      </svg>
    </div>
  );
}

export default QueueingSettingBrand;
