import React from 'react';
import { IIconProps } from '..';

const MinusCircleOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.909091 10C0.909091 15.0208 4.97923 19.0909 10 19.0909C15.0208 19.0909 19.0909 15.0208 19.0909 10C19.0909 4.97923 15.0208 0.909091 10 0.909091C4.97923 0.909091 0.909091 4.97923 0.909091 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z" fill="currentColor"/>
        <path d="M12.9552 9.54545H7.04615C6.66959 9.54545 6.36433 9.85072 6.36433 10.2273C6.36433 10.6038 6.66959 10.9091 7.04615 10.9091H12.9552C13.3318 10.9091 13.6371 10.6038 13.6371 10.2273C13.6371 9.85072 13.3318 9.54545 12.9552 9.54545Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default MinusCircleOutlined;
