import React from 'react';
import { IIconProps } from '..';

const DeliveryOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.73326 3.73242V5.87528H4.91749L4.48014 7.18734C4.36838 7.52262 4.14805 7.81108 3.85399 8.00713L2.30469 9.03999V10.5539H16.9833V3.73242H8.73326ZM6.98326 3.57171C6.98326 2.69397 7.69481 1.98242 8.57254 1.98242H17.144C18.0217 1.98242 18.7333 2.69397 18.7333 3.57171V10.7146C18.7333 11.5923 18.0217 12.3039 17.144 12.3039H2.14397C1.26623 12.3039 0.554688 11.5923 0.554688 10.7146V8.95398C0.554688 8.4226 0.820259 7.92637 1.2624 7.63162L2.83738 6.58162L3.29393 5.21199C3.51025 4.56302 4.11758 4.12528 4.80166 4.12528H6.98326V3.57171Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.554688 13.9998V9.99976H2.30469V13.4105H16.9833V9.99976H18.7333V13.9998C18.7333 14.6408 18.2136 15.1605 17.5725 15.1605H1.7154C1.07436 15.1605 0.554688 14.6408 0.554688 13.9998Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.98117 11.8748V4.28549H8.73117V11.8748H6.98117Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.00042 15.6424C4.56648 15.6424 4.2147 15.9942 4.2147 16.4282C4.2147 16.8621 4.56648 17.2139 5.00042 17.2139C5.43436 17.2139 5.78613 16.8621 5.78613 16.4282C5.78613 15.9942 5.43436 15.6424 5.00042 15.6424ZM7.2147 16.4282C7.2147 17.6511 6.22333 18.6424 5.00042 18.6424C3.7775 18.6424 2.78613 17.6511 2.78613 16.4282C2.78613 15.2052 3.7775 14.2139 5.00042 14.2139C6.22333 14.2139 7.2147 15.2052 7.2147 16.4282Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.0004 15.6424C14.5665 15.6424 14.2147 15.9942 14.2147 16.4282C14.2147 16.8621 14.5665 17.2139 15.0004 17.2139C15.4344 17.2139 15.7861 16.8621 15.7861 16.4282C15.7861 15.9942 15.4344 15.6424 15.0004 15.6424ZM17.2147 16.4282C17.2147 17.6511 16.2233 18.6424 15.0004 18.6424C13.7775 18.6424 12.7861 17.6511 12.7861 16.4282C12.7861 15.2052 13.7775 14.2139 15.0004 14.2139C16.2233 14.2139 17.2147 15.2052 17.2147 16.4282Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default DeliveryOutlined;
