import React from 'react';
import { IIconProps } from '..';

const GooglePay: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="63" height="25" viewBox="0 0 63 25" fill="none">
        <path d="M29.4511 12.1125V19.1885H27.1902V1.71484H33.1838C34.7027 1.71484 35.998 2.21777 37.0578 3.22362C38.1411 4.22946 38.6827 5.45753 38.6827 6.90782C38.6827 8.3932 38.1411 9.62127 37.0578 10.6154C36.0098 11.6096 34.7145 12.1008 33.1838 12.1008H29.4511V12.1125ZM29.4511 3.86689V9.96045H33.2309C34.1258 9.96045 34.8794 9.65636 35.4681 9.05987C36.0686 8.46338 36.3748 7.73823 36.3748 6.91952C36.3748 6.1125 36.0686 5.39905 35.4681 4.80256C34.8794 4.18268 34.1375 3.87858 33.2309 3.87858H29.4511V3.86689Z" fill="#5F6368"/>
        <path d="M44.5939 6.83765C46.2659 6.83765 47.5847 7.28209 48.5503 8.17098C49.5158 9.05987 49.9986 10.2762 49.9986 11.8201V19.1885H47.8438V17.5277H47.7496C46.8193 18.8961 45.5712 19.5745 44.0169 19.5745C42.6863 19.5745 41.5794 19.1885 40.6845 18.4049C39.7896 17.6213 39.3422 16.6505 39.3422 15.4809C39.3422 14.2411 39.8132 13.2587 40.7552 12.5335C41.6972 11.7967 42.9571 11.4341 44.5232 11.4341C45.8656 11.4341 46.9724 11.6797 47.832 12.171V11.6564C47.832 10.8727 47.5259 10.2178 46.9018 9.66805C46.2777 9.11834 45.5476 8.84934 44.7116 8.84934C43.4517 8.84934 42.4508 9.37565 41.7207 10.44L39.7307 9.20022C40.8258 7.62127 42.4508 6.83765 44.5939 6.83765ZM41.6736 15.516C41.6736 16.1008 41.9209 16.592 42.4272 16.978C42.9218 17.364 43.5105 17.5628 44.1817 17.5628C45.1355 17.5628 45.9833 17.2119 46.7251 16.5102C47.467 15.8084 47.8438 14.9897 47.8438 14.0423C47.1373 13.4926 46.1599 13.2119 44.9 13.2119C43.9815 13.2119 43.2162 13.4341 42.6039 13.8669C41.9798 14.323 41.6736 14.8727 41.6736 15.516Z" fill="#5F6368"/>
        <path d="M62.2918 7.22363L54.7558 24.44H52.4243L55.2268 18.4166L50.2577 7.22363H52.7187L56.2983 15.8084H56.3454L59.8308 7.22363H62.2918Z" fill="#5F6368"/>
        <path d="M20.4586 10.6855C20.4586 9.95337 20.3926 9.25279 20.2702 8.5791H10.7936V12.4387L16.2513 12.4399C16.03 13.7241 15.3176 14.8189 14.226 15.5487V18.0528H17.4748C19.3717 16.3089 20.4586 13.7311 20.4586 10.6855Z" fill="#4285F4"/>
        <path d="M14.2272 15.5487C13.3229 16.1546 12.1583 16.509 10.7959 16.509C8.16421 16.509 5.93166 14.7476 5.13213 12.3733H1.78094V14.9557C3.44123 18.2283 6.85365 20.4739 10.7959 20.4739C13.5207 20.4739 15.8098 19.5838 17.4759 18.0517L14.2272 15.5487Z" fill="#34A853"/>
        <path d="M4.81651 10.4574C4.81651 9.79075 4.92837 9.14631 5.13208 8.54046V5.95801H1.7809C1.09441 7.31122 0.708191 8.83871 0.708191 10.4574C0.708191 12.0761 1.09559 13.6036 1.7809 14.9568L5.13208 12.3744C4.92837 11.7685 4.81651 11.1241 4.81651 10.4574Z" fill="#FABB05"/>
        <path d="M10.7959 4.40485C12.2831 4.40485 13.6149 4.91362 14.6664 5.90777L17.5454 3.05047C15.7968 1.43292 13.5172 0.439941 10.7959 0.439941C6.85482 0.439941 3.44123 2.68555 1.78094 5.95806L5.13213 8.54052C5.93166 6.16625 8.16421 4.40485 10.7959 4.40485Z" fill="#E94235"/>
      </svg>
    </div>
  );
}

export default GooglePay;
