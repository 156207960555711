import React from 'react';
import { IIconProps } from '..';

const QuestionCircleOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <path fillRule="evenodd" clipRule="evenodd" d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z" fill="currentColor"/>
          <path d="M7.37598 8.38042H8.67528C8.73177 7.55189 9.2904 7.03091 10.1566 7.03091C11.0165 7.03091 11.5752 7.55189 11.5752 8.26116C11.5752 8.90768 11.3053 9.27173 10.5458 9.72994C9.68584 10.2446 9.30296 10.8096 9.33434 11.6946L9.34062 12.1967H10.6399V11.8138C10.6399 11.1673 10.8722 10.8347 11.6819 10.3576C12.5104 9.86175 13 9.17758 13 8.20467C13 6.86144 11.8827 5.87598 10.2256 5.87598C8.40537 5.87598 7.43247 6.96186 7.37598 8.38042ZM9.99968 15.1029C10.4704 15.1029 10.8282 14.7514 10.8282 14.2932C10.8282 13.835 10.4704 13.4835 9.99968 13.4835C9.54148 13.4835 9.17742 13.835 9.17742 14.2932C9.17742 14.7514 9.54148 15.1029 9.99968 15.1029Z" fill="currentColor"/>
        </g>
      </svg>
    </div>
  );
}

export default QuestionCircleOutlined;
