import React from 'react';
import { IIconProps } from '..';

const EditOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.9713 6.4917C14.9527 6.40026 14.9281 6.32029 14.8974 6.25173C14.7913 6.02322 14.6414 5.80419 14.4482 5.59473C14.2548 5.38528 14.0785 5.23869 13.9192 5.15483C13.8434 5.1167 13.7544 5.08244 13.652 5.052C13.5496 5.02155 13.4435 5.00441 13.3335 5.00057C13.2236 4.99675 13.1117 5.01201 12.998 5.04625C12.8843 5.08057 12.7743 5.13961 12.6682 5.22337C12.5848 5.29197 12.4805 5.38523 12.3554 5.5033C12.2303 5.62142 12.1222 5.72234 12.0312 5.80613L14.1581 7.94277C14.2112 7.8971 14.2681 7.84376 14.3287 7.78282C14.3818 7.72949 14.4443 7.66855 14.5164 7.59999C14.5884 7.53144 14.6699 7.45149 14.7609 7.36004C14.8441 7.26866 14.9048 7.17344 14.9428 7.07439C14.9808 6.97534 14.9997 6.87637 14.9997 6.77736C14.9997 6.67833 14.9903 6.58311 14.9713 6.4917ZM6.35605 11.5016C6.18927 11.673 6.08309 11.7816 6.03762 11.8273C5.93151 11.934 5.84997 12.0311 5.79309 12.1187C5.73624 12.2063 5.68503 12.3034 5.63954 12.41C5.60161 12.4786 5.55234 12.6043 5.4917 12.7871C5.43101 12.9699 5.36848 13.1699 5.304 13.387C5.23958 13.6041 5.17895 13.8135 5.12207 14.0154C5.06521 14.2173 5.02917 14.3715 5.01399 14.4781C4.98367 14.6762 5.00263 14.8171 5.07086 14.9009C5.13913 14.9847 5.27557 15.0152 5.48034 14.9923C5.57892 14.9771 5.72864 14.9428 5.92956 14.8895C6.13054 14.8362 6.34286 14.7733 6.5665 14.7009C6.7902 14.6286 7.00439 14.5543 7.20909 14.4781C7.41385 14.402 7.56932 14.3373 7.67544 14.2839C7.78163 14.2382 7.88398 14.1773 7.98253 14.1011C8.08105 14.0249 8.16832 13.9526 8.24409 13.884C8.27441 13.8611 8.36919 13.7697 8.52845 13.6098C8.68765 13.4498 8.88864 13.2461 9.13126 12.9985C9.3739 12.7509 9.64686 12.4786 9.95016 12.1815L10.86 11.256L13.3053 8.79937L11.1785 6.65128L6.98159 10.8675C6.73143 11.1189 6.52288 11.3302 6.35605 11.5016Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.4281 15.001C11.8226 15.001 12.1424 14.6812 12.1424 14.2867C12.1424 13.8922 11.8226 13.5724 11.4281 13.5724C11.0336 13.5724 10.7138 13.8922 10.7138 14.2867C10.7138 14.6812 11.0336 15.001 11.4281 15.001Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.2867 14.9992C14.6812 14.9992 15.001 14.6794 15.001 14.285C15.001 13.8905 14.6812 13.5707 14.2867 13.5707C13.8922 13.5707 13.5724 13.8905 13.5724 14.285C13.5724 14.6794 13.8922 14.9992 14.2867 14.9992Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default EditOutlined;
