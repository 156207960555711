import React from 'react';
import { IIconProps } from '..';

const EditAddressOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.6932 0.410963C15.1453 -0.136988 14.2569 -0.136988 13.7089 0.410963L10.1279 3.99195L16.0084 9.87238L19.5894 6.29139C20.1373 5.74344 20.1373 4.85504 19.5894 4.30709L15.6932 0.410963ZM15.0989 10.7819L9.21847 4.90142L0.410963 13.7089C0.147828 13.9721 0 14.3289 0 14.7011V19.2988C0 19.6862 0.314098 20.0003 0.701558 20.0003H5.29924C5.67137 20.0003 6.02826 19.8525 6.29139 19.5894L15.0989 10.7819Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default EditAddressOutlined;
