import React from 'react';
import { IIconProps } from '..';

const LogoutOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.72729 5.09091V3.04545C6.72729 2.50297 6.9428 1.9827 7.32639 1.5991C7.70999 1.2155 8.23026 1 8.77275 1H16.9546C17.4971 1 18.0173 1.2155 18.4009 1.5991C18.7845 1.9827 19 2.50297 19 3.04545V15.3182C19 15.8607 18.7845 16.3809 18.4009 16.7645C18.0173 17.1481 17.4971 17.3636 16.9546 17.3636H9.18184C8.05224 17.3636 6.72729 16.4478 6.72729 15.3182V13.2727" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M11.6362 13.2717L15.7271 9.18075L11.6362 5.08984" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M1 9.18359H14.9091" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </div>
  );
}

export default LogoutOutlined;
