import React from 'react';
import { IIconProps } from '..';

const NavLogo: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="347" height="76" viewBox="0 0 347 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" d="M67.7544 33.8813L84.6953 50.8223L67.7544 67.7632L50.8135 50.8223L67.7544 33.8813Z" fill="white"/>
        <path opacity="0.6" d="M33.8633 33.8726L50.7967 50.8059L33.8633 67.7393L16.9299 50.8059L33.8633 33.8726Z" fill="white"/>
        <rect x="16.9561" y="16.9629" width="23.9795" height="23.9795" transform="rotate(45 16.9561 16.9629)" fill="white" fillOpacity="0.4"/>
        <path opacity="0.6" d="M67.7715 0L84.7275 16.956L67.7715 33.9121L50.8154 16.956L67.7715 0Z" fill="white"/>
        <path opacity="0.6" d="M33.8994 0L50.8555 16.956L33.8994 33.9121L16.9434 16.956L33.8994 0Z" fill="white"/>
        <rect x="84.7222" y="16.9414" width="23.9795" height="23.9795" transform="rotate(45 84.7222 16.9414)" fill="white" fillOpacity="0.4"/>
        <path d="M50.8335 16.9414L67.7895 33.8975L50.8335 50.8535L33.8774 33.8975L50.8335 16.9414Z" fill="white"/>
        <path d="M118.317 31.4589C118.317 31.8683 118.48 32.2609 118.77 32.5505C119.059 32.84 119.452 33.0026 119.861 33.0026H128.765C135.618 33.0026 139.891 29.9473 139.891 23.8C139.891 20.4089 138.28 18.0323 135.448 16.7186C137.647 15.4027 138.791 13.3252 138.791 10.737C138.791 5.09578 134.815 2.04053 127.962 2.04053H119.861C119.658 2.04053 119.457 2.08053 119.27 2.15826C119.082 2.23598 118.912 2.3499 118.769 2.49349C118.625 2.63709 118.512 2.80754 118.434 2.9951C118.357 3.18266 118.317 3.38365 118.317 3.58657V31.4589ZM128.47 19.8544C132.784 19.8544 133.969 21.1681 133.969 23.6712C133.969 26.1743 132.193 27.5823 128.765 27.5823H124.198V19.8544H128.47ZM128.01 7.47006C131.479 7.47006 132.876 8.48693 132.876 10.737C132.876 12.8996 131.606 14.3398 127.969 14.3398H124.198V7.47006H128.01Z" fill="white"/>
        <path d="M142.653 31.4589C142.653 31.8683 142.816 32.2609 143.105 32.5505C143.395 32.84 143.788 33.0026 144.197 33.0026H146.988C147.397 33.0026 147.79 32.84 148.079 32.5505C148.369 32.2609 148.531 31.8683 148.531 31.4589V3.58657C148.532 3.38365 148.492 3.18266 148.415 2.9951C148.337 2.80754 148.224 2.63709 148.08 2.49349C147.937 2.3499 147.766 2.23598 147.579 2.15826C147.392 2.08053 147.191 2.04053 146.988 2.04053H144.197C143.994 2.04053 143.793 2.08053 143.606 2.15826C143.418 2.23598 143.248 2.3499 143.105 2.49349C142.961 2.63709 142.848 2.80754 142.77 2.9951C142.693 3.18266 142.653 3.38365 142.653 3.58657V31.4589Z" fill="white"/>
        <path d="M152.105 31.4589C152.106 31.8681 152.268 32.2604 152.558 32.5498C152.847 32.8392 153.239 33.002 153.649 33.0026H156.442C156.851 33.002 157.243 32.8392 157.533 32.5498C157.822 32.2604 157.985 31.8681 157.985 31.4589V11.4985L171.067 32.2802C171.206 32.5012 171.399 32.6833 171.627 32.8096C171.856 32.9359 172.113 33.0023 172.374 33.0026H176.236C176.646 33.0026 177.039 32.84 177.329 32.5506C177.619 32.2612 177.782 31.8685 177.782 31.4589V3.58657C177.782 3.38354 177.742 3.18249 177.665 2.99492C177.587 2.80735 177.473 2.63692 177.33 2.49335C177.186 2.34979 177.016 2.23591 176.828 2.15821C176.641 2.08052 176.439 2.04053 176.236 2.04053H173.446C173.243 2.04053 173.042 2.08053 172.854 2.15826C172.667 2.23598 172.497 2.3499 172.353 2.49349C172.21 2.63709 172.096 2.80754 172.019 2.9951C171.941 3.18266 171.902 3.38365 171.902 3.58657V22.866L159.248 2.76293C159.109 2.54212 158.916 2.3601 158.688 2.2338C158.459 2.1075 158.203 2.04101 157.942 2.04053H153.649C153.239 2.04114 152.846 2.2043 152.557 2.49417C152.268 2.78404 152.105 3.17693 152.105 3.58657V31.4589Z" fill="white"/>
        <path d="M181.148 31.4589C181.148 31.8683 181.311 32.2609 181.6 32.5505C181.89 32.84 182.282 33.0026 182.692 33.0026H189.824C199.256 33.0026 205.898 26.3445 205.898 17.5216C205.898 8.74231 200.018 2.04053 189.564 2.04053H182.692C182.489 2.04053 182.288 2.08053 182.1 2.15826C181.913 2.23598 181.743 2.3499 181.599 2.49349C181.456 2.63709 181.342 2.80754 181.265 2.9951C181.187 3.18266 181.148 3.38365 181.148 3.58657V31.4589ZM188.887 7.51147C196.459 7.51147 200.013 11.6687 200.013 17.5216C200.013 23.2042 195.826 27.5317 189.143 27.5317H187.026V7.51606L188.887 7.51147Z" fill="white"/>
        <path d="M223.759 33.4327C227.977 33.4327 232.023 31.7571 235.005 28.7745C237.988 25.7918 239.664 21.7465 239.664 17.5284C239.664 13.3103 237.988 9.26494 235.005 6.2823C232.023 3.29965 227.977 1.62402 223.759 1.62402C215.003 1.62402 207.981 8.75602 207.981 17.5284C207.981 26.3514 215.003 33.4327 223.759 33.4327ZM223.759 27.6236C218.217 27.6236 213.818 23.0844 213.818 17.5284C213.818 11.9723 218.217 7.43545 223.759 7.43545C229.302 7.43545 233.742 11.9723 233.742 17.5284C233.742 23.0844 229.302 27.6236 223.759 27.6236Z" fill="white"/>
        <path d="M252.491 31.4589C252.492 31.8681 252.655 32.2604 252.944 32.5498C253.233 32.8392 253.626 33.002 254.035 33.0026H267.995C268.404 33.002 268.797 32.8392 269.086 32.5498C269.376 32.2604 269.538 31.8681 269.539 31.4589V28.9052C269.539 28.4957 269.376 28.1031 269.087 27.8136C268.797 27.5241 268.405 27.3614 267.995 27.3614H258.372V3.58657C258.372 3.17693 258.209 2.78404 257.92 2.49417C257.63 2.2043 257.238 2.04114 256.828 2.04053H254.035C253.625 2.04114 253.233 2.2043 252.943 2.49417C252.654 2.78404 252.491 3.17693 252.491 3.58657V31.4589Z" fill="white"/>
        <path d="M272.314 30.9435C272.23 31.1771 272.202 31.4277 272.235 31.6741C272.268 31.9204 272.36 32.1552 272.503 32.3585C272.646 32.5618 272.836 32.7275 273.056 32.8417C273.277 32.9558 273.522 33.015 273.771 33.0141H276.619C276.94 33.014 277.253 32.9138 277.514 32.7274C277.776 32.541 277.973 32.2777 278.077 31.9742L279.591 27.6168H291.435L292.935 31.9627C293.04 32.266 293.237 32.5291 293.498 32.7154C293.76 32.9018 294.073 33.0021 294.394 33.0026H297.244C297.492 33.0029 297.737 32.9434 297.958 32.8291C298.178 32.7148 298.368 32.5491 298.511 32.346C298.654 32.1429 298.745 31.9083 298.778 31.6622C298.811 31.4161 298.785 31.1656 298.7 30.932L288.828 3.07123C288.722 2.7702 288.524 2.50955 288.264 2.32503C288.003 2.14051 287.691 2.04113 287.372 2.04053H283.634C283.314 2.0408 283.002 2.14004 282.742 2.32461C282.481 2.50918 282.284 2.77001 282.177 3.07123L272.314 30.9435ZM285.502 10.4379L289.484 21.9756H281.526L285.502 10.4379Z" fill="white"/>
        <path d="M301.676 31.4589C301.676 31.6616 301.716 31.8623 301.793 32.0496C301.871 32.2369 301.985 32.4071 302.128 32.5505C302.271 32.6938 302.441 32.8075 302.629 32.8851C302.816 32.9627 303.017 33.0026 303.22 33.0026H312.123C318.977 33.0026 323.247 29.9473 323.247 23.8C323.247 20.4089 321.636 18.0323 318.806 16.7186C321.006 15.4027 322.147 13.3252 322.147 10.737C322.147 5.09578 318.171 2.04053 311.32 2.04053H303.226C303.023 2.04053 302.823 2.08053 302.635 2.15826C302.448 2.23598 302.277 2.3499 302.134 2.49349C301.991 2.63709 301.877 2.80754 301.8 2.9951C301.722 3.18266 301.682 3.38365 301.683 3.58657L301.676 31.4589ZM311.826 19.8544C316.142 19.8544 317.327 21.1681 317.327 23.6712C317.327 26.1743 315.549 27.5823 312.123 27.5823H307.561V19.8544H311.826ZM311.366 7.47006C314.835 7.47006 316.232 8.48693 316.232 10.737C316.232 12.8996 314.962 14.3398 311.325 14.3398H307.561V7.47006H311.366Z" fill="white"/>
        <path d="M336.297 33.4258C343.107 33.4258 347 28.675 347 23.7631C347 17.8252 342.263 15.9593 338.076 14.602C334.774 13.5 331.813 12.6924 331.813 10.3182C331.813 8.36722 333.295 6.88329 336.044 6.88329C337.855 6.88329 339.332 7.45615 340.491 8.55126C341.14 9.16093 342.143 9.35419 342.852 8.81584L345.021 7.17087C345.197 7.04454 345.342 6.88058 345.447 6.69096C345.551 6.50134 345.611 6.29084 345.624 6.07481C345.637 5.85878 345.601 5.64266 345.519 5.44223C345.438 5.2418 345.312 5.0621 345.152 4.91625C342.919 2.963 340.218 1.62402 336.003 1.62402C330.037 1.62402 325.723 5.56733 325.723 10.5298C325.723 16.4678 330.325 18.0805 334.48 19.3528C337.779 20.3697 340.866 21.1749 340.866 23.724C340.866 25.8429 339.256 27.7939 336.265 27.7939C334.07 27.7939 332.092 26.823 330.608 25.0929C329.996 24.3797 328.919 24.1611 328.181 24.7432L325.487 26.8713C325.32 26.996 325.182 27.1551 325.083 27.3376C324.983 27.5202 324.924 27.7221 324.909 27.9296C324.894 28.137 324.924 28.3453 324.997 28.5402C325.069 28.7351 325.183 28.9121 325.33 29.0592C327.976 31.6198 331.429 33.4258 336.297 33.4258Z" fill="white"/>
        <path d="M128.164 65.7753C133.52 65.7753 137.795 61.4357 137.795 56.0307C137.795 50.6517 133.52 46.2861 128.164 46.2861C122.834 46.2861 118.559 50.6517 118.559 56.0307C118.559 61.4357 122.834 65.7753 128.164 65.7753ZM128.164 62.2153C124.791 62.2153 122.113 59.4348 122.113 56.0307C122.113 52.6266 124.791 49.8461 128.164 49.8461C131.538 49.8461 134.241 52.6266 134.241 56.0307C134.241 59.4348 131.538 62.2153 128.164 62.2153Z" fill="white"/>
        <path d="M139.343 65.5154H142.922V52.3408L151.163 65.5154H154.974V46.546H151.395V59.3049L143.412 46.546H139.343V65.5154Z" fill="white"/>
        <path d="M157.022 65.5154H168.172V62.0593H160.601V57.7198H168.172V54.2637H160.601V50.0021H168.172V46.546H157.022V65.5154Z" fill="white"/>
        <path d="M176.335 65.5154H179.914V52.4447L185.502 60.0844L191.168 52.3667V65.5154H194.747V46.546H191.374L185.528 54.4456L179.889 46.546H176.335V65.5154Z" fill="white"/>
        <path d="M196.136 65.5154H199.869L201.003 62.2153H208.213L209.346 65.5154H213.08L206.41 46.546H202.805L196.136 65.5154ZM204.608 51.6911L207.028 58.7592H202.187L204.608 51.6911Z" fill="white"/>
        <path d="M214.459 65.5154H218.038V49.9241H219.944C222.647 49.9241 223.678 51.0155 223.678 52.9124C223.678 54.2637 222.725 56.1346 219.944 56.1346H218.193L223.832 65.5154H227.926L223.652 58.5513C226.15 57.5898 227.283 55.2771 227.283 52.9124C227.283 49.3264 224.682 46.546 220.356 46.546H214.459V65.5154Z" fill="white"/>
        <path d="M239.675 65.5154H243.744L237.306 53.9259L243.229 46.546H238.877L232.8 54.1857V46.546H229.22V65.5154H232.8V58.9151L234.757 56.4465L239.675 65.5154Z" fill="white"/>
        <path d="M245.088 65.5154H256.239V62.0593H248.668V57.7198H256.239V54.2637H248.668V50.0021H256.239V46.546H245.088V65.5154Z" fill="white"/>
        <path d="M262.699 65.5154H266.278V50.0021H271.274V46.546H257.703V50.0021H262.699V65.5154Z" fill="white"/>
        <path d="M272.952 65.5154H276.531V60.1104H279.39C283.304 60.1104 286.188 57.0961 286.188 53.3282C286.188 49.5603 283.304 46.546 279.415 46.546H272.952V65.5154ZM279.158 49.8981C281.192 49.8981 282.583 51.4313 282.583 53.3282C282.583 55.2252 281.192 56.7583 279.158 56.7583H276.531V49.8981H279.158Z" fill="white"/>
        <path d="M287.864 65.5154H298.242V62.0593H291.444V46.546H287.864V65.5154Z" fill="white"/>
        <path d="M299.492 65.5154H303.226L304.359 62.2153H311.569L312.702 65.5154H316.436L309.767 46.546H306.161L299.492 65.5154ZM307.964 51.6911L310.385 58.7592H305.543L307.964 51.6911Z" fill="white"/>
        <path d="M325.8 65.7753C329.534 65.7753 332.675 63.5925 334.323 60.3703L331.053 58.6293C330.1 60.6561 328.298 62.1633 325.723 62.1633C322.272 62.1633 319.697 59.4348 319.697 56.0307C319.697 52.5486 322.298 49.8461 325.8 49.8461C328.195 49.8461 329.843 51.0415 330.899 53.0164L334.04 50.9895C332.392 48.1571 329.328 46.2861 325.8 46.2861C320.366 46.2861 316.143 50.3659 316.143 56.0307C316.143 61.4357 319.98 65.7753 325.8 65.7753Z" fill="white"/>
        <path d="M335.594 65.5154H346.744V62.0593H339.174V57.7198H346.744V54.2637H339.174V50.0021H346.744V46.546H335.594V65.5154Z" fill="white"/>
      </svg>
    </div>
  );
}

export default NavLogo;
