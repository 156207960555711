import React from 'react';
import { IIconProps } from '..';

const MemberOutlined: React.FC<IIconProps> = props => {
  const { className = '', lightColor = '', darkColor = '', tag = '', onClick, logoSvg = '' } = props;
  const lightTagColor = lightColor || 'currentColor';
  const darkTagColor = darkColor || 'currentColor';
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill={`url(#${tag}_paint0_linear)`} />
        <circle opacity="0.7" cx="9.99994" cy="10.0024" r="7.75238" fill={`url(#${tag}_paint1_linear)`} />
        {
          !logoSvg &&
          <g opacity="0.85" filter={`url(#${tag}_filter0_i)`}>
            <path d="M6.69932 6.7534C6.6816 6.46571 6.91014 6.22266 7.19838 6.22266H9.4123C9.67229 6.22266 9.8889 6.42191 9.91056 6.681L10.2044 10.1955C10.2463 10.6967 10.9188 10.8293 11.1478 10.3815L13.1354 6.49499C13.2209 6.32784 13.3928 6.22266 13.5806 6.22266H13.8628C14.2332 6.22266 14.475 6.61141 14.3113 6.94369L10.6424 14.3881C10.5582 14.5589 10.3843 14.6671 10.1939 14.6671H7.65698C7.39278 14.6671 7.17417 14.4615 7.15793 14.1978L6.69932 6.7534Z" fill="white" />
          </g>
        }
        <defs>
          <filter id={`${tag}_filter0_i`} x="6.69836" y="6.22266" width="7.66507" height="9.44444" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="0.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.756863 0 0 0 0 0.768627 0 0 0 0 0.819608 0 0 0 1 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient id={`${tag}_paint0_linear`} x1="9.77778" y1="-9.64156e-09" x2="10" y2="20" gradientUnits="userSpaceOnUse">
            <stop stopColor={lightTagColor} />
            <stop offset="1" stopColor={darkTagColor} />
          </linearGradient>
          <linearGradient id={`${tag}_paint1_linear`} x1="9.82767" y1="2.25" x2="9.99994" y2="17.7548" gradientUnits="userSpaceOnUse">
            <stop stopColor={darkTagColor} />
            <stop offset="1" stopColor={lightTagColor} />
          </linearGradient>
        </defs>
      </svg>
      {
        logoSvg && <div className="bm-icon-sub sub-icon" dangerouslySetInnerHTML={{ __html: logoSvg }} />
      }
    </div>
  );
}

export default MemberOutlined;
