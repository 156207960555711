import React from 'react';
import { IIconProps } from '..';

const AppleEmailOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.58947 0H15.4105C17.9531 0 20 2.04689 20 4.58946V15.4105C20 17.9531 17.9531 20 15.4105 20H4.58947C2.0469 20 0 17.9531 0 15.4105V4.58946C0 2.04689 2.0469 0 4.58947 0Z" fill="url(#paint0_linear_apple_email)" />
        <path d="M4.38227 5.68555C4.27689 5.68555 4.17762 5.70372 4.08366 5.7411L5.9656 7.6786L7.86838 9.65082L7.9031 9.69249L7.95866 9.74805L8.01421 9.8036L8.12533 9.92166L9.75727 11.5953C9.78444 11.6122 9.86318 11.6851 9.9247 11.7158C10.0039 11.7555 10.0898 11.792 10.1784 11.7951C10.2739 11.7985 10.3715 11.7712 10.4575 11.7294C10.5219 11.698 10.5505 11.6531 10.6253 11.5953L12.5142 9.64388L14.4239 7.6786L16.2642 5.78277C16.1461 5.71876 16.0152 5.68555 15.8753 5.68555H4.38227ZM3.80588 5.92166C3.60519 6.11182 3.47949 6.3977 3.47949 6.72027V13.0814C3.47949 13.3426 3.56338 13.5798 3.70171 13.7619L3.9656 13.5119L5.93088 11.6022L7.67394 9.91471L7.63921 9.87305L5.72949 7.90777L3.81977 5.93555L3.80588 5.92166ZM16.5142 5.98416L14.6531 7.90777L12.7503 9.87305L12.7156 9.90777L14.5281 11.6647L16.4934 13.5744L16.6114 13.6855C16.7173 13.5158 16.7781 13.3066 16.7781 13.0814V6.72027C16.7781 6.43265 16.6784 6.17184 16.5142 5.98416ZM7.89616 10.1439L6.16005 11.8314L4.18783 13.7411L3.93783 13.9842C4.06962 14.069 4.21961 14.123 4.38227 14.123H15.8753C16.0709 14.123 16.2482 14.0478 16.3962 13.9286L16.2712 13.8036L14.2989 11.8939L12.4864 10.1439L10.8545 11.8244C10.7662 11.883 10.7071 11.9479 10.6209 11.9878C10.4821 12.052 10.33 12.1063 10.1771 12.104C10.0237 12.1016 9.87342 12.0416 9.73583 11.9739C9.66676 11.94 9.62995 11.9062 9.54894 11.8383L7.89616 10.1439Z" fill="white" />
        <defs>
          <linearGradient id="paint0_linear_apple_email" x1="10.14" y1="19.9197" x2="10.1595" y2="0.241447" gradientUnits="userSpaceOnUse">
            <stop stopColor="#70EFFF" />
            <stop offset="1" stopColor="#5770FF" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default AppleEmailOutlined;
