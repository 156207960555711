import React from 'react';
import { IIconProps } from '..';

const PlusOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6236 9.37643H13.3764C13.7208 9.37643 14 9.65561 14 10C14 10.3444 13.7208 10.6236 13.3764 10.6236H10.6236V13.3764C10.6236 13.7208 10.3444 14 10 14C9.65561 14 9.37643 13.7208 9.37643 13.3764V10.6236H6.62357C6.27918 10.6236 6 10.3444 6 10C6 9.65561 6.27918 9.37643 6.62357 9.37643H9.37643V6.62357C9.37643 6.27918 9.65561 6 10 6C10.3444 6 10.6236 6.27918 10.6236 6.62357V9.37643Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default PlusOutlined;
