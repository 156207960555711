import React from 'react';
import { IIconProps } from '..';

const NavAccountOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0007 8.5833C11.4841 8.5833 12.7507 7.33751 12.7507 5.72069C12.7507 4.10386 11.4841 2.85807 10.0007 2.85807C8.51725 2.85807 7.25065 4.10386 7.25065 5.72069C7.25065 7.33751 8.51725 8.5833 10.0007 8.5833ZM17.7507 15.6644V15.4617C17.7507 14.173 16.706 13.1283 15.4173 13.1283H4.58398C3.29532 13.1283 2.25065 14.173 2.25065 15.4617V15.6644C2.25065 16.953 3.29532 17.9977 4.58398 17.9977H15.4173C16.706 17.9977 17.7507 16.953 17.7507 15.6644ZM10.0007 9.99996C12.3018 9.99996 14.1673 8.08407 14.1673 5.72069C14.1673 3.3573 12.3018 1.44141 10.0007 1.44141C7.69946 1.44141 5.83398 3.3573 5.83398 5.72069C5.83398 8.08407 7.69946 9.99996 10.0007 9.99996ZM4.58398 11.7117C2.51292 11.7117 0.833984 13.3906 0.833984 15.4617V15.6644C0.833984 17.7354 2.51292 19.4144 4.58398 19.4144H15.4173C17.4884 19.4144 19.1673 17.7354 19.1673 15.6644V15.4617C19.1673 13.3906 17.4884 11.7117 15.4173 11.7117H4.58398Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default NavAccountOutlined;
