import React from 'react';
import { IIconProps } from '..';

const StarOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" preserveAspectRatio="xMinYMin slice" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" d="M8.75765 1.6825C9.35589 0.772499 10.6441 0.7725 11.2423 1.6825L13.4154 4.98796C13.6112 5.28586 13.8996 5.50427 14.2319 5.60639L17.9189 6.73945C18.9339 7.05138 19.332 8.32863 18.6867 9.20297L16.3427 12.3789C16.1314 12.6651 16.0213 13.0185 16.0308 13.3796L16.1365 17.3853C16.1656 18.4881 15.1234 19.2774 14.1263 18.9078L10.5046 17.5652C10.1782 17.4442 9.82178 17.4442 9.49538 17.5652L5.87368 18.9078C4.87662 19.2774 3.83443 18.4881 3.86352 17.3853L3.9692 13.3796C3.97872 13.0185 3.86858 12.6651 3.65733 12.3789L1.31332 9.20297C0.668002 8.32863 1.06608 7.05138 2.08113 6.73945L5.76814 5.60639C6.10043 5.50427 6.38879 5.28586 6.58463 4.98796L8.75765 1.6825Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default StarOutlined;
