import React from 'react';
import { getPrimaryColor, IIconProps } from '..';

const TimeOutlined: React.FC<IIconProps> = props => {
  const { className = '', showTagColor = false, onClick } = props;
  const tagColor = showTagColor ? getPrimaryColor() : 'currentColor';

  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0006 17.8577C14.3399 17.8577 17.8577 14.3399 17.8577 10.0006C17.8577 5.66118 14.3399 2.14342 10.0006 2.14342C5.66118 2.14342 2.14342 5.66118 2.14342 10.0006C2.14342 14.3399 5.66118 17.8577 10.0006 17.8577ZM19.2863 10.0006C19.2863 15.1289 15.1289 19.2863 10.0006 19.2863C4.8722 19.2863 0.714844 15.1289 0.714844 10.0006C0.714844 4.8722 4.8722 0.714844 10.0006 0.714844C15.1289 0.714844 19.2863 4.8722 19.2863 10.0006Z" fill="currentColor"/>
        <path d="M9.99944 5.35742C10.3939 5.35742 10.7137 5.67722 10.7137 6.07171V9.64278L12.8566 9.64314C13.2511 9.64314 13.5709 9.96293 13.5709 10.3574C13.5709 10.7519 13.2511 11.0717 12.8566 11.0717L10.0085 11.0717C10.0055 11.0717 10.0025 11.0717 9.99944 11.0717C9.60495 11.0717 9.28516 10.7519 9.28516 10.3574V6.07171C9.28516 5.67722 9.60495 5.35742 9.99944 5.35742Z" fill={tagColor}/>
      </svg>
    </div>
  );
}

export default TimeOutlined;
