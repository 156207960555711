import React from 'react';
import { IIconProps } from '..';

const CheckedOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7394 7.02548C14.0015 7.25011 14.0319 7.64468 13.8072 7.90676L9.52151 12.9068C9.41292 13.0334 9.25817 13.1115 9.09173 13.1234C8.92529 13.1354 8.76099 13.0803 8.63541 12.9704L6.25446 10.887C5.99469 10.6597 5.96836 10.2649 6.19566 10.0051C6.42297 9.74534 6.81782 9.71902 7.07759 9.94632L8.98336 11.6139L12.8582 7.09327C13.0828 6.83119 13.4774 6.80084 13.7394 7.02548Z" fill="white"/>
      </svg>
    </div>
  );
}

export default CheckedOutlined;
