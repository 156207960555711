import React from 'react';
import { IIconProps } from '..';

const Date: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1 4V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V4H1ZM2 1C1.46957 1 0.960859 1.21071 0.585786 1.58579C0.210714 1.96086 0 2.46957 0 3L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V3C16 2.46957 15.7893 1.96086 15.4142 1.58579C15.0391 1.21071 14.5304 1 14 1H2Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.5 0C3.63261 0 3.75979 0.0526784 3.85355 0.146447C3.94732 0.240215 4 0.367392 4 0.5V1C4 1.13261 3.94732 1.25979 3.85355 1.35355C3.75979 1.44732 3.63261 1.5 3.5 1.5C3.36739 1.5 3.24021 1.44732 3.14645 1.35355C3.05268 1.25979 3 1.13261 3 1V0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0V0ZM12.5 0C12.6326 0 12.7598 0.0526784 12.8536 0.146447C12.9473 0.240215 13 0.367392 13 0.5V1C13 1.13261 12.9473 1.25979 12.8536 1.35355C12.7598 1.44732 12.6326 1.5 12.5 1.5C12.3674 1.5 12.2402 1.44732 12.1464 1.35355C12.0527 1.25979 12 1.13261 12 1V0.5C12 0.367392 12.0527 0.240215 12.1464 0.146447C12.2402 0.0526784 12.3674 0 12.5 0V0Z" fill="black"/>
        <path d="M12.5 6H11.5C11.2239 6 11 6.22386 11 6.5V7.5C11 7.77614 11.2239 8 11.5 8H12.5C12.7761 8 13 7.77614 13 7.5V6.5C13 6.22386 12.7761 6 12.5 6Z" fill="black"/>
      </svg>
    </div>
  );
}

export default Date;
