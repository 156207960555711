import React from 'react';
import { IIconProps } from '..';

const CreditCard: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="18" rx="2" fill="#32AB64"/>
        <rect opacity="0.9" y="3" width="24" height="3" fill="#3D4343"/>
        <rect opacity="0.8" width="16" height="2" rx="0.2" transform="matrix(1 0 0 -1 1 10)" fill="#E3EEF4"/>
        <rect opacity="0.8" width="5" height="2" rx="0.2" transform="matrix(1 0 0 -1 18 10)" fill="#E3EEF4"/>
        <path d="M18.7497 14.1257C18.7801 14.0498 18.8536 14 18.9354 14H21.7046C21.8461 14 21.9428 14.1429 21.8903 14.2743L21.2503 15.8743C21.2199 15.9502 21.1464 16 21.0646 16H18.2954C18.1539 16 18.0572 15.8571 18.1097 15.7257L18.7497 14.1257Z" fill="#2A8157"/>
        <path d="M13.7497 14.1257C13.7801 14.0498 13.8536 14 13.9354 14H16.7046C16.8461 14 16.9428 14.1429 16.8903 14.2743L16.2503 15.8743C16.2199 15.9502 16.1464 16 16.0646 16H13.2954C13.1539 16 13.0572 15.8571 13.1097 15.7257L13.7497 14.1257Z" fill="#2A8157"/>
      </svg>
    </div>
  );
}

export default CreditCard;
