import React from 'react';
import { IIconProps } from '..';

const FavoriteSetting: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.332031 7.39562C0.332031 3.87222 2.8283 0.888672 6.10803 0.888672C7.17115 0.888672 8.14117 1.49691 8.83901 2.07112C9.29752 2.4484 9.69565 2.85903 9.99816 3.20087C10.3007 2.85903 10.6988 2.44839 11.1574 2.07111C11.8552 1.4969 12.8253 0.888672 13.8885 0.888672C17.1679 0.888672 19.6654 3.87201 19.6654 7.39562C19.6654 8.82428 19.015 10.2648 18.149 11.5674C17.2738 12.8838 16.121 14.1446 14.9903 15.2315C13.8564 16.3215 12.7256 17.2548 11.8799 17.9147C11.4564 18.2452 11.1029 18.5082 10.8543 18.6893C10.73 18.7799 10.6318 18.8501 10.5641 18.898C10.5303 18.922 10.504 18.9404 10.4859 18.953L10.465 18.9676L10.4576 18.9727C10.4576 18.9727 10.4567 18.9733 10.0151 18.3331C9.57454 18.9741 9.57421 18.9739 9.57421 18.9739L9.57199 18.9723L9.56622 18.9684L9.54522 18.9538C9.52708 18.9412 9.50075 18.9228 9.46678 18.8988C9.39884 18.8509 9.3003 18.7807 9.17552 18.6901C8.92603 18.509 8.57127 18.246 8.14625 17.9156C7.29745 17.2557 6.1625 16.3224 5.02447 15.2325C3.88969 14.1457 2.73266 12.8849 1.85428 11.5685C0.985141 10.266 0.332031 8.8251 0.332031 7.39562ZM10.0151 18.3331L9.57421 18.9739L10.0159 19.2774L10.4567 18.9733L10.0151 18.3331ZM10.0142 17.3766C10.2452 17.2072 10.5564 16.9743 10.9229 16.6883C11.7408 16.0502 12.8281 15.1523 13.9123 14.1101C14.9998 13.0647 16.0651 11.8922 16.8536 10.7062C17.6511 9.50652 18.1098 8.37551 18.1098 7.39562C18.1098 4.5317 16.1221 2.44423 13.8885 2.44423C13.3852 2.44423 12.7729 2.75631 12.1457 3.27232C11.5441 3.76736 11.0495 4.3493 10.7972 4.6688C10.391 5.18301 9.60526 5.183 9.19912 4.66879C8.94677 4.34929 8.45225 3.76734 7.85063 3.27231C7.22352 2.7563 6.61122 2.44423 6.10803 2.44423C3.87447 2.44423 1.88759 4.53149 1.88759 7.39562C1.88759 8.3747 2.34763 9.5053 3.14823 10.7051C3.93959 11.8911 5.00888 13.0637 6.10042 14.1091C7.1887 15.1514 8.28007 16.0493 9.101 16.6875C9.46946 16.9739 9.78223 17.2071 10.0142 17.3766Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default FavoriteSetting;
