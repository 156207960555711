import React from 'react';
import { IIconProps } from '..';

const WhatsAppOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#30BF39"/>
        <path d="M13.0254 10.9155C12.8867 10.8413 12.2157 10.48 12.0931 10.4252C11.9673 10.3736 11.8738 10.3478 11.777 10.4833C11.6802 10.6188 11.3996 10.922 11.3157 11.0123C11.2319 11.1026 11.1512 11.1091 11.0125 11.0349C10.8738 10.9607 10.4319 10.7962 9.91895 10.2994C9.51895 9.91552 9.25766 9.44778 9.18024 9.30585C9.10605 9.16391 9.18024 9.08972 9.25121 9.02198C9.32218 8.95423 9.39637 8.86391 9.47056 8.78327C9.54476 8.70262 9.56734 8.64778 9.61895 8.55423C9.66734 8.46069 9.64798 8.38004 9.61573 8.30907C9.58347 8.23811 9.33185 7.54456 9.2254 7.25746C9.11895 6.97359 9.00282 7.01552 8.91895 7.0123C8.83831 7.00907 8.74476 6.99294 8.65121 6.99294C8.50927 6.98972 8.37056 7.04778 8.27379 7.15423C8.14153 7.28649 7.76734 7.6123 7.74153 8.29617C7.71573 8.98004 8.18669 9.66714 8.25444 9.76391C8.32218 9.85746 9.16089 11.3446 10.5544 11.9542C11.948 12.5639 11.9577 12.38 12.2125 12.3639C12.4673 12.3478 13.048 12.0575 13.177 11.7381C13.306 11.4188 13.3157 11.1381 13.2835 11.08C13.248 11.022 13.1577 10.9833 13.0222 10.9058V10.9155H13.0254ZM10.4673 14.2155C9.58024 14.2155 8.70927 13.9542 7.97056 13.4639L6.2254 14.022L6.79314 12.3349C6.22863 11.5607 5.9254 10.6284 5.92863 9.67036C5.92863 7.16391 7.96411 5.12843 10.4706 5.12843C12.977 5.12843 15.0125 7.16391 15.0125 9.67036C15.0125 12.1768 12.9706 14.2155 10.4673 14.2155ZM10.4673 4.21875C7.45444 4.21875 5.0125 6.66069 5.0125 9.67359C5.0125 10.6671 5.28024 11.6413 5.79637 12.4897L4.8125 15.4155L7.83185 14.4478C8.63831 14.8929 9.54798 15.1284 10.4673 15.1284C13.4802 15.1284 15.9222 12.6865 15.9222 9.67359C15.9222 6.66069 13.4802 4.21875 10.4673 4.21875Z" fill="white"/>
      </svg>
    </div>
  );
}

export default WhatsAppOutlined;
