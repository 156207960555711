import React from 'react';
import { IIconProps } from '..';

const ExpressOrderOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.41143 4.53515C7.55366 4.35506 7.77052 4.25 8 4.25H18.7125C19.1481 4.25 19.4466 4.52225 19.5921 4.77382C19.7395 5.02884 19.7885 5.34237 19.7187 5.64423L19.7176 5.64909L18.2253 11.9122L18.2249 11.9142C18.1357 12.2838 17.8044 12.75 17.2203 12.75H9.66728C9.32001 12.75 9.01815 12.5116 8.93769 12.1738L7.27041 5.17378C7.21724 4.95054 7.2692 4.71524 7.41143 4.53515ZM8.94962 5.75L10.2596 11.25H16.8411L18.1515 5.75H8.94962Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.25 2C4.25 1.58579 4.58579 1.25 5 1.25H6.96541C7.30996 1.25 7.61018 1.48476 7.69327 1.81914L10.7821 14.25H17C17.4142 14.25 17.75 14.5858 17.75 15C17.75 15.4142 17.4142 15.75 17 15.75H10.1957C9.85112 15.75 9.5509 15.5152 9.46781 15.1809L6.37896 2.75H5C4.58579 2.75 4.25 2.41421 4.25 2Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 15.75C10.0858 15.75 9.75 16.0858 9.75 16.5C9.75 16.9142 10.0858 17.25 10.5 17.25C10.9142 17.25 11.25 16.9142 11.25 16.5C11.25 16.0858 10.9142 15.75 10.5 15.75ZM8.25 16.5C8.25 15.2574 9.25736 14.25 10.5 14.25C11.7426 14.25 12.75 15.2574 12.75 16.5C12.75 17.7426 11.7426 18.75 10.5 18.75C9.25736 18.75 8.25 17.7426 8.25 16.5Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.1445 15.75C16.7303 15.75 16.3945 16.0858 16.3945 16.5C16.3945 16.9142 16.7303 17.25 17.1445 17.25C17.5587 17.25 17.8945 16.9142 17.8945 16.5C17.8945 16.0858 17.5587 15.75 17.1445 15.75ZM14.8945 16.5C14.8945 15.2574 15.9019 14.25 17.1445 14.25C18.3872 14.25 19.3945 15.2574 19.3945 16.5C19.3945 17.7426 18.3872 18.75 17.1445 18.75C15.9019 18.75 14.8945 17.7426 14.8945 16.5Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.25 5C0.25 4.58579 0.585786 4.25 1 4.25H5C5.41421 4.25 5.75 4.58579 5.75 5C5.75 5.41421 5.41421 5.75 5 5.75H1C0.585786 5.75 0.25 5.41421 0.25 5Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.25 9C2.25 8.58579 2.58579 8.25 3 8.25L6 8.25C6.41421 8.25 6.75 8.58579 6.75 9C6.75 9.41421 6.41421 9.75 6 9.75H3C2.58579 9.75 2.25 9.41421 2.25 9Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.25 13C4.25 12.5858 4.58579 12.25 5 12.25H7C7.41421 12.25 7.75 12.5858 7.75 13C7.75 13.4142 7.41421 13.75 7 13.75H5C4.58579 13.75 4.25 13.4142 4.25 13Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default ExpressOrderOutlined;
