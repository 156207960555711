import React from 'react';
import { IIconProps } from '..';

const AlreadyFavoriteOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0611 2.21371C12.5273 0.357921 15.8851 0.680913 18.143 2.83646L18.1531 2.84608L18.1629 2.85602C20.6199 5.35794 20.6548 9.46211 18.0111 11.859L11.7505 18.2341C10.7476 19.2553 9.13517 19.2553 8.13233 18.2341L1.8535 11.8404C-0.617835 9.32391 -0.617835 5.37256 1.8535 2.85602L1.86327 2.84608L1.87334 2.83646C4.14589 0.666968 7.5009 0.369204 10.0611 2.21371Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default AlreadyFavoriteOutlined;
