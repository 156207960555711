import React from 'react';
import { IIconProps } from '..';

const RecordsOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.25 4C2.25 2.48122 3.48122 1.25 5 1.25H5.6571C6.07131 1.25 6.4071 1.58579 6.4071 2C6.4071 2.41421 6.07131 2.75 5.6571 2.75H5C4.30964 2.75 3.75 3.30964 3.75 4V16C3.75 16.6904 4.30964 17.25 5 17.25H15C15.6904 17.25 16.25 16.6904 16.25 16V4C16.25 3.30964 15.6904 2.75 15 2.75H14.0303C13.6161 2.75 13.2803 2.41421 13.2803 2C13.2803 1.58579 13.6161 1.25 14.0303 1.25H15C16.5188 1.25 17.75 2.48122 17.75 4V16C17.75 17.5188 16.5188 18.75 15 18.75H5C3.48122 18.75 2.25 17.5188 2.25 16V4Z" fill="currentColor" />
        <path d="M7.19385 2C7.19385 1.58579 7.52963 1.25 7.94385 1.25H11.238C11.6522 1.25 11.988 1.58579 11.988 2C11.988 2.41421 11.6522 2.75 11.238 2.75H7.94385C7.52963 2.75 7.19385 2.41421 7.19385 2Z" fill="currentColor" />
        <path d="M5.47266 5.41016C5.05844 5.41016 4.72266 5.74594 4.72266 6.16016C4.72266 6.57437 5.05844 6.91016 5.47266 6.91016H14.5315C14.9457 6.91016 15.2815 6.57437 15.2815 6.16016C15.2815 5.74594 14.9457 5.41016 14.5315 5.41016H5.47266Z" fill="currentColor" />
        <path d="M4.72266 9.85938C4.72266 9.44516 5.05844 9.10938 5.47266 9.10938H14.5315C14.9457 9.10938 15.2815 9.44516 15.2815 9.85938C15.2815 10.2736 14.9457 10.6094 14.5315 10.6094H5.47266C5.05844 10.6094 4.72266 10.2736 4.72266 9.85938Z" fill="currentColor" />
        <path d="M5.47266 12.8047C5.05844 12.8047 4.72266 13.1405 4.72266 13.5547C4.72266 13.9689 5.05844 14.3047 5.47266 14.3047H14.5315C14.9457 14.3047 15.2815 13.9689 15.2815 13.5547C15.2815 13.1405 14.9457 12.8047 14.5315 12.8047H5.47266Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default RecordsOutlined;
