import React from 'react';
import { IIconProps } from '..';

const ScreenOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.94999 5C7.71836 6.14112 6.70948 7 5.5 7C4.29052 7 3.28164 6.14112 3.05001 5H1.5C1.22386 5 1 4.77614 1 4.5C1 4.22386 1.22386 4 1.5 4H3.05001C3.28164 2.85888 4.29052 2 5.5 2C6.70948 2 7.71836 2.85888 7.94999 4H18.5C18.7761 4 19 4.22386 19 4.5C19 4.77614 18.7761 5 18.5 5H7.94999ZM5.5 6C4.67157 6 4 5.32843 4 4.5C4 3.67157 4.67157 3 5.5 3C6.32843 3 7 3.67157 7 4.5C7 5.32843 6.32843 6 5.5 6Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.5 12C8.70948 12 9.71836 12.8589 9.94999 14H18.5C18.7761 14 19 14.2239 19 14.5C19 14.7761 18.7761 15 18.5 15H9.94999C9.71836 16.1411 8.70948 17 7.5 17C6.29052 17 5.28164 16.1411 5.05001 15H1.5C1.22386 15 1 14.7761 1 14.5C1 14.2239 1.22386 14 1.5 14H5.05001C5.28164 12.8589 6.29052 12 7.5 12ZM6 14.5C6 15.3284 6.67157 16 7.5 16C8.32843 16 9 15.3284 9 14.5C9 13.6716 8.32843 13 7.5 13C6.67157 13 6 13.6716 6 14.5Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.95 9C15.7184 7.85888 14.7095 7 13.5 7C12.2905 7 11.2816 7.85888 11.05 9H1.5C1.22386 9 1 9.22386 1 9.5C1 9.77614 1.22386 10 1.5 10H11.05C11.2816 11.1411 12.2905 12 13.5 12C14.7095 12 15.7184 11.1411 15.95 10H18.5C18.7761 10 19 9.77614 19 9.5C19 9.22386 18.7761 9 18.5 9H15.95ZM13.5 11C12.6716 11 12 10.3284 12 9.5C12 8.67157 12.6716 8 13.5 8C14.3284 8 15 8.67157 15 9.5C15 10.3284 14.3284 11 13.5 11Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default ScreenOutlined;
