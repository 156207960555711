enum IconType {
  STATUS_SUCCESS = 'status-success',
  STATUS_FAILURE = 'status-failure',
  STATUS_WAITING = 'status-waiting',
  STATUS_WARNING = 'status-warning',
  TAG_ADDRESS = 'tag-address',
  TAG_STORE = 'tag-store',
  TAG_NETWORK = 'tag-network',
  TAG_VOUCHER = 'tag-voucher',
  TAG_ITEM = 'tag-item',
  ALIPAY = 'alipay',
  WECHAT_PAY = 'wechat-pay',
  APPLE_PAY = 'apple-pay',
  CREDIT_CARD = 'credit-card',
  OE_PAY = 'oe-pay',
  AE_CARD = 'ae-card',
  GOOGLE_PAY = 'google-pay',
  JCB_CARD = 'jcb-card',
  MASTER_CARD = 'master-card',
  TAPGO_PAY = 'tapgo-pay',
  UNION_PAY = 'union-pay',
  VISA_CARD = 'visa-card',
  CASH_ON_DELIVERY = 'cash-on-delivery',
  GIFT_CARD = 'gift-card',
  LOYALTY = 'loyalty',
  UP_OUTLINED = 'up-outlined',
  DOWN_OUTLINED = 'down-outlined',
  LEFT_OUTLINED = 'left-outlined',
  RIGHT_OUTLINED = 'right-outlined',
  CLOSE_OUTLINED = 'close-outlined',
  QUESTION_CIRCLE_OUTLINED = 'question-circle-outlined',
  SELECTED_OUTLINED = 'selected-outlined',
  UN_SELECTED_OUTLINED = 'un-selected-outlined',
  MINUS_OUTLINED = 'minus-outlined',
  MINUS_CIRCLE_OUTLINED = 'minus-circle-outlined',
  PLUS_OUTLINED = 'plus-outlined',
  PLUS_CIRCLE_OUTLINED = 'plus-circle-outlined',
  TIME_OUTLINED = 'time-outlined',
  STORE_OUTLINED = 'store-outlined',
  STAR_OUTLINED = 'star-outlined',
  RECYCLE_BIN_OUTLINED = 'recycle-bin-outlined',
  CHECKED_OUTLINED = 'checked-outlined',
  SEARCH_OUTLINED = 'search-outlined',
  PHONE_OUTLINED = 'phone-outlined',
  EMAIL_OUTLINED = 'email-outlined',
  EYE_OPEN_OUTLINED = 'eye-open-outlined',
  EYE_CLOSED_OUTLINED = 'eye-closed-outlined',
  PICK_UP_OUTLINED = 'pick-up-outlined',
  DELIVERY_OUTLINED = 'delivery-outlined',
  BOOK_OUTLINED = 'book-outlined',
  ADDRESS_OUTLINED = 'address-outlined',
  DEFAULT_AVATAR_OUTLINED = 'default-avatar-outlined',
  EDIT_OUTLINED = 'edit-outlined',
  CAMERA_OUTLINED = 'camera-outlined',
  TIME_SOLID_OUTLINED = 'time-solid-outlined',
  NAV_ACCOUNT_OUTLINED = 'nav-account-outlined',
  NAV_ORDERS_OUTLINED = 'nav-orders-outlined',
  MAP_OUTLINED = 'map-outlined',
  SHOPPING_CART_OUTLINED = 'shopping-cart-outlined',
  GUEST_OUTLINED = 'guest-outlined',
  ALREADY_FAVORITE_OUTLINED = 'already-favorite-outlined',
  NOT_FAVORITE_OUTLINED = 'not-favorite-outlined',
  SCAN_OUTLINED = 'scan-outlined',
  BOOKING_OUTLINED = 'booking-outlined',
  QUEUEING_OUTLINED = 'queueing-outlined',
  FACEBOOK_OUTLINED = 'facebook-outlined',
  APPLE_OUTLINED = 'apple-outlined',
  WECHAT_OUTLINED = 'wechat-outlined',
  TEL_PHONE_OUTLINED = 'tel-phone-outlined',
  LOADING = 'loading',
  NEWS_OUTLINED = 'news-outlined',
  ABOUT_US_OUTLINED = 'about-us-outlined',
  LOCATION_OUTLINED = 'location-outlined',
  QUICK_OUTLINED = 'quick-outlined',
  VOUCHER_OUTLINED = 'voucher-outlined',
  BOOKING_SETTING = 'booking-setting',
  QUEUEING_SETTING = 'queuein-setting',
  FAVORITE_SETTING = 'favorite-setting',
  PAYMENT_SETTING = 'payment-setting',
  BINDO_SETTING = 'bindo-setting',
  BECOME_PARTNER_SETTING = 'become-partner-setting',
  SETTING_OUTLINED = 'setting-outlined',
  HOME_OUTLINED = 'home-outlined',
  MEMBERS_OUTLINED = 'members-outlined',
  PROMOTIONS_OUTLINED = 'promotions-outlined',
  RECORDS_OUTLINED = 'records-outlined',
  SHOPPING_OUTLINED = 'shopping-outlined',
  STAFF_ID_OUTLINED = 'staff-id-outlined',
  QR_CODE_OUTLINED = 'qr-code-outlined',
  MEMBER_CARD_OUTLINED = 'member-card-outlined',
  EDIT_ADDRESS_OUTLINED = 'edit-address-outlined',
  MY_ID_OUTLINED = 'my-id-outlined',
  MEMBER_OUTLINED = 'member-outlined',
  MEMBER_TAG_OUTLINED = 'member-tag-outlined',
  MENUS_OUTLINED = 'menus-outlined',
  POSITION_OUTLINED = 'positon-outlined',
  GOOGLE_LOCALTION_OUTLINED = 'google-localtion-outlined',
  FOOD_ORDERING_OUTLINED = 'food-ordering-outlined',
  REFRESH_OUTLINED = 'refresh-outlined',
  SHARE_OUTLINED = 'share-outlined',
  DATE_OUTLINED = 'date-outlined',
  BOOKING_SETTING_BRAND = 'booking-setting-brand',
  QUEUEING_SETTING_BRAND = 'queueing-setting-brand',
  COMING_SOON_OUTLINED = 'coming-soon-outlined',
  DELIVEROO_OUTLINED = 'deliveroo-outlined',
  FOODPANDA_OUTLINED = 'foodpanda-outlined',
  STORE_URL_OUTLINED = 'store-url-outlined',
  CATEGORY_OUTLINED = 'category-outlined',
  SCREEN_OUTLINED = 'screen-outlined',
  STATUS_ERROR_OUTLINED = 'status-error-outlined',
  LEFT_ARROW_OUTLINED = 'left-arrow-outlined',
  EXPRSESS_ORDER_OUTLINED = 'express-order-outlined',
  WHATS_APP_OUTLINED = 'whats-app-outlined',
  APPLE_EMAIL_OUTLINED = 'apple-email-outlined',
  GEMAIL_OUTLINED = 'g-email-outlined',
  COPY_OUTLINED = 'copy-outlined',
  PRIVILEGE_OUTLINED = 'privilege-outlined',
  UN_SELECTED_SQUARE_OUTLINED = 'un-selected-square-outlined',
  CHECKED_SQUARE_OUTLINED = 'checked-square-outlined',
  ALIPAY_HK = 'alipay-hk',
  NAV_LOGO = 'nav-logo',
  SHOPPING_CART = 'shopping-cart',
  DEFERRED_INVOICE_OUTLINED = 'deferred-invoice-outlined',
  JUST_LOGO = 'just-logo',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  LOGOUT_OUTLINED = 'logout-outlined',
  SETTING2_OUTLINED = 'setting2-outlined',
  DOLLAR_OUTLINED = 'dollar-outlined',
  PAYMENT_OUTLINED = 'payment-outlined',
  RECORD_OUTLINED = 'record-outlined',
  GRN_OUTLINED = 'grn-outlined',
  RN_OUTLINED = 'rn-outlined',
  PAYMENT_FAILED = 'payment-failed',
};

export default IconType;
