import React from 'react';
import { IIconProps } from '..';

const LocationOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.9864 11.2768C22.9864 11.1013 22.9984 10.9234 22.9743 10.7507C22.8735 10.0285 22.3477 9.7115 21.6663 9.99516C21.2722 10.1593 20.9139 10.404 20.5354 10.6054C20.0882 10.8433 19.7844 11.163 19.9061 11.7009C20.0179 12.1956 20.3891 12.3576 20.8676 12.374C20.961 12.3772 21.0521 12.4443 21.1459 12.4825V19.0304L16.8444 21.1736C15.4265 20.4479 14.0957 19.7533 12.7525 19.0822C10.488 17.9507 11.2591 17.9617 9.06474 19.0765C8.37092 19.4289 7.68486 19.7959 6.78705 20.265C6.78705 18.249 6.8 16.4624 6.78104 14.676C6.77273 13.8909 6.71983 13.164 7.68099 12.7725C8.16465 12.5755 8.23323 11.9796 7.97182 11.596C7.6873 11.1786 7.26901 11.1103 6.83258 11.2943C6.43903 11.4603 6.08254 11.7081 5.69917 11.8996C5.19328 12.1522 4.99722 12.5411 5.00003 13.0939C5.01423 15.8677 4.99376 18.6418 5.01221 21.4156C5.02004 22.5902 5.58754 22.8893 6.6892 22.3259C7.86703 21.7236 9.04671 21.122 10.1961 20.4704C10.69 20.1904 11.0758 20.1809 11.5811 20.4556C13.0565 21.2573 14.5742 21.9845 16.0548 22.7773C16.6204 23.0801 17.0954 23.071 17.6645 22.769C19.1165 21.9988 20.5894 21.2634 22.0767 20.5603C22.7494 20.2423 23.0093 19.8028 22.9997 19.0718C22.9657 16.4738 22.9869 13.8752 22.9864 11.2768Z" fill="currentColor"/>
        <path d="M13.2482 15.891C13.8097 16.4134 14.1754 16.406 14.7433 15.8811C16.0291 14.6924 17.1799 13.386 17.8943 11.7871C18.2189 11.0608 18.5909 10.4329 18.6102 9.49855C18.6578 7.20623 16.9572 5.42034 14.6786 5.05704C12.5074 4.7109 10.3876 5.96981 9.70257 8.02761C9.31888 9.18015 9.44764 10.3162 9.91004 11.4193C10.6461 13.1751 11.8609 14.6002 13.2482 15.891ZM14.1213 6.80178C15.6653 6.86663 16.8693 8.20489 16.6667 9.7208C16.4604 11.2646 15.613 12.5324 14.6332 13.7085C14.2943 14.1153 13.7957 14.0882 13.388 13.7598C12.513 13.0549 11.315 10.583 11.3091 9.47046C11.301 7.9187 12.5475 6.73573 14.1213 6.80178Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default LocationOutlined;
