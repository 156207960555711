import React from 'react';
import { getWarningColor, IIconProps } from '..';
import StatusPath from '../common/StatusPath';
import StatusDefs from '../common/StatusDefs';

const StatusWarning: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <StatusPath tag="warning" />
        <path d="M71.597 58.6018C71.5888 58.2724 71.8535 58.001 72.183 58.001H76.8144C77.1439 58.001 77.4086 58.2724 77.4004 58.6018L76.8463 80.8776C76.8383 81.1956 76.5783 81.4493 76.2602 81.4493H72.7372C72.4191 81.4493 72.1591 81.1956 72.1511 80.8776L71.597 58.6018Z" fill="white"/>
        <ellipse cx="74.5" cy="88.4831" rx="3.5" ry="3.51724" fill="white"/>
        <StatusDefs  tag="warning" color={getWarningColor()} />
      </svg>
    </div>
  );
}

export default StatusWarning;
