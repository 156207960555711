import React from 'react';
import { IIconProps } from '..';

const DownOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7964 8.23116L10.3394 12.8536C10.1518 13.0484 9.84869 13.0484 9.66058 12.8536L5.20358 8.23116C4.93214 7.94999 4.93214 7.49254 5.20358 7.21087C5.47501 6.92971 5.91555 6.92971 6.18698 7.21087L10.0002 11.1652L13.8125 7.21087C14.0845 6.92971 14.525 6.92971 14.7964 7.21087C15.0679 7.49254 15.0679 7.94999 14.7964 8.23116Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default DownOutlined;
