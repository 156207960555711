import React from 'react';
import { IIconProps } from '..';

const EmailOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.8148 8.16075C16.9227 8.0733 17.0832 8.15511 17.0832 8.29335V14.0627C17.0832 14.8103 16.4883 15.4168 15.755 15.4168H4.24463C3.51139 15.4168 2.9165 14.8103 2.9165 14.0627V8.29617C2.9165 8.15511 3.07422 8.07612 3.1849 8.16357C3.80469 8.65446 4.62647 9.27794 7.44873 11.3684C8.03255 11.8029 9.01758 12.717 9.99984 12.7113C10.9876 12.7198 11.992 11.786 12.5537 11.3684C15.376 9.27794 16.195 8.65164 16.8148 8.16075ZM9.99984 11.8057C10.6418 11.817 11.5659 10.9819 12.0308 10.6378C15.7025 7.92095 15.9819 7.68397 16.8286 7.00689C16.9891 6.87994 17.0832 6.68245 17.0832 6.47369V5.93766C17.0832 5.19005 16.4883 4.5835 15.755 4.5835H4.24463C3.51139 4.5835 2.9165 5.19005 2.9165 5.93766V6.47369C2.9165 6.68245 3.01058 6.87712 3.17106 7.00689C4.01774 7.68115 4.2972 7.92095 7.96891 10.6378C8.43376 10.9819 9.35791 11.817 9.99984 11.8057Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default EmailOutlined;
