import React from 'react';
import { IIconProps } from '..';

const CopyOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7C1 5.89543 1.89543 5 3 5H12C13.1046 5 14 5.89543 14 7V16C14 17.1046 13.1046 18 12 18H3C1.89543 18 1 17.1046 1 16V7ZM3 6C2.44772 6 2 6.44772 2 7V16C2 16.5523 2.44772 17 3 17H12C12.5523 17 13 16.5523 13 16V7C13 6.44772 12.5523 6 12 6H3Z" fill="#BDBDBD"/>
        <path d="M5 3C5 1.89543 5.89543 1 7 1H16C17.1046 1 18 1.89543 18 3V13C18 14.1046 17.1046 15 16 15H15.3182C15.042 15 14.8182 14.7761 14.8182 14.5C14.8182 14.2239 15.042 14 15.3182 14H16C16.5523 14 17 13.5523 17 13V3C17 2.44772 16.5523 2 16 2H7C6.44772 2 6 2.44772 6 3V3.66667C6 3.94281 5.77614 4.16667 5.5 4.16667C5.22386 4.16667 5 3.94281 5 3.66667V3Z" fill="#BDBDBD"/>
        <path d="M4 11.5C4 11.2239 4.22386 11 4.5 11H10.5C10.7761 11 11 11.2239 11 11.5C11 11.7761 10.7761 12 10.5 12H4.5C4.22386 12 4 11.7761 4 11.5Z" fill="#BDBDBD"/>
        <path d="M7.5 8C7.77614 8 8 8.22386 8 8.5L8 14.5C8 14.7761 7.77614 15 7.5 15C7.22386 15 7 14.7761 7 14.5L7 8.5C7 8.22386 7.22386 8 7.5 8Z" fill="#BDBDBD"/>
      </svg>
    </div>
  );
}

export default CopyOutlined;
