import React from 'react';
import { IIconProps } from '..';

const DeliverooOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6176 1.52019L6.72447 2.49406L8.33967 9.95249L1 11.4252L2.1639 17.2447L14.9905 20L18.0071 12.9454L19.266 0.475059L14.1116 0L13.3515 8.55107L11.6176 1.52019ZM10.4537 12.1615C10.4537 12.6338 10.0708 13.0166 9.59857 13.0166C9.12631 13.0166 8.74347 12.6338 8.74347 12.1615C8.74347 11.6893 9.12631 11.3064 9.59857 11.3064C10.0708 11.3064 10.4537 11.6893 10.4537 12.1615ZM13.209 13.5392C13.6813 13.5392 14.0641 13.1563 14.0641 12.6841C14.0641 12.2118 13.6813 11.829 13.209 11.829C12.7368 11.829 12.3539 12.2118 12.3539 12.6841C12.3539 13.1563 12.7368 13.5392 13.209 13.5392Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default DeliverooOutlined;
