import React from 'react';
import { getErrorColor, IIconProps } from '..';
import StatusPath from '../common/StatusPath';
import StatusDefs from '../common/StatusDefs';

const StatusFailure: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <StatusPath tag="failure" />
        <path d="M87.0178 58.7375C86.2368 57.9565 84.9704 57.9565 84.1894 58.7375L74.9972 67.9298L65.8039 58.7365C65.0228 57.9554 63.7565 57.9554 62.9754 58.7365L58.7328 62.9791C57.9517 63.7602 57.9517 65.0265 58.7328 65.8075L67.9261 75.0008L58.7336 84.1934C57.9525 84.9744 57.9525 86.2408 58.7336 87.0218L62.9762 91.2645C63.7572 92.0455 65.0236 92.0455 65.8046 91.2645L74.9972 82.0719L84.1886 91.2634C84.9697 92.0444 86.236 92.0444 87.0171 91.2634L91.2597 87.0208C92.0408 86.2397 92.0408 84.9734 91.2597 84.1923L82.0682 75.0008L91.2605 65.8086C92.0415 65.0276 92.0415 63.7612 91.2605 62.9802L87.0178 58.7375Z" fill="white"/>
        <StatusDefs tag="failure" color={getErrorColor()} />
      </svg>
    </div>
  );
}

export default StatusFailure;
