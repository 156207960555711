import React from 'react';
import { IIconProps } from '..';

const ShareOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 8C17.6569 8 19 6.65685 19 5C19 3.34315 17.6569 2 16 2C14.3431 2 13 3.34315 13 5C13 5.21613 13.0229 5.42693 13.0663 5.6301L6.26354 9.03108C5.71354 8.39932 4.9034 8 4 8C2.34315 8 1 9.34315 1 11C1 12.6569 2.34315 14 4 14C5.02687 14 5.93324 13.4841 6.47404 12.6973L12.0137 14.7117C12.0046 14.8066 12 14.9028 12 15C12 16.6569 13.3431 18 15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12C13.9734 12 13.0672 12.5156 12.5264 13.3021L6.9864 11.2875C6.9954 11.1929 7 11.097 7 11C7 10.7849 6.97736 10.575 6.93432 10.3727L13.7384 6.97111C14.2883 7.6016 15.0976 8 16 8Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default ShareOutlined;
