import React from 'react';
import { IIconProps } from '..';

const RNOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.18255 12.2778C7.66952 12.2778 6.29922 12.8622 5.24295 13.8125L3.1875 11.6875V17H8.32612L6.25925 14.8632C7.05288 14.1785 8.06348 13.7535 9.18255 13.7535C11.2037 13.7535 12.9223 15.117 13.5218 17L14.875 16.5396C14.0814 14.0663 11.8375 12.2778 9.18255 12.2778Z" fill="currentColor"/>
        <rect x="0.5" y="2.625" width="16" height="19" stroke="currentColor"/>
        <path d="M6.875 1C6.875 0.723858 7.09886 0.5 7.375 0.5H9.625C9.90114 0.5 10.125 0.723858 10.125 1V4.8125H6.875V1Z" stroke="currentColor"/>
        <rect x="7.4375" y="1.0625" width="2.125" height="2.125" fill="white"/>
      </svg>
    </div>
  );
}

export default RNOutlined;
