import React from 'react';
import { IIconProps } from '..';

const BookOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.12402 6.125V13.875H13.874V6.125H6.12402ZM5.80259 4.375C5.01362 4.375 4.37402 5.01459 4.37402 5.80357V14.1964C4.37402 14.9854 5.01362 15.625 5.80259 15.625H14.1955C14.9844 15.625 15.624 14.9854 15.624 14.1964V5.80357C15.624 5.01459 14.9844 4.375 14.1955 4.375H5.80259Z" fill="currentColor"/>
        <path d="M7.85589 2.875C7.85589 2.18464 8.41553 1.625 9.10589 1.625H10.8916C11.582 1.625 12.1416 2.18464 12.1416 2.875C12.1416 3.01307 12.0297 3.125 11.8916 3.125H8.10589C7.96782 3.125 7.85589 3.01307 7.85589 2.875Z" fill="currentColor"/>
        <path d="M17.3184 7.85767C18.0087 7.85767 18.5684 8.41731 18.5684 9.10767V10.8934C18.5684 11.5837 18.0087 12.1434 17.3184 12.1434C17.1803 12.1434 17.0684 12.0315 17.0684 11.8934V8.10767C17.0684 7.96959 17.1803 7.85767 17.3184 7.85767Z" fill="currentColor"/>
        <path d="M7.85617 17.3214C7.85617 18.0117 8.41581 18.5714 9.10617 18.5714H10.8919C11.5822 18.5714 12.1419 18.0117 12.1419 17.3214C12.1419 17.1833 12.03 17.0714 11.8919 17.0714H8.10617C7.9681 17.0714 7.85617 17.1833 7.85617 17.3214Z" fill="currentColor"/>
        <path d="M2.67969 7.85767C1.98933 7.85767 1.42969 8.41731 1.42969 9.10767L1.42969 10.8934C1.42969 11.5837 1.98933 12.1434 2.67969 12.1434C2.81776 12.1434 2.92969 12.0315 2.92969 11.8934L2.92969 8.10767C2.92969 7.9696 2.81776 7.85767 2.67969 7.85767Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default BookOutlined;
