import { getAppTheme } from '@/components/App/App';
import './Icon.less';
import IconType from './IconType';

export { default as IconType } from './IconType';

export { default } from './Icon';

export const getPrimaryColor = () => getAppTheme().primaryColor || '';
export const getErrorColor = () => getAppTheme().errorColor || '';
export const getWarningColor = () => getAppTheme().warningColor || '';

export interface IIconProps {
  type: IconType;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  showTagColor?: boolean;
  lightColor?: string;
  darkColor?: string;
  tag?: string;
  logoSvg?: string;
}
