import React from 'react';
import { IIconProps } from '..';

const VoucherOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.125 11.875V16.3068C18.125 16.4038 18.1059 16.4999 18.0688 16.5895C18.0317 16.6791 17.9772 16.7605 17.9087 16.8291C17.8401 16.8977 17.7586 16.9521 17.669 16.9892C17.5794 17.0263 17.4834 17.0455 17.3864 17.0455H2.61364C2.51664 17.0455 2.42059 17.0263 2.33097 16.9892C2.24136 16.9521 2.15993 16.8977 2.09134 16.8291C2.02275 16.7605 1.96835 16.6791 1.93123 16.5895C1.89411 16.4999 1.875 16.4038 1.875 16.3068V11.875C2.2668 11.875 2.64255 11.7194 2.91959 11.4423C3.19663 11.1653 3.35227 10.7895 3.35227 10.3977C3.35227 10.0059 3.19663 9.63018 2.91959 9.35314C2.64255 9.07609 2.2668 8.92045 1.875 8.92045V4.48864C1.875 4.39164 1.89411 4.29559 1.93123 4.20597C1.96835 4.11636 2.02275 4.03493 2.09134 3.96634C2.15993 3.89775 2.24136 3.84335 2.33097 3.80623C2.42059 3.76911 2.51664 3.75 2.61364 3.75H17.3864C17.4834 3.75 17.5794 3.76911 17.669 3.80623C17.7586 3.84335 17.8401 3.89775 17.9087 3.96634C17.9772 4.03493 18.0317 4.11636 18.0688 4.20597C18.1059 4.29559 18.125 4.39164 18.125 4.48864V8.92045C17.7332 8.92045 17.3575 9.07609 17.0804 9.35314C16.8034 9.63018 16.6477 10.0059 16.6477 10.3977C16.6477 10.7895 16.8034 11.1653 17.0804 11.4423C17.3575 11.7194 17.7332 11.875 18.125 11.875ZM16.6477 12.6136C15.98 12.2443 15.1705 11.6852 15.1705 10.3977C15.1705 9.11028 15.98 8.55114 16.6477 8.18182V5.22727H3.35227V8.18182C4.09091 8.55114 4.82955 9.18932 4.82955 10.3977C4.82955 11.6061 4.09091 12.2443 3.35227 12.6136V15.5682H16.6477V12.6136ZM7.04545 7.44318H8.52273V13.3523H7.04545V7.44318Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default VoucherOutlined;
