import React from 'react';
import { getPrimaryColor, IIconProps } from '..';

const Loyalty: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V16C24 17.1046 23.1046 18 22 18H2C0.89543 18 0 17.1046 0 16V2ZM2.00006 3H22.0001V5H2.00006V3ZM17.3847 11.5391H14.0001V12.5391H17.3847V11.5391ZM3.64892 14.2361L4.09795 11.618L2.19583 9.76393L4.82449 9.38197L6.00006 7L7.17563 9.38197L9.80429 9.76393L7.90217 11.618L8.3512 14.2361L6.00006 13L3.64892 14.2361ZM20.7693 9H14.0001V10H20.7693V9Z" fill={getPrimaryColor()}/>
      </svg>
    </div>
  );
}

export default Loyalty;
