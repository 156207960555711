import React from 'react';
import { IIconProps } from '..';

const AddressOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0022 10C10.9226 10 11.6688 9.25381 11.6688 8.33333C11.6688 7.41286 10.9226 6.66667 10.0022 6.66667C9.0817 6.66667 8.3355 7.41286 8.3355 8.33333C8.3355 9.25381 9.0817 10 10.0022 10ZM13.3355 8.33333C13.3355 10.1743 11.8431 11.6667 10.0022 11.6667C8.16122 11.6667 6.66884 10.1743 6.66884 8.33333C6.66884 6.49238 8.16122 5 10.0022 5C11.8431 5 13.3355 6.49238 13.3355 8.33333Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.3962 12.6319C16.6638 11.0191 17.2244 9.62733 17.2244 8.20099C17.2244 5.12678 14.2937 1.66667 10.0022 1.66667C5.71069 1.66667 2.77995 5.12678 2.77995 8.20099C2.77995 9.62365 3.33745 10.9924 4.60603 12.5947C5.83895 14.152 7.64302 15.8061 10.0121 17.8251C12.369 15.8487 14.1683 14.1942 15.3962 12.6319ZM10.7162 19.4087C10.3042 19.7518 9.70276 19.7485 9.29383 19.4019C4.21586 15.0973 1.11328 12.0465 1.11328 8.20099C1.11328 4.17976 4.81699 0 10.0022 0C15.1874 0 18.8911 4.17976 18.8911 8.20099C18.8911 12.0451 15.7908 15.1831 10.7162 19.4087Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default AddressOutlined;
