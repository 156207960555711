import React from 'react';
import { IIconProps } from '..';

const RefreshOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M31.5412 12.0748L27.7512 14.6378C27.7212 14.6708 27.7012 14.7138 27.6712 14.7378C27.6512 14.8228 27.6412 14.9118 27.6212 14.9968H25.5512C25.6228 14.7552 25.6796 14.5094 25.7212 14.2608L22.4812 12.0698C22.0133 11.7151 21.866 11.0766 22.1312 10.5528C22.2296 10.3079 22.4328 10.1201 22.6847 10.0413C22.9366 9.96242 23.2106 10.0008 23.4312 10.1458L26.0012 11.8848C25.9573 7.84449 23.4844 4.22823 19.7352 2.72175C15.9859 1.21527 11.6984 2.11511 8.87118 5.00183H6.28118C9.30503 0.78366 14.7008 -1.00817 19.6472 0.563214C24.5937 2.1346 27.9661 6.7119 28.0012 11.9018L30.5912 10.1548C30.8117 10.0098 31.0857 9.97142 31.3376 10.0503C31.5895 10.1291 31.7927 10.3169 31.8912 10.5618C32.1541 11.0846 32.007 11.7206 31.5412 12.0748ZM9.33735 11.9578C9.08548 12.0364 8.81159 11.9979 8.59118 11.8528L6.18118 10.2268C6.07365 10.8125 6.01677 11.4064 6.01118 12.0018C6.01178 16.064 8.46966 19.7222 12.2303 21.2581C15.991 22.7939 20.3071 21.9022 23.1512 19.0018H25.7412C22.7045 23.2342 17.2823 25.0213 12.3241 23.4241C7.36596 21.8269 4.00649 17.2109 4.01118 12.0018C4.01663 11.3212 4.08357 10.6424 4.21118 9.97383L1.43118 11.8528C1.21077 11.9979 0.93689 12.0364 0.685013 11.9578C0.433136 11.8791 0.229853 11.6916 0.131184 11.4468C-0.134135 10.9227 0.013163 10.2839 0.481184 9.92883L4.27118 7.36683C4.42743 7.16299 4.66477 7.03739 4.92118 7.02283C4.98071 7.01216 5.04166 7.01216 5.10118 7.02283C5.3576 7.03739 5.59494 7.16299 5.75118 7.36683L9.54118 9.92883C10.0092 10.2839 10.1565 10.9227 9.89118 11.4468C9.79251 11.6916 9.58923 11.8791 9.33735 11.9578Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default RefreshOutlined;
