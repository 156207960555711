import React from 'react';
import { IIconProps } from '..';

const QuickOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path fillRule="evenodd" clipRule="evenodd" d="M4.28563 16.1089C5.68603 16.1089 6.82129 14.9736 6.82129 13.5732C6.82129 12.1728 5.68603 11.0376 4.28563 11.0376C2.88522 11.0376 1.74996 12.1728 1.74996 13.5732C1.74996 14.9736 2.88522 16.1089 4.28563 16.1089ZM4.28563 17.8588C6.65251 17.8588 8.57125 15.9401 8.57125 13.5732C8.57125 11.2063 6.65251 9.28759 4.28563 9.28759C1.91874 9.28759 0 11.2063 0 13.5732C0 15.9401 1.91874 17.8588 4.28563 17.8588Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M15.7161 16.109C17.1165 16.109 18.2517 14.9738 18.2517 13.5734C18.2517 12.173 17.1165 11.0377 15.7161 11.0377C14.3156 11.0377 13.1804 12.173 13.1804 13.5734C13.1804 14.9738 14.3156 16.109 15.7161 16.109ZM15.7161 17.859C18.0829 17.859 20.0017 15.9403 20.0017 13.5734C20.0017 11.2065 18.0829 9.28776 15.7161 9.28776C13.3492 9.28776 11.4304 11.2065 11.4304 13.5734C11.4304 15.9403 13.3492 17.859 15.7161 17.859Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M4.14277 5.00196C4.14277 4.52859 4.52652 4.14484 4.9999 4.14484H7.85698C8.33036 4.14484 8.7141 4.52859 8.7141 5.00196C8.7141 5.47534 8.33036 5.85909 7.85698 5.85909H4.9999C4.52652 5.85909 4.14277 5.47534 4.14277 5.00196Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M11.0054 2.40266C11.0603 1.92255 11.494 1.57782 11.9741 1.63269L15.099 1.98983C15.5792 2.0447 15.9239 2.47839 15.869 2.9585C15.8141 3.43862 15.3804 3.78334 14.9003 3.72847L11.7754 3.37134C11.2953 3.31647 10.9506 2.88278 11.0054 2.40266Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M6.09513 4.33116C6.4656 4.14592 6.9161 4.29608 7.10134 4.66656L8.52988 7.52364C8.71512 7.89412 8.56495 8.34461 8.19447 8.52985C7.824 8.71509 7.3735 8.56493 7.18826 8.19445L5.75972 5.33737C5.57449 4.96689 5.72465 4.51639 6.09513 4.33116Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M13.6013 2.39184C14.0073 2.30989 14.4029 2.5726 14.4849 2.97862L16.4491 12.7106C16.5311 13.1166 16.2684 13.5122 15.8623 13.5941C15.4563 13.676 15.0607 13.4133 14.9788 13.0073L13.0146 3.27538C12.9326 2.86937 13.1953 2.47379 13.6013 2.39184Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M14.1902 6.98994C14.6705 6.93657 15.1031 7.28266 15.1564 7.76294C15.2098 8.24323 14.8637 8.67583 14.3834 8.7292L8.34329 9.40033L4.99902 14.0823C4.71814 14.4755 4.17167 14.5666 3.77845 14.2857C3.38522 14.0049 3.29414 13.4584 3.57502 13.0652L6.961 8.32479C7.22348 7.95732 7.6299 7.71886 8.07872 7.66899L14.1902 6.98994Z" fill="currentColor"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20.0017" height="19.9996" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default QuickOutlined;
