import React from 'react';
import { IIconProps } from '..';

const PositionOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.8564 12.145L0.477102 9.07061C0.335971 9.01271 0.215261 8.9142 0.130309 8.78761C0.0453576 8.66102 0 8.51206 0 8.35966C0 8.20727 0.0453576 8.05831 0.130309 7.93172C0.215261 7.80513 0.335971 7.70662 0.477102 7.64872L18.9446 0.0550406C19.0844 -0.00086771 19.2376 -0.0145543 19.3851 0.0156782C19.5327 0.0459107 19.6681 0.118732 19.7746 0.225115C19.8811 0.331498 19.954 0.466764 19.9843 0.614144C20.0146 0.761523 20.0009 0.914534 19.9449 1.05421L12.3117 19.5234C12.2537 19.6644 12.1551 19.785 12.0283 19.8698C11.9016 19.9547 11.7525 20 11.5999 20C11.4473 20 11.2982 19.9547 11.1715 19.8698C11.0447 19.785 10.9461 19.6644 10.8881 19.5234L7.8564 12.145ZM3.3857 8.35966L9.12601 10.6693C9.31403 10.7473 9.46345 10.8966 9.54153 11.0844L11.5999 16.6182L17.5441 2.47615L3.3857 8.35966Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default PositionOutlined;
