import React from 'react';
import { IIconProps } from '..';

const GRNOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.39062 12.6437L12.6996 10.2871V16.5325L7.39062 18.8886V12.6437Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.64062 9.25778L6.65477 11.496L11.6816 9.26457L6.60311 7.26172L1.64062 9.25778Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 10.2871L6.11284 12.5671V18.608L1 16.3285V10.2871Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.8422 6.17323L4.82422 1H15.4421L18.9992 4.93753V19H12.5785" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.0625 1V4.91304H19" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}

export default GRNOutlined;
