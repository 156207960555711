import React from 'react';
import { IIconProps } from '..';

const MemberCardOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.2353 4.11788H1.76471C1.43983 4.11788 1.17647 4.38124 1.17647 4.70611V15.2943C1.17647 15.6192 1.43983 15.8826 1.76471 15.8826H18.2353C18.5602 15.8826 18.8235 15.6192 18.8235 15.2943V4.70611C18.8235 4.38124 18.5602 4.11788 18.2353 4.11788ZM1.76471 2.94141C0.790086 2.94141 0 3.73149 0 4.70611V15.2943C0 16.269 0.790086 17.0591 1.76471 17.0591H18.2353C19.2099 17.0591 20 16.269 20 15.2943V4.70611C20 3.73149 19.2099 2.94141 18.2353 2.94141H1.76471Z" fill="currentColor"/>
        <path d="M2.64706 6.63552C2.64706 6.31065 2.91042 6.04729 3.23529 6.04729H7.02206C7.34693 6.04729 7.61029 6.31065 7.61029 6.63552V8.00023C7.61029 8.3251 7.34693 8.58847 7.02206 8.58847H3.23529C2.91042 8.58847 2.64706 8.3251 2.64706 8.00023V6.63552Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default MemberCardOutlined;
