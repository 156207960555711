import React from 'react';
import { IIconProps } from '..';

const MinusOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 10.25C6 9.83579 6.33579 9.5 6.75 9.5H13.25C13.6642 9.5 14 9.83579 14 10.25C14 10.6642 13.6642 11 13.25 11H6.75C6.33579 11 6 10.6642 6 10.25Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default MinusOutlined;
