import React from 'react';
import { IIconProps } from '..';

const PromotionsOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.34731 5.52996C9.05835 6.37524 8.94953 7.63689 8.10425 8.34792C7.25897 9.05896 5.99732 8.95014 5.28628 8.10486C4.57525 7.25958 4.68407 5.99793 5.52935 5.28689C6.37463 4.57586 7.63628 4.68468 8.34731 5.52996ZM7.19943 6.49555C7.37719 6.70687 7.34998 7.02228 7.13866 7.20004C6.92734 7.3778 6.61193 7.35059 6.43417 7.13927C6.25641 6.92795 6.28362 6.61254 6.49494 6.43478C6.70626 6.25702 7.02167 6.28423 7.19943 6.49555Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.947081 7.91609C0.699432 8.58972 0.831724 9.34441 1.29373 9.89364L8.70043 18.6987C9.41147 19.544 10.6731 19.6528 11.5184 18.9418L18.2782 13.2556C19.1235 12.5445 19.2323 11.2829 18.5212 10.4376L11.1145 1.63252C10.6525 1.08328 9.93164 0.823716 9.22555 0.952355L4.22103 1.8641C3.52433 1.99103 2.94669 2.47693 2.70233 3.14161L0.947081 7.91609ZM17.3734 11.4032L16.7837 10.7022L9.25865 17.0321L9.84832 17.7331C10.0261 17.9444 10.3415 17.9717 10.5528 17.7939L17.3126 12.1077C17.5239 11.9299 17.5511 11.6145 17.3734 11.4032ZM8.29306 15.8842L15.8181 9.55429L9.96665 2.59811C9.85115 2.4608 9.67093 2.3959 9.4944 2.42806L4.48988 3.33981C4.31571 3.37155 4.1713 3.49302 4.11021 3.65919L2.35496 8.43367C2.29304 8.60208 2.32612 8.79075 2.44162 8.92806L8.29306 15.8842Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default PromotionsOutlined;
