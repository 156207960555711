import React from 'react';
import { IIconProps } from '..';

const AddressOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.8046 8.51833C15.0909 8.21895 15.0802 7.7442 14.7809 7.45793C14.4815 7.17167 14.0067 7.1823 13.7205 7.48167L9.48713 11.9089L7.23095 9.50044C6.94777 9.19814 6.47315 9.18264 6.17086 9.46582C5.86856 9.749 5.85306 10.2236 6.13624 10.5259L8.93418 13.5127C9.07506 13.6631 9.27162 13.7489 9.47769 13.75C9.68376 13.751 9.88118 13.6673 10.0236 13.5183L14.8046 8.51833Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.98609 1.25C5.28986 1.25 4.63672 1.58715 4.23346 2.1547L1.02237 6.67401C0.450681 7.47861 0.500263 8.56911 1.14261 9.31851L8.36786 17.748C9.22592 18.749 10.7746 18.749 11.6327 17.748L18.8201 9.36264C19.4889 8.58235 19.512 7.43783 18.8752 6.6312L15.2725 2.06776C14.8647 1.55131 14.243 1.25 13.585 1.25H5.98609ZM5.45623 3.02351C5.57815 2.85193 5.7756 2.75 5.98609 2.75H13.585C13.7839 2.75 13.9719 2.84109 14.0952 2.99723L17.6979 7.56067C17.8904 7.80453 17.8834 8.15055 17.6812 8.38645L10.4938 16.7718C10.2344 17.0744 9.76616 17.0744 9.50675 16.7718L2.28149 8.34232C2.08729 8.11576 2.0723 7.78607 2.24514 7.54282L5.45623 3.02351Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default AddressOutlined;
