import React from 'react';

interface IDefsProps {
  color: string;
}

const Defs: React.FC<IDefsProps> = props => {
  const { color } = props;
  return (
    <defs>
      <linearGradient id="paint0_linear" x1="16.2946" y1="80.967" x2="10.232" y2="69.7492" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="111.415" y1="34.442" x2="105.888" y2="28.7761" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="44.2854" y1="117.002" x2="40.597" y2="113.22" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint3_linear" x1="38.1388" y1="43.1455" x2="28.3029" y2="33.062" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint4_linear" x1="125.056" y1="112.87" x2="116.204" y2="103.795" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint5_linear" x1="17.1004" y1="3.49614" x2="15.6828" y2="-3.27193" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint6_linear" x1="15.3235" y1="4.11058" x2="13.2008" y2="-3.61273" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint7_linear" x1="25.8259" y1="4.11058" x2="24.5064" y2="-3.98094" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
      <linearGradient id="paint8_linear" x1="103.05" y1="111.001" x2="32.9615" y2="57.745" gradientUnits="userSpaceOnUse">
        <stop stopColor={color}/>
        <stop offset="1" stopColor={color} stopOpacity="0.5"/>
      </linearGradient>
    </defs>
  );
}

export default Defs;
