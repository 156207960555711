import React from 'react';
import { IIconProps } from '..';

const CloseOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.4383 6.6281C14.7999 6.25565 14.7999 5.65179 14.4383 5.27934C14.0766 4.90689 13.4903 4.90689 13.1287 5.27934L9.99993 8.50172L6.87116 5.27934C6.50953 4.90689 5.92321 4.90689 5.56158 5.27934C5.19995 5.65179 5.19995 6.25565 5.56158 6.6281L8.69035 9.85048L5.27122 13.3719C4.90959 13.7444 4.90959 14.3482 5.27122 14.7207C5.63285 15.0931 6.21917 15.0931 6.5808 14.7207L9.99993 11.1992L13.4191 14.7207C13.7807 15.0931 14.367 15.0931 14.7286 14.7207C15.0903 14.3482 15.0903 13.7444 14.7286 13.3719L11.3095 9.85048L14.4383 6.6281Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default CloseOutlined;
