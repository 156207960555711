import React from 'react';
import { IIconProps } from '..';

const StatusSuccess: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.93995 2.57143C9.37984 1.80952 10.4796 1.80952 10.9194 2.57143L18.8374 16.2857C19.2773 17.0476 18.7274 18 17.8476 18H2.01175C1.13198 18 0.58212 17.0476 1.02201 16.2857L8.93995 2.57143Z" fill="url(#paint0_linear)"/>
        <path d="M9.32215 12.9873C9.23221 12.7623 9.16686 12.4213 9.12832 11.9625L8.92165 8.42033C8.88247 7.72987 8.86328 7.23445 8.86328 6.93372C8.86328 6.52478 8.95734 6.2052 9.14498 5.97553C9.33277 5.74622 9.57941 5.63184 9.88617 5.63184C9.90203 5.63184 9.91456 5.63601 9.92979 5.63673C9.94581 5.63601 9.95802 5.63184 9.97436 5.63184C10.2805 5.63184 10.5276 5.74622 10.7157 5.97553C10.9032 6.20483 10.9966 6.52423 10.9966 6.93354C10.9966 7.23427 10.9769 7.72969 10.9384 8.42015L10.7313 11.9625C10.6919 12.4213 10.6277 12.7623 10.5373 12.9873C10.4467 13.2115 10.2846 13.3233 10.0519 13.3233H9.80813C9.57482 13.3233 9.41319 13.2115 9.32215 12.9873Z" fill="white"/>
        <path d="M9.93756 13.9619C9.64024 13.9619 9.38765 14.0653 9.1779 14.272C8.96831 14.4789 8.86328 14.73 8.86328 15.0248C8.86328 15.3576 8.96958 15.6196 9.1806 15.8098C9.39257 16.0001 9.63976 16.0952 9.92233 16.0952C10.21 16.0952 10.4608 16.0015 10.6753 15.8135C10.8895 15.6261 10.9966 15.3627 10.9966 15.0252C10.9966 14.7303 10.8941 14.4792 10.6891 14.2723C10.4841 14.0653 10.2336 13.9619 9.93709 13.9619" fill="white"/>
        <defs>
          <linearGradient id="paint0_linear" x1="6.21196" y1="-0.657487" x2="15.0244" y2="20.9604" gradientUnits="userSpaceOnUse">
            <stop offset="0.0871049" stopColor="#D81616" stopOpacity="0.71"/>
            <stop offset="1" stopColor="#800000"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default StatusSuccess;
