import React from 'react';
import { IIconProps } from '..';

const RecycleBinOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="-1 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.875 4H2.75C2.375 4 2.125 4.25 2.125 4.625C2.125 5 2.375 5.25 2.75 5.25H15.25C15.625 5.25 15.875 5 15.875 4.625C15.875 4.25 15.625 4 15.25 4H12.125V3.125C12.125 2.25 11.375 1.5 10.5 1.5H7.5C6.625 1.5 5.875 2.25 5.875 3.125V4ZM10.5 2.75C10.75 2.75 10.875 2.875 10.875 3.125V4H7.125V3.125C7.125 2.875 7.25 2.75 7.5 2.75H10.5Z" fill="currentColor"/>
        <path d="M4.625 7.125C4.625 6.75 4.375 6.5 4 6.5C3.625 6.5 3.375 6.75 3.5 7.125V14.5C3.5 15.625 4.375 16.5 5.5 16.5H12.625C13.75 16.5 14.625 15.625 14.625 14.5V7.125C14.625 6.75 14.375 6.5 14 6.5C13.625 6.5 13.375 6.75 13.375 7.125V14.5C13.375 14.875 13.125 15.25 12.625 15.25H5.375C5 15.25 4.625 15 4.625 14.5V7.125Z" fill="currentColor"/>
        <path d="M10 7.125V13.375C10 13.75 10.25 14 10.625 14C11 14 11.25 13.75 11.25 13.375V7.125C11.25 6.75 10.75 6.5 10.5 6.5C10.25 6.5 10 6.75 10 7.125Z" fill="currentColor"/>
        <path d="M6.75 13.375V7.125C6.75 6.75 7.125 6.5 7.5 6.5C7.875 6.5 8 6.75 8 7.125V13.375C8 13.75 7.75 14 7.5 14C7.25 14 6.75 13.75 6.75 13.375Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default RecycleBinOutlined;
