import React from 'react';
import { IIconProps } from '..';

const JustLogo: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="136" height="90" viewBox="0 0 136 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.6" d="M89.9878 44.9995L112.488 67.4996L89.9878 89.9997L67.4877 67.4996L89.9878 44.9995Z" fill="white"/>
        <path opacity="0.6" d="M44.9756 44.9878L67.4657 67.4779L44.9756 89.9679L22.4855 67.4779L44.9756 44.9878Z" fill="white"/>
        <rect x="22.52" y="22.5293" width="31.8483" height="31.8483" transform="rotate(45 22.52 22.5293)" fill="white" fillOpacity="0.4"/>
        <path opacity="0.6" d="M90.0107 0L112.531 22.5202L90.0107 45.0404L67.4906 22.5202L90.0107 0Z" fill="white"/>
        <path opacity="0.6" d="M45.0234 0L67.5436 22.5202L45.0234 45.0404L22.5033 22.5202L45.0234 0Z" fill="white"/>
        <rect x="112.524" y="22.501" width="31.8483" height="31.8483" transform="rotate(45 112.524 22.501)" fill="white" fillOpacity="0.4"/>
        <path d="M67.5146 22.501L90.0348 45.0212L67.5146 67.5413L44.9945 45.0212L67.5146 22.501Z" fill="white"/>
      </svg>
    </div>
  );
}

export default JustLogo;
