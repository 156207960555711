import React from 'react';
import { IIconProps } from '..';

const LeftOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.7686 5.20358L7.14608 9.66058C6.95131 9.8482 6.95131 10.1513 7.14608 10.3394L11.7686 14.7964C12.0497 15.0679 12.5072 15.0679 12.7889 14.7964C13.07 14.525 13.07 14.0845 12.7889 13.813L8.83456 9.99976L12.7889 6.18747C13.07 5.91555 13.07 5.47501 12.7889 5.20358C12.5072 4.93214 12.0497 4.93214 11.7686 5.20358Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default LeftOutlined;
