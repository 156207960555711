import React from 'react';
import { IIconProps } from '..';

const SearchOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#search-clip0)">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.81833 15.9045C12.7145 15.9045 15.873 12.7397 15.873 8.83582C15.873 4.93191 12.7145 1.76716 8.81833 1.76716C4.92215 1.76716 1.76367 4.93191 1.76367 8.83582C1.76367 12.7397 4.92215 15.9045 8.81833 15.9045ZM8.81833 17.6716C10.9423 17.6716 12.8909 16.9193 14.4131 15.666L18.477 19.738C18.8214 20.083 19.3846 20.0881 19.7351 19.7492C20.0856 19.4104 20.0905 18.8559 19.7461 18.5109L15.6575 14.4141C16.8948 12.893 17.6367 10.9512 17.6367 8.83582C17.6367 3.95593 13.6886 0 8.81833 0C3.9481 0 0 3.95593 0 8.83582C0 13.7157 3.9481 17.6716 8.81833 17.6716Z" fill="currentColor"/>
        </g>
        <defs>
          <clipPath id="search-clip0">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default SearchOutlined;
