import React from 'react';
import { IIconProps } from '..';

const GEmailOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.36365 20.0001H4.54549V9.70028L0 5.15625V18.1825C0 19.1882 0.611368 20.0001 1.36365 20.0001Z" fill="#4285F4" />
        <path d="M15.4551 20.0001H18.6369C19.3915 20.0001 20.0006 19.1852 20.0006 18.1825V5.15625L15.4551 9.70028" fill="#34A853" />
        <path d="M15.4551 1.82277V9.69908L20.0006 5.15505V2.73157C20.0006 0.483794 18.0756 -0.797621 16.7278 0.550439" fill="#FBBC04" />
        <path d="M4.5459 9.69857V1.82227L10.0005 7.27509L15.4551 1.82227V9.69857L10.0005 15.1514" fill="#EA4335" />
        <path d="M0 2.73157V5.15505L4.54549 9.69908V1.82277L3.27275 0.550439C1.92274 -0.797621 0 0.483794 0 2.73157" fill="#C5221F" />
      </svg>
    </div>
  );
}

export default GEmailOutlined;
