import React from 'react';
import { IIconProps } from '..';

const ApplePay: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.25" y="1.25" width="21.5" height="16" rx="1.75" fill="white" stroke="#BDBDBD" strokeWidth="0.5" />
        <path d="M6.1123 6.92069C5.69137 6.92069 5.3518 7.17415 5.1325 7.17415C4.90258 7.17415 4.59484 6.93477 4.23051 6.93477C3.53722 6.93477 2.83331 7.50857 2.83331 8.58224C2.83331 9.2546 3.09507 9.96217 3.41695 10.4233C3.68932 10.807 3.93338 11.1238 4.28003 11.1238C4.62314 11.1238 4.77524 10.8985 5.20324 10.8985C5.63478 10.8985 5.73382 11.1168 6.1123 11.1168C6.49078 11.1168 6.74192 10.7753 6.97892 10.4374C7.24067 10.0467 7.35386 9.66647 7.3574 9.64887C7.33617 9.64183 6.61459 9.34965 6.61459 8.52592C6.61459 7.81483 7.18054 7.49801 7.21237 7.47337C6.84097 6.93477 6.27148 6.92069 6.1123 6.92069ZM5.91776 6.46658C6.08754 6.25889 6.20781 5.97727 6.20781 5.69213C6.20781 5.65341 6.20427 5.61469 6.19719 5.58301C5.92129 5.59357 5.58172 5.76606 5.3801 6.00191C5.22446 6.18145 5.0759 6.46658 5.0759 6.75172C5.0759 6.79397 5.08298 6.83973 5.08651 6.85381C5.1042 6.85733 5.1325 6.86085 5.16079 6.86085C5.41194 6.86085 5.73028 6.69188 5.91776 6.46658Z" fill="black" />
        <path d="M9.13308 5.98079V11.0605H10.0457V9.39541H11.1528C12.1857 9.39541 12.9073 8.70545 12.9073 7.69866C12.9073 6.67428 12.2104 5.98079 11.1917 5.98079H9.13308ZM10.0457 6.74116H10.9512C11.6091 6.74116 11.9805 7.07558 11.9805 7.69866C11.9805 8.30414 11.5985 8.64208 10.9477 8.64208H10.0457V6.74116Z" fill="black" />
        <path d="M15.0048 10.4374C14.6157 10.4374 14.3363 10.2438 14.3363 9.91993C14.3363 9.60663 14.5662 9.43061 15.0579 9.39893L15.9316 9.34261V9.64887C15.9316 10.0959 15.5354 10.4374 15.0048 10.4374ZM14.7466 11.1238C15.2524 11.1238 15.6769 10.9056 15.8927 10.5324H15.9528V11.0605H16.7982V8.43087C16.7982 7.61418 16.2393 7.13191 15.2454 7.13191C14.3257 7.13191 13.6855 7.56489 13.6147 8.2443H14.4424C14.5238 7.9838 14.7997 7.84299 15.2029 7.84299C15.6769 7.84299 15.9316 8.05421 15.9316 8.43087V8.75473L14.9341 8.81457C13.9932 8.8709 13.4662 9.2722 13.4662 9.96921C13.4662 10.6733 14.0003 11.1238 14.7466 11.1238Z" fill="black" />
        <path d="M18.16 12.458C19.0266 12.458 19.4405 12.1412 19.7695 11.1837L21.1666 7.20935H20.2399L19.3379 10.2156H19.2778L18.3723 7.20935H17.4101L18.7932 11.0781L18.7472 11.2471C18.634 11.6026 18.4253 11.7434 18.0539 11.7434C17.9938 11.7434 17.8629 11.7399 17.8134 11.7293V12.4439C17.87 12.4545 18.107 12.458 18.16 12.458Z" fill="black" />
      </svg>
    </div>
  );
}

export default ApplePay;
