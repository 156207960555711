import React from 'react';
import { IIconProps } from '..';

const AboutUsOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.4438 6.4321C17.9826 5.33944 17.3206 4.35953 16.4801 3.51902C15.6397 2.67851 14.6579 2.01636 13.5674 1.55305C12.4359 1.0754 11.2347 0.833496 9.9987 0.833496C8.76267 0.833496 7.5615 1.0754 6.43001 1.5551C5.33747 2.01636 4.35767 2.67851 3.51726 3.51902C2.67684 4.35953 2.01476 5.33944 1.55356 6.4321C1.07596 7.56166 0.832031 8.76298 0.832031 9.99914C0.832031 11.2353 1.07391 12.4366 1.55356 13.5682C2.01476 14.6609 2.67684 15.6408 3.51726 16.4813C4.35767 17.3218 5.33952 17.984 6.43001 18.4452C7.5615 18.9229 8.76062 19.1668 9.9987 19.1668C11.2347 19.1668 12.4359 18.9249 13.5674 18.4452C14.6599 17.984 15.6397 17.3218 16.4801 16.4813C17.3206 15.6408 17.9826 14.6588 18.4438 13.5682C18.9214 12.4366 19.1654 11.2374 19.1654 9.99914C19.1654 8.76298 18.9214 7.56166 18.4438 6.4321ZM9.9987 17.9061C5.63264 17.9061 2.09265 14.3657 2.09265 9.99914C2.09265 5.63259 5.63264 2.09426 9.9987 2.09426C14.3648 2.09426 17.9047 5.63259 17.9047 9.99914C17.9047 14.3657 14.3648 17.9061 9.9987 17.9061Z" fill="currentColor"/>
        <path d="M10.957 8.5666C10.9325 8.62779 10.8897 8.85418 10.8285 9.24578V11.9992C10.8285 12.6111 10.8469 12.966 10.8836 13.0639C10.9203 13.1496 10.9692 13.2108 11.0304 13.2475C11.0916 13.2719 11.1589 13.2964 11.2323 13.3209C11.3547 13.3454 11.4832 13.3576 11.6178 13.3576C11.6423 13.4066 11.6545 13.4861 11.6545 13.5962C11.6545 13.7064 11.6423 13.7798 11.6178 13.8165C11.0059 13.7553 10.4797 13.7247 10.0392 13.7247C9.53743 13.7247 8.98062 13.7553 8.36874 13.8165C8.34427 13.7798 8.33203 13.7064 8.33203 13.5962C8.33203 13.4861 8.34427 13.4066 8.36874 13.3576C8.62573 13.3576 8.81541 13.3331 8.93779 13.2842C9.06017 13.223 9.13359 13.174 9.15807 13.1373C9.21925 12.9905 9.24985 12.6111 9.24985 11.9992V10.4206C9.24985 10.3227 9.23761 10.1758 9.21314 9.98004C9.18866 9.78424 9.16419 9.66186 9.13971 9.61291C9.05405 9.45382 8.79706 9.37428 8.36874 9.37428C8.34427 9.36204 8.33203 9.30085 8.33203 9.19071C8.34427 9.06834 8.36263 9.00715 8.3871 9.00715C8.85213 8.97044 9.30492 8.88478 9.74547 8.75016C10.186 8.60331 10.5226 8.47482 10.7551 8.36468C10.7918 8.37692 10.8346 8.40751 10.8836 8.45646C10.9325 8.50541 10.957 8.54212 10.957 8.5666ZM9.05011 6.69624C9.05011 6.45149 9.12966 6.24957 9.28875 6.09049C9.46007 5.91916 9.66811 5.8335 9.91286 5.8335C10.1576 5.8335 10.3657 5.91916 10.537 6.09049C10.7083 6.24957 10.794 6.45149 10.794 6.69624C10.794 6.941 10.7083 7.14903 10.537 7.32036C10.3657 7.49169 10.1576 7.57735 9.91286 7.57735C9.66811 7.57735 9.46007 7.49169 9.28875 7.32036C9.12966 7.14903 9.05011 6.941 9.05011 6.69624Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default AboutUsOutlined;
