import React from 'react';
import { IIconProps } from '..';

const EyeOpenOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.06 16.2765C6.55848 16.2765 3.29077 14.3919 1.53599 11.356C1.34613 11.0262 1.45805 10.6045 1.78782 10.4146C2.11759 10.2248 2.53929 10.3367 2.72916 10.6665C4.2401 13.2766 7.04814 14.8975 10.06 14.8975C13.0719 14.8975 15.882 13.2766 17.3909 10.6665C17.5808 10.3367 18.0025 10.2248 18.3322 10.4146C18.662 10.6045 18.7739 11.0262 18.5841 11.356C16.8293 14.3919 13.5616 16.2765 10.06 16.2765ZM17.9885 9.49729C17.7506 9.49729 17.5188 9.37338 17.3909 9.15353C15.88 6.54336 13.0719 4.92249 10.06 4.92249C7.04814 4.92249 4.2401 6.54336 2.72916 9.15353C2.53929 9.4833 2.11759 9.59522 1.78782 9.40535C1.45805 9.21549 1.34613 8.79378 1.53599 8.46401C3.29077 5.42814 6.55848 3.54346 10.06 3.54346C13.5616 3.54346 16.8293 5.42814 18.5841 8.46401C18.7739 8.79378 18.662 9.21549 18.3322 9.40535C18.2243 9.46731 18.1044 9.49729 17.9885 9.49729Z" fill="currentColor" />
        <path d="M10.0599 13.6805C8.00335 13.6805 6.33252 12.0077 6.33252 9.95311C6.33252 7.89855 8.00535 6.22572 10.0599 6.22572C12.1145 6.22572 13.7873 7.89855 13.7873 9.95311C13.7873 12.0077 12.1165 13.6805 10.0599 13.6805ZM10.0599 7.60276C8.76482 7.60276 7.70956 8.65602 7.70956 9.95311C7.70956 11.2502 8.76282 12.3035 10.0599 12.3035C11.357 12.3035 12.4103 11.2482 12.4103 9.95311C12.4103 8.65802 11.355 7.60276 10.0599 7.60276Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default EyeOpenOutlined;
