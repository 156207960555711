import React from 'react';
import { IIconProps } from '..';
import StatusPath from '../common/StatusPath';
import StatusDefs from '../common/StatusDefs';

const StatusWaiting: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <StatusPath  tag="waiting" />
        <circle cx="60.85" cy="74.851" r="3.85" fill="white"/>
        <circle cx="74.9672" cy="74.851" r="3.85" fill="white"/>
        <circle cx="89.0844" cy="74.851" r="3.85" fill="white"/>
        <StatusDefs  tag="waiting" color="#FFC617" />
      </svg>
    </div>
  );
}

export default StatusWaiting;
