import React from 'react';
import { IIconProps } from '..';

const BookingSetting: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.5521 3.88932H4.44097C4.13415 3.88932 3.88542 4.13805 3.88542 4.44488V15.556C3.88542 15.8628 4.13415 16.1115 4.44097 16.1115H15.5521C15.8589 16.1115 16.1076 15.8628 16.1076 15.556V4.44488C16.1076 4.13805 15.8589 3.88932 15.5521 3.88932ZM4.44097 2.22266C3.21367 2.22266 2.21875 3.21758 2.21875 4.44488V15.556C2.21875 16.7833 3.21367 17.7782 4.44097 17.7782H15.5521C16.7794 17.7782 17.7743 16.7833 17.7743 15.556V4.44488C17.7743 3.21758 16.7794 2.22266 15.5521 2.22266H4.44097Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.77708 0.555556C7.77708 0.248731 8.02581 0 8.33264 0H11.666C11.9728 0 12.2215 0.248731 12.2215 0.555556C12.2215 0.86238 11.9728 1.11111 11.666 1.11111H8.33264C8.02581 1.11111 7.77708 0.86238 7.77708 0.555556ZM0.555556 7.77778C0.862381 7.77778 1.11111 8.02651 1.11111 8.33333L1.11111 11.6667C1.11111 11.9735 0.86238 12.2222 0.555556 12.2222C0.248731 12.2222 0 11.9735 0 11.6667L1.32455e-07 8.33333C1.65568e-07 8.02651 0.248731 7.77778 0.555556 7.77778ZM19.4444 7.77778C19.7513 7.77778 20 8.02651 20 8.33333V11.6667C20 11.9735 19.7513 12.2222 19.4444 12.2222C19.1376 12.2222 18.8889 11.9735 18.8889 11.6667V8.33333C18.8889 8.02651 19.1376 7.77778 19.4444 7.77778ZM7.77708 19.4444C7.77708 19.1376 8.02581 18.8889 8.33264 18.8889H11.666C11.9728 18.8889 12.2215 19.1376 12.2215 19.4444C12.2215 19.7513 11.9728 20 11.666 20H8.33264C8.02581 20 7.77708 19.7513 7.77708 19.4444Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default BookingSetting;
