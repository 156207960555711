import React from 'react';
import { IIconProps } from '..';

const BindoSetting: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.85012" y="1.85207" width="16.2963" height="16.2963" rx="1.48148" stroke="currentColor" strokeWidth="1.48148"/>
        <path d="M10.2897 14.4446C12.3281 14.4446 13.5564 13.4959 13.5564 11.9313C13.5564 10.7485 12.68 9.89231 11.3786 9.75679V9.70751C12.3347 9.55967 13.085 8.71575 13.085 7.77327C13.085 6.43039 11.9695 5.55566 10.2698 5.55566H6.44531V14.4446H10.2897ZM7.9326 6.68295H9.91123C10.9869 6.68295 11.5977 7.13879 11.5977 7.96423C11.5977 8.84511 10.8873 9.33791 9.59917 9.33791H7.9326V6.68295ZM7.9326 13.3173V10.3913H9.89795C11.3056 10.3913 12.0359 10.8841 12.0359 11.845C12.0359 12.806 11.3255 13.3173 9.98427 13.3173H7.9326Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default BindoSetting;
