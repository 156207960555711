import React from 'react';
import { getPrimaryColor, IIconProps } from '..';

const Cash: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 18H22.6667C23.403 18 24 17.3284 24 16.5V1.5C24 0.671573 23.403 0 22.6667 0H1.33333C0.596954 0 0 0.671573 0 1.5V16.5C0 17.3284 0.596954 18 1.33333 18ZM21.3328 2.33325C22.0692 2.33325 22.6661 2.93019 22.6661 3.66655V14.333C22.6661 15.0694 22.0692 15.6663 21.3328 15.6663H2.66655C1.93019 15.6663 1.33325 15.0694 1.33325 14.333V3.66655C1.33325 2.93019 1.93019 2.33325 2.66655 2.33325H21.3328ZM21.3328 12.9999C20.8579 13.0028 20.4204 13.258 20.1841 13.67C19.9479 14.0821 19.9487 14.5886 20.1862 14.9999H3.81317C4.10266 14.4794 4.01189 13.8301 3.59077 13.409C3.16965 12.9879 2.52033 12.8971 1.99987 13.1866V4.81343C2.52033 5.10293 3.16965 5.01215 3.59077 4.59104C4.01189 4.16992 4.10266 3.52059 3.81317 3.00014H20.1862C19.8967 3.52059 19.9874 4.16992 20.4086 4.59104C20.8297 5.01215 21.479 5.10293 21.9995 4.81343V13.1866C21.7976 13.0668 21.5676 13.0024 21.3328 12.9999ZM6.6665 9.00025C6.6665 6.05479 9.05427 3.66703 11.9997 3.66703C14.9452 3.66703 17.333 6.05479 17.333 9.00025C17.333 11.9457 14.9452 14.3335 11.9997 14.3335C9.05427 14.3335 6.6665 11.9457 6.6665 9.00025Z" fill={getPrimaryColor()}/>
        <path d="M11.8241 6.15589C11.3601 6.18789 10.9841 6.30789 10.6801 6.52389C10.2721 6.80388 10.0721 7.20387 10.0721 7.73186C10.0721 8.32385 10.3441 8.77184 10.9041 9.07584L11.8241 9.41183V11.2838C11.5761 11.2518 11.3841 11.1718 11.2481 11.0438C11.0641 10.8678 10.9601 10.5638 10.9281 10.1478H10.0001C10.0481 10.8038 10.2401 11.2918 10.5761 11.6118C10.8641 11.8838 11.2801 12.0358 11.8241 12.0758V12.7798H12.3521V12.0758C12.84 12.0438 13.24 11.9238 13.56 11.7158C13.992 11.4278 14.208 11.0038 14.208 10.4358C14.208 9.85182 13.928 9.40383 13.376 9.09184C13.328 9.05984 12.984 8.93184 12.3521 8.69984V6.93988C12.552 6.96388 12.712 7.01988 12.832 7.10787C13.024 7.24387 13.144 7.47587 13.192 7.81186H14.104C14.024 7.22787 13.824 6.80388 13.496 6.54789C13.216 6.32389 12.832 6.18789 12.3521 6.15589V5.49991H11.8241V6.15589ZM12.3521 9.60382C12.3841 9.61182 12.4081 9.61982 12.4401 9.63582C12.992 9.82782 13.272 10.1078 13.272 10.4678C13.272 10.7318 13.16 10.9398 12.936 11.0838C12.776 11.1878 12.576 11.2518 12.3521 11.2838V9.60382ZM11.8241 8.49985C11.2801 8.29185 11.0081 8.03586 11.0081 7.73186C11.0081 7.43587 11.1121 7.21987 11.3281 7.09188C11.4481 7.01188 11.6161 6.95588 11.8241 6.93188V8.49985Z" fill={getPrimaryColor()}/>
      </svg>
    </div>
  );
}

export default Cash;
