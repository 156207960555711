import React from 'react';
import { IIconProps } from '..';

const CheckedSquareOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="currentColor" stroke="currentColor"/>
        <path d="M4.76172 10.0006L8.79102 14.2863L15.2379 5.71484" stroke="white" strokeWidth="1.3" strokeLinecap="round"/>
      </svg>
    </div>
  );
}

export default CheckedSquareOutlined;
