import React from 'react';

interface IDefsProps {
  tag: string;
  color: string;
}

const Defs: React.FC<IDefsProps> = props => {
  const { tag, color } = props;
  return (
    <defs>
      <linearGradient id={`${tag}_paint0_linear`} x1="9.01563" y1="70.3799" x2="18.0481" y2="79.1505" gradientUnits="userSpaceOnUse">
        <stop stopColor={color} stopOpacity="0.5"/>
        <stop offset="1" stopColor={color}/>
      </linearGradient>
      <linearGradient id={`${tag}_paint1_linear`} x1="40.6797" y1="113.126" x2="45.08" y2="115.558" gradientUnits="userSpaceOnUse">
        <stop stopColor={color} stopOpacity="0.5"/>
        <stop offset="1" stopColor={color}/>
      </linearGradient>
      <linearGradient id={`${tag}_paint2_linear`} x1="0" y1="0" x2="3.47869" y2="8.84654" gradientUnits="userSpaceOnUse">
        <stop stopColor={color} stopOpacity="0.5"/>
        <stop offset="1" stopColor={color}/>
      </linearGradient>
      <linearGradient id={`${tag}_paint3_linear`} x1="0" y1="0" x2="4.89365" y2="9.48477" gradientUnits="userSpaceOnUse">
        <stop stopColor={color} stopOpacity="0.5"/>
        <stop offset="1" stopColor={color}/>
      </linearGradient>
      <linearGradient id={`${tag}_paint4_linear`} x1="0" y1="0" x2="3.3615" y2="10.9806" gradientUnits="userSpaceOnUse">
        <stop stopColor={color} stopOpacity="0.5"/>
        <stop offset="1" stopColor={color}/>
      </linearGradient>
      <linearGradient id={`${tag}_paint5_linear`} x1="39" y1="39.001" x2="125.141" y2="83.8278" gradientUnits="userSpaceOnUse">
        <stop stopColor={color} stopOpacity="0.5"/>
        <stop offset="1" stopColor={color}/>
      </linearGradient>
    </defs>
  );
}

export default Defs;
