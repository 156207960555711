import React from 'react';
import { IIconProps } from '..';

const HomeOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 2.5V7.59999H7.60002V2.5H2.5ZM2 1C1.44772 1 1 1.44772 1 2V8.09999C1 8.65227 1.44772 9.09999 2 9.09999H8.10002C8.65231 9.09999 9.10002 8.65227 9.10002 8.09999V2C9.10002 1.44772 8.65231 1 8.10002 1H2Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4004 5.04999C12.4004 6.45831 13.5421 7.59999 14.9504 7.59999C16.3588 7.59999 17.5005 6.45831 17.5005 5.04999C17.5005 3.64168 16.3588 2.5 14.9504 2.5C13.5421 2.5 12.4004 3.64168 12.4004 5.04999ZM14.9504 1C12.7137 1 10.9004 2.81324 10.9004 5.04999C10.9004 7.28675 12.7137 9.09999 14.9504 9.09999C17.1872 9.09999 19.0005 7.28675 19.0005 5.04999C19.0005 2.81324 17.1872 1 14.9504 1Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 12.4002V17.5002H7.60002V12.4002H2.5ZM2 10.9002C1.44772 10.9002 1 11.3479 1 11.9002V18.0002C1 18.5525 1.44772 19.0002 2 19.0002H8.10002C8.65231 19.0002 9.10002 18.5525 9.10002 18.0002V11.9002C9.10002 11.3479 8.65231 10.9002 8.10002 10.9002H2Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4004 12.4002V17.5002H17.5005V12.4002H12.4004ZM11.9004 10.9002C11.3481 10.9002 10.9004 11.3479 10.9004 11.9002V18.0002C10.9004 18.5525 11.3481 19.0002 11.9004 19.0002H18.0005C18.5527 19.0002 19.0005 18.5525 19.0005 18.0002V11.9002C19.0005 11.3479 18.5527 10.9002 18.0005 10.9002H11.9004Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default HomeOutlined;
