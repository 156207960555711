import React from 'react';

interface IStatusPathProps {
  tag: string;
}

const Defs: React.FC<IStatusPathProps> = props => {
  const { tag } = props;
  return (
    <>
      <path d="M7.7669 75.4974L18.3023 72.7029L15.2815 80.7136L7.7669 75.4974Z" fill={`url(#${tag}_paint0_linear)`}/>
      <path d="M105.927 31.4529C105.911 32.0103 106.058 32.5601 106.35 33.0329C106.641 33.5058 107.065 33.8803 107.568 34.1092C108.07 34.3381 108.628 34.4111 109.171 34.319C109.714 34.2268 110.218 33.9736 110.619 33.5914C111.02 33.2093 111.3 32.7153 111.424 32.1719C111.548 31.6285 111.51 31.0602 111.314 30.5388C111.119 30.0174 110.776 29.5663 110.327 29.2426C109.879 28.9189 109.346 28.7371 108.795 28.7202C108.057 28.6975 107.34 28.9727 106.802 29.4852C106.264 29.9977 105.95 30.7055 105.927 31.4529V31.4529Z" fill="#14CAB4"/>
      <path opacity="0.3" d="M40.6234 115.007C40.6122 115.379 40.7103 115.746 40.9051 116.061C41.1 116.377 41.3829 116.627 41.718 116.779C42.0532 116.932 42.4256 116.981 42.7881 116.919C43.1506 116.858 43.4869 116.689 43.7546 116.434C44.0222 116.179 44.2092 115.849 44.2918 115.487C44.3744 115.124 44.3489 114.745 44.2186 114.397C44.0883 114.049 43.859 113.748 43.5597 113.532C43.2604 113.316 42.9046 113.194 42.5372 113.183C42.0446 113.168 41.5662 113.352 41.2072 113.694C40.8483 114.036 40.6383 114.508 40.6234 115.007Z" fill={`url(#${tag}_paint1_linear)`}/>
      <path d="M31.29 35.6515L38.4303 33.1146L35.4968 40.1772L28.3241 42.7131L31.29 35.6515ZM31.1585 40.044L28.5195 32.9417L35.3614 36.039L38.0328 43.1423L31.1585 40.044Z" fill="#FFC11F"/>
      <path d="M118.883 106.124L125.318 103.842L122.679 110.207L116.244 112.489L118.883 106.124ZM118.765 110.087L116.399 103.678L122.574 106.458L124.939 112.866L118.765 110.087Z" fill="#14CAB4"/>
      <rect opacity="0.3" width="17.0851" height="3.49614" rx="1.74807" transform="matrix(0.805873 -0.592088 0.58253 0.812809 19.2969 109.932)" fill={`url(#${tag}_paint2_linear)`}/>
      <rect opacity="0.7" width="15.3098" height="4.11059" rx="2.05529" transform="matrix(0.795554 -0.605883 0.596346 0.802728 118.375 57.1494)" fill={`url(#${tag}_paint3_linear)`}/>
      <rect opacity="0.5" width="25.8028" height="4.11059" rx="2.05529" transform="matrix(0.795554 -0.605883 0.596346 0.802728 118.375 74.7959)" fill={`url(#${tag}_paint4_linear)`}/>
      <path opacity="0.9" d="M75 39.001C67.8799 39.001 60.9197 41.1123 54.9995 45.0681C49.0793 49.0238 44.4651 54.6462 41.7404 61.2244C39.0156 67.8025 38.3027 75.0409 39.6917 82.0242C41.0808 89.0075 44.5095 95.4221 49.5442 100.457C54.5789 105.492 60.9934 108.92 67.9767 110.309C74.9601 111.698 82.1985 110.985 88.7766 108.261C95.3547 105.536 100.977 100.922 104.933 95.0015C108.889 89.0813 111 82.1211 111 75.001C111.001 70.2731 110.071 65.5912 108.262 61.223C106.453 56.8547 103.802 52.8856 100.459 49.5425C97.1154 46.1993 93.1463 43.5476 88.778 41.7389C84.4098 39.9301 79.7279 38.9998 75 39.001Z" fill={`url(#${tag}_paint5_linear)`}/>
    </>
  );
}

export default Defs;
