import React from 'react';
import { IIconProps } from '..';

const BookingOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.33594 5.00065C3.33594 4.08018 4.08213 3.33398 5.0026 3.33398H15.0026C15.9231 3.33398 16.6693 4.08018 16.6693 5.00065V15.0006C16.6693 15.9211 15.9231 16.6673 15.0026 16.6673H5.00261C4.08213 16.6673 3.33594 15.9211 3.33594 15.0007V5.00065Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.0026 5.00065H5.0026V15.0007L15.0026 15.0007L15.0026 5.00065ZM5.0026 3.33398C4.08213 3.33398 3.33594 4.08018 3.33594 5.00065V15.0007C3.33594 15.9211 4.08213 16.6673 5.00261 16.6673H15.0026C15.9231 16.6673 16.6693 15.9211 16.6693 15.0006V5.00065C16.6693 4.08018 15.9231 3.33398 15.0026 3.33398H5.0026Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.8857 0.0617471C12.0556 0.126259 12.2022 0.237969 12.3095 0.380861C12.4291 0.54005 12.5 0.737939 12.5 0.952381V1.47619C12.5 1.58139 12.4147 1.66667 12.3095 1.66667H7.69048C7.58528 1.66667 7.5 1.58139 7.5 1.47619V0.952381C7.5 0.737938 7.57087 0.540048 7.69048 0.380859C7.79783 0.237969 7.94445 0.126259 8.11431 0.0617474C8.21937 0.0218457 8.33333 0 8.45238 0H11.5476C11.6667 0 11.7806 0.0218456 11.8857 0.0617471ZM19.9383 11.8857C19.8737 12.0556 19.762 12.2022 19.6191 12.3095C19.46 12.4291 19.2621 12.5 19.0476 12.5H18.5238C18.4186 12.5 18.3333 12.4147 18.3333 12.3095V7.69048C18.3333 7.58528 18.4186 7.5 18.5238 7.5H19.0476C19.2621 7.5 19.46 7.57088 19.6191 7.69048C19.762 7.79784 19.8737 7.94445 19.9383 8.11431C19.9782 8.21938 20 8.33333 20 8.45239V11.5476C20 11.6667 19.9782 11.7806 19.9383 11.8857ZM11.8857 19.9383C11.7806 19.9782 11.6667 20 11.5476 20H8.45238C8.33333 20 8.21937 19.9782 8.11431 19.9383C7.94445 19.8737 7.79783 19.762 7.69048 19.6191C7.57087 19.46 7.5 19.2621 7.5 19.0476V18.5238C7.5 18.4186 7.58528 18.3333 7.69048 18.3333H12.3095C12.4147 18.3333 12.5 18.4186 12.5 18.5238V19.0476C12.5 19.2621 12.4291 19.46 12.3095 19.6191C12.2022 19.762 12.0556 19.8737 11.8857 19.9383ZM1.66667 12.3095C1.66667 12.4147 1.58139 12.5 1.47619 12.5H0.952381C0.737938 12.5 0.540049 12.4291 0.380859 12.3095C0.237969 12.2022 0.126259 12.0556 0.0617473 11.8857C0.0218457 11.7806 0 11.6667 0 11.5476V8.45239C0 8.33333 0.0218457 8.21938 0.0617473 8.11432C0.126259 7.94445 0.237969 7.79784 0.380859 7.69048C0.540048 7.57088 0.737938 7.5 0.952381 7.5L1.47619 7.5C1.58139 7.5 1.66667 7.58528 1.66667 7.69048L1.66667 12.3095Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default BookingOutlined;
