import React from 'react';
import { IIconProps } from '..';

const UnSelectedSquareOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="currentColor" strokeOpacity="0.4"/>
      </svg>
    </div>
  );
}

export default UnSelectedSquareOutlined;
