import React from 'react';
import { IIconProps } from '..';

const QueueingOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4.52409C0 3.60362 0.746192 2.85742 1.66667 2.85742H18.3333C19.2538 2.85742 20 3.60361 20 4.52409V15.4765C20 16.3969 19.2538 17.1431 18.3333 17.1431H1.66667C0.746192 17.1431 0 16.3969 0 15.4765V4.52409Z" fill="currentColor"/>
        <path d="M10.8055 6.75824C10.4823 6.57176 10.0596 6.68365 9.87314 7.00687L7.8592 10.4878L6.67818 7.20578C6.66575 7.16848 6.65331 7.14362 6.62845 7.10632C6.57872 6.96958 6.47927 6.83283 6.33009 6.75824C6.00686 6.57176 5.58418 6.68365 5.39771 7.00687L2.62542 11.8055C2.43895 12.1288 2.55083 12.5514 2.87406 12.7379C2.98594 12.8001 3.09783 12.8249 3.20972 12.8249C3.44592 12.8249 3.66969 12.7006 3.80644 12.4768L5.84525 8.94622L7.0014 12.166C7.01384 12.3898 7.13815 12.6012 7.33706 12.7255C7.44895 12.7876 7.56083 12.8125 7.67272 12.8125C7.90892 12.8125 8.1327 12.6882 8.26944 12.4644L11.0542 7.69062C11.2406 7.36739 11.1287 6.94471 10.8055 6.75824ZM14.3983 6.94471C12.6454 6.94471 11.2033 8.37436 11.2033 10.1397C11.2033 11.905 12.633 13.3346 14.3983 13.3346C16.1636 13.3346 17.5933 11.905 17.5933 10.1397C17.5933 8.37436 16.1512 6.94471 14.3983 6.94471ZM14.3983 11.9547C13.3913 11.9547 12.5708 11.1342 12.5708 10.1272C12.5708 9.12027 13.3913 8.29977 14.3983 8.29977C15.4053 8.29977 16.2258 9.12027 16.2258 10.1272C16.2258 11.1342 15.4053 11.9547 14.3983 11.9547Z" fill="white"/>
      </svg>
    </div>
  );
}

export default QueueingOutlined;
