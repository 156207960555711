import React from 'react';
import { IIconProps } from '..';

const PrivilegeOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.55093 7.04296L5.00866 2.26697C5.24374 1.94226 5.62029 1.75 6.02117 1.75H11.9788C12.3797 1.75 12.7563 1.94226 12.9913 2.26697L16.4491 7.04296C16.7934 7.51854 16.7603 8.16965 16.3696 8.60788L9.1866 16.6638C9.08718 16.7753 8.91282 16.7753 8.8134 16.6638L1.63044 8.60788C1.2397 8.16965 1.20662 7.51854 1.55093 7.04296Z" stroke="currentColor" strokeWidth="1.5"></path>
        <path d="M7 7L11 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
      </svg>
    </div>
  );
}

export default PrivilegeOutlined;
