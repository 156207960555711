import React from 'react';
import { IIconProps } from '..';

const GuestOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1427 7.04453C14.1427 9.09299 12.4679 10.7539 10.4013 10.7539C8.33474 10.7539 6.66055 9.09299 6.66055 7.04453C6.66055 4.99487 8.33535 3.33398 10.4019 3.33398C12.4685 3.33398 14.1427 4.99487 14.1427 7.04453ZM10.4013 10.7539C13.3278 10.7539 15.7825 12.2596 16.4547 15.133C16.6643 16.0293 15.8906 16.7868 14.9701 16.7868H5.83316C4.91269 16.7868 4.13895 16.0293 4.34857 15.133C5.02059 12.2596 7.47485 10.7539 10.4013 10.7539Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default GuestOutlined;
