import React from 'react';
import { getPrimaryColor, IIconProps } from '..';
import TagPath from '../common/TagPath';
import TagDefs from '../common/TagDefs';

const TagAddress: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <TagPath />
        <path opacity="0.9" d="M47 68.1506V67.9655C47 51.9712 59.5328 39.001 74.9927 39.001C90.4526 39.001 102.98 51.9661 102.98 67.9655H102.996C103.148 73.5198 99.4169 81.6815 94.3213 89.2569C88.7829 97.4957 81.7478 105.261 77.5329 109.828C76.0668 111.423 74.2291 111.361 72.87 109.828C67.9678 104.315 62.4446 98.5037 56.3258 89.5809C51.4084 82.4118 46.8778 73.7255 47.0051 68.1506H47ZM74.9978 80.1438C78.373 80.1438 81.61 78.7892 83.9966 76.378C86.3832 73.9668 87.724 70.6966 87.724 67.2866C87.724 63.8767 86.3832 60.6065 83.9966 58.1953C81.61 55.7841 78.373 54.4295 74.9978 54.4295C71.6226 54.4295 68.3856 55.7841 65.999 58.1953C63.6123 60.6065 62.2715 63.8767 62.2715 67.2866C62.2715 70.6966 63.6123 73.9668 65.999 76.378C68.3856 78.7892 71.6226 80.1438 74.9978 80.1438Z" fill="url(#paint8_linear)"/>
        <TagDefs color={getPrimaryColor()} />
      </svg>
    </div>
  );
}

export default TagAddress;
