import React from 'react';
import { IIconProps } from '..';

const Setting2Outlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.64837 0.902776C9.48801 0.428242 10.5149 0.428241 11.3545 0.902776L14.3631 2.60313L14.3657 2.60462L17.3423 4.27103C18.2128 4.75834 18.7511 5.67882 18.749 6.67638L18.742 9.99798V10.0011L18.749 13.3227C18.7511 14.3203 18.2128 15.2408 17.3423 15.7281L14.3657 17.3945L14.3631 17.396L11.3545 19.0963C10.5149 19.5709 9.48801 19.5709 8.64837 19.0963L5.6398 17.396L5.63716 17.3945L2.66054 15.7281C1.7901 15.2408 1.25181 14.3203 1.25391 13.3227L1.26091 10.0011V9.99798L1.25391 6.67638C1.25181 5.67881 1.7901 4.75834 2.66054 4.27103L5.6398 2.60313L8.64837 0.902776ZM10.6165 2.20865C10.2348 1.99295 9.76806 1.99295 9.38641 2.20865L6.37255 3.91198L3.39329 5.57988C2.99763 5.80139 2.75295 6.21978 2.75391 6.67322L2.76091 9.99798V10.0011L2.75391 13.3259C2.75295 13.7793 2.99763 14.1977 3.39329 14.4192L6.37519 16.0886L9.38641 17.7905C9.76806 18.0062 10.2348 18.0062 10.6165 17.7905L13.6277 16.0886L13.6303 16.0871L16.6096 14.4192C17.0052 14.1977 17.2499 13.7793 17.249 13.3259L17.242 10.0011V9.99798L17.249 6.67322C17.2499 6.21978 17.0052 5.80139 16.6096 5.57988L13.6303 3.91198L13.6277 3.91049L10.6165 2.20865Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0014 11.5996C10.8851 11.5996 11.6014 10.8832 11.6014 9.99956C11.6014 9.1159 10.8851 8.39956 10.0014 8.39956C9.11778 8.39956 8.40144 9.1159 8.40144 9.99956C8.40144 10.8832 9.11778 11.5996 10.0014 11.5996ZM10.0014 12.9996C11.6583 12.9996 13.0014 11.6564 13.0014 9.99956C13.0014 8.34271 11.6583 6.99956 10.0014 6.99956C8.34458 6.99956 7.00144 8.34271 7.00144 9.99956C7.00144 11.6564 8.34458 12.9996 10.0014 12.9996Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default Setting2Outlined;
