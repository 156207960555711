import React from 'react';
import { IIconProps } from '..';
import Config from '@/Config';

const MasterCard: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <img src={`${Config.cdnUrl}/images/mastercard.png`} style={{ height: '19PX' }} alt="" />
    </div>
  );
}

export default MasterCard;
