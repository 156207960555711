import React from 'react';
import { getPrimaryColor, IIconProps } from '..';

const SuccessOutlined: React.FC<IIconProps> = props => {
  const { className = '', showTagColor = false, onClick } = props;
  const tagColor = showTagColor ? getPrimaryColor() : 'currentColor';
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.096 18.7247H3.9075C3.16722 18.7247 2.56523 18.1227 2.56523 17.3824V8.9918C2.56523 8.62117 2.86573 8.32068 3.23635 8.32068C3.60698 8.32068 3.90748 8.62117 3.90748 8.9918V17.3824H16.096V8.9918C16.096 8.62117 16.3965 8.32068 16.7671 8.32068C17.1377 8.32068 17.4382 8.62117 17.4382 8.9918V17.3824C17.4383 18.1227 16.8363 18.7247 16.096 18.7247Z" fill="currentColor"/>
        <path d="M5.14684 10.2793C4.46588 10.2793 3.85406 10.1368 3.32645 9.85543C3.31685 9.85027 3.30735 9.84492 3.29795 9.83938C2.05236 9.16252 1.27898 7.86466 1.27734 6.44489C1.27734 6.17569 1.38615 5.87501 2.45904 3.53146C2.71433 2.97338 2.95649 2.4448 3.1115 2.08482C3.32288 1.59343 3.80655 1.27539 4.34332 1.27539H15.6975C16.2376 1.27539 16.7226 1.59621 16.9329 2.09284C17.0853 2.45297 17.3219 2.97928 17.5716 3.53456C18.6206 5.86796 18.7268 6.16747 18.7255 6.43487C18.7258 7.84743 17.9547 9.14873 16.7127 9.82723C16.1809 10.1187 15.5596 10.2685 14.8671 10.2718C13.9627 10.2718 13.1031 9.9624 12.4208 9.4058C11.7471 9.9565 10.9003 10.2665 10.0129 10.2736C9.11303 10.268 8.26035 9.95878 7.58299 9.4081C6.90201 9.96538 6.04507 10.2767 5.14684 10.2793ZM3.97465 8.67947C4.30235 8.85038 4.69593 8.93704 5.14488 8.93704C5.88876 8.9349 6.59036 8.60736 7.06816 8.03849C7.13104 7.96354 7.20956 7.90326 7.29821 7.86188C7.38686 7.82049 7.4835 7.79901 7.58133 7.79893C7.6791 7.79892 7.77634 7.82028 7.86499 7.86152C7.95363 7.90276 8.03219 7.96288 8.09516 8.03767C8.56968 8.60098 9.26832 8.92672 10.0119 8.9313C10.74 8.9254 11.4338 8.599 11.9063 8.03587C12.1609 7.73193 12.6787 7.73159 12.9346 8.03555C13.4114 8.60378 14.1147 8.92952 14.8638 8.92952C15.3275 8.92722 15.7339 8.83319 16.0684 8.64983C16.4576 8.43733 16.7839 8.12609 17.0146 7.74742C17.2453 7.36875 17.3722 6.93601 17.3825 6.49273C17.2832 6.16715 16.7232 4.92092 16.3473 4.08495C16.0934 3.51999 15.8528 2.98437 15.6975 2.61766H4.3433C4.18601 2.98305 3.93958 3.52163 3.67938 4.09018C3.29564 4.92877 2.7238 6.17813 2.62057 6.50762C2.64418 7.41569 3.15177 8.24017 3.95661 8.66946C3.96252 8.67276 3.96876 8.67603 3.97465 8.67947Z" fill="currentColor"/>
        <path d="M10.8419 16.3764H9.16406V13.0207C9.16406 12.6501 9.46454 12.3496 9.83519 12.3496H10.1708C10.5414 12.3496 10.8419 12.6501 10.8419 13.0207V16.3764Z" fill={tagColor}/>
      </svg>
    </div>
  );
}

export default SuccessOutlined;
