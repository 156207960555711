import React from 'react';
import { IIconProps } from '..';

const AlipayHK: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.25" y="0.25" width="17.5" height="17.5" rx="1.75" fill="white" stroke="#BDBDBD" strokeWidth="0.5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.80544 9.80824H6.14347L5.93674 9.37737H4.66502L4.44175 9.80824H4L5.24604 7.25784C5.25866 7.23172 5.27825 7.20953 5.30393 7.19342C5.33047 7.17776 5.35963 7.16992 5.38792 7.16992H5.56898L6.80544 9.80824ZM5.85405 9.16759L5.31263 8.02774L4.74902 9.16759H5.85405Z" fill="#333333"/>
        <path d="M7.05565 9.80998H7.65452V7.17166H7.17142C7.14182 7.17166 7.11223 7.18297 7.0896 7.20517C7.06697 7.22693 7.05565 7.25653 7.05565 7.28525V9.80998Z" fill="#333333"/>
        <path d="M8.18068 7.97711C8.18068 7.94839 8.192 7.91923 8.2142 7.89746C8.23683 7.87527 8.26686 7.86439 8.29602 7.86439H8.78347V9.81027H8.18068V7.97711Z" fill="#333333"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.32621 7.89757C9.30315 7.91977 9.29183 7.94936 9.29183 7.97809L9.29227 10.5764H9.89853V9.79645C9.95337 9.81821 10.0095 9.8317 10.0674 9.83823C10.1253 9.84389 10.194 9.84693 10.2733 9.84693C10.4299 9.84693 10.5714 9.81908 10.6976 9.76381C10.8242 9.70766 10.9313 9.63063 11.0205 9.53183C11.1098 9.43347 11.1781 9.3164 11.2255 9.18191C11.273 9.047 11.2965 8.90033 11.2965 8.74016C11.2965 8.61569 11.2769 8.49818 11.2364 8.38763C11.1964 8.27665 11.1389 8.1796 11.0649 8.09647C10.9909 8.01291 10.9017 7.94675 10.7981 7.89757C10.6941 7.84839 10.5783 7.82446 10.4513 7.82446C10.3311 7.82446 10.2263 7.8423 10.1366 7.87886C10.0474 7.91498 9.96773 7.96068 9.89809 8.01639V7.86406H9.40847C9.37887 7.86406 9.34884 7.87581 9.32621 7.89757ZM9.92508 9.40083C9.90723 9.35905 9.89809 9.31509 9.89809 9.26765V8.41027C9.92247 8.37806 9.94989 8.34672 9.97948 8.31669C10.0095 8.2871 10.0404 8.26185 10.0735 8.23966C10.1061 8.21746 10.1405 8.20049 10.1762 8.18787C10.2123 8.17525 10.2493 8.16915 10.2876 8.16915C10.3407 8.16915 10.3895 8.18482 10.4343 8.21703C10.4787 8.24923 10.517 8.29667 10.5492 8.36109C10.5814 8.42506 10.6066 8.50471 10.6245 8.59959C10.6423 8.69403 10.651 8.80458 10.651 8.93036C10.651 9.02785 10.6406 9.11881 10.6206 9.20237C10.6005 9.28637 10.5731 9.35774 10.5392 9.41737C10.5052 9.47743 10.4656 9.524 10.4199 9.55751C10.3738 9.59146 10.325 9.60887 10.2733 9.60887C10.2171 9.60887 10.1666 9.59973 10.1201 9.58232C10.0739 9.56491 10.0339 9.54054 10.0012 9.50964C9.9686 9.47874 9.94336 9.44261 9.92508 9.40083Z" fill="#333333"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.8564 7.88535C12.5669 7.80439 12.2009 7.78916 11.7009 7.91668C11.7103 7.94457 11.7215 7.98499 11.7322 8.02392C11.7403 8.05305 11.7481 8.08136 11.7548 8.10296C12.5461 7.96499 12.6988 8.07206 12.6988 8.40674V8.5112C12.6441 8.50741 12.5858 8.50163 12.5268 8.49578C12.43 8.48619 12.3314 8.47642 12.2449 8.47507C11.7039 8.4668 11.5072 8.88853 11.5072 9.15489C11.5072 9.62058 11.9598 10.0528 12.6988 9.73373V9.80816H13.3168V8.38759C13.3168 8.1121 13.0735 7.94541 12.8564 7.88535ZM11.9946 9.16446C11.9946 8.93858 12.1826 8.7658 12.4129 8.74926C12.5587 8.73882 12.6466 8.76449 12.6984 8.79627V9.46303C12.624 9.53179 12.5234 9.57401 12.4129 9.57401C12.1818 9.57401 11.9946 9.39121 11.9946 9.16446Z" fill="#333333"/>
        <path d="M14.1227 7.82793H13.4829L14.4552 9.67109L14.3847 9.8269C14.3756 9.84606 14.3657 9.86653 14.3549 9.88881C14.3493 9.90045 14.3434 9.91257 14.3373 9.92526C14.3199 9.96226 14.292 10.0167 14.2659 10.0454C14.1505 10.1723 13.89 10.1721 13.8035 10.172L13.798 10.172V10.4993C13.8071 10.4993 13.8196 10.4999 13.835 10.5006C14.0019 10.5086 14.5117 10.5327 14.7329 9.99316L15.7365 7.82793H15.3949C15.3696 7.82793 15.3444 7.83446 15.3217 7.84838C15.2991 7.86362 15.2821 7.8832 15.2721 7.9054L14.7533 9.02871L14.1227 7.82793Z" fill="#333333"/>
        <path d="M8.48138 7.57537C8.66158 7.57537 8.80695 7.44611 8.80695 7.28725C8.80695 7.12883 8.66158 7 8.48138 7C8.30162 7 8.15625 7.12883 8.15625 7.28725C8.15625 7.44611 8.30162 7.57537 8.48138 7.57537Z" fill="#00A0E9"/>
        <path d="M19.5564 9.83015H19.9999L19.2709 8.62807L19.9507 7.8351H19.4828C19.4711 7.8351 19.4598 7.84032 19.4519 7.84946L18.7769 8.66071C18.773 8.6655 18.7673 8.66811 18.7612 8.66811C18.7503 8.66811 18.7412 8.65941 18.7412 8.64809V7.8351H18.3813C18.3591 7.8351 18.3408 7.85337 18.3408 7.87557V9.78968C18.3408 9.81187 18.3591 9.83015 18.3813 9.83015H18.7412V9.23042L19.0063 8.92228L19.5216 9.81013C19.5285 9.82232 19.542 9.83015 19.5564 9.83015Z" fill="#00A0E9"/>
        <path d="M17.4934 9.83015H17.8586V7.8351H17.4934C17.4712 7.8351 17.4529 7.85337 17.4529 7.87557V8.61023C17.4529 8.63242 17.4347 8.6507 17.4125 8.6507H16.7496V7.8351H16.3845C16.3623 7.8351 16.344 7.85337 16.344 7.87557V9.78968C16.344 9.81187 16.3623 9.83015 16.3845 9.83015H16.7496V8.99496H17.4125C17.4347 8.99496 17.4529 9.01324 17.4529 9.03544V9.78968C17.4529 9.81187 17.4712 9.83015 17.4934 9.83015Z" fill="#00A0E9"/>
      </svg>
    </div>
  );
}

export default AlipayHK;

