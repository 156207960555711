import React from 'react';
import { IIconProps } from '..';

const CameraOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0011 8.54137C8.49538 8.54137 7.27379 9.76296 7.27379 11.2686C7.27379 12.7743 8.49538 13.9959 10.0011 13.9959C11.5067 13.9959 12.7283 12.7743 12.7283 11.2686C12.7283 9.76296 11.5067 8.54137 10.0011 8.54137ZM16.6677 4.60198H14.5465L14.0636 3.3141C13.8268 2.6891 13.0882 2.17773 12.4253 2.17773H7.57682C6.91394 2.17773 6.17531 2.6891 5.93856 3.3141L5.45561 4.60198H3.3344C1.99917 4.60198 0.910156 5.69099 0.910156 7.02622V15.5111C0.910156 16.8463 1.99917 17.9353 3.3344 17.9353H16.6677C18.003 17.9353 19.092 16.8463 19.092 15.5111V7.02622C19.092 5.69099 18.003 4.60198 16.6677 4.60198ZM10.0011 15.5111C7.66205 15.5111 5.75864 13.6077 5.75864 11.2686C5.75864 8.92963 7.66205 7.02622 10.0011 7.02622C12.3401 7.02622 14.2435 8.92963 14.2435 11.2686C14.2435 13.6077 12.3401 15.5111 10.0011 15.5111Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default CameraOutlined;
