import React from 'react';
import { IIconProps } from '..';

const PaymentFailed: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M59.1365 29.9996C59.1365 45.8151 46.3156 58.636 30.5001 58.636C14.6847 58.636 1.86377 45.8151 1.86377 29.9996C1.86377 24.4998 3.41424 19.362 6.10194 14.9996C11.143 6.81762 20.1846 1.36328 30.5001 1.36328C46.3156 1.36328 59.1365 14.1842 59.1365 29.9996Z" fill="#FC2E01" />
        <path d="M30.5001 59.726C29.9547 59.726 29.4092 59.3169 29.4092 58.6351C29.4092 57.9533 29.8183 57.5442 30.5001 57.5442C45.7728 57.5442 58.0456 45.1351 58.0456 29.9988C58.0456 14.8624 45.7728 2.4533 30.5001 2.4533C20.8183 2.4533 12.091 7.36239 6.9092 15.5442C6.63647 16.0897 5.95466 16.226 5.54556 15.8169C5.00011 15.5442 5.00826 14.8814 5.27284 14.4533C10.7274 5.58967 20.1365 0.271484 30.5001 0.271484C46.8637 0.271484 60.2274 13.6351 60.2274 29.9988C60.2274 46.3624 46.8637 59.726 30.5001 59.726Z" fill="black" />
        <path d="M40.1819 40.6351C39.9092 40.6351 39.6365 40.4988 39.5001 40.3624L20.1365 21.1351C19.7274 20.726 19.7274 20.0442 20.1365 19.6351C20.5456 19.226 21.2274 19.226 21.6365 19.6351L40.8637 38.8624C41.2728 39.2715 41.2728 39.9533 40.8637 40.3624C40.7274 40.4988 40.4547 40.6351 40.1819 40.6351Z" fill="black" />
        <path d="M20.8183 40.6351C20.5456 40.6351 20.2728 40.4988 20.1365 40.3624C19.7274 39.9533 19.7274 39.2715 20.1365 38.8624L39.3637 19.6351C39.7728 19.226 40.4547 19.226 40.8637 19.6351C41.2728 20.0442 41.2728 20.726 40.8637 21.1351L21.6365 40.3624C21.3637 40.4988 21.091 40.6351 20.8183 40.6351Z" fill="black" />
      </svg>
    </div>
  );
}

export default PaymentFailed;
