import React from 'react';
import { IIconProps } from '..';

const StaffIDOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.09065 6.73118C8.4586 6.73118 7.89795 6.42335 7.55032 5.95048C7.20152 6.42335 6.64087 6.73118 6.00999 6.73118C5.37911 6.73118 4.81846 6.42335 4.46966 5.95048C4.39592 6.05114 4.31282 6.14361 4.22035 6.22905C3.82708 6.58955 3.29452 6.7698 2.75844 6.72415C1.78111 6.63871 1.01562 5.77959 1.01562 4.76831C1.01562 4.51901 1.07415 4.26853 1.18651 4.04497L2.04212 2.33258C2.35112 1.71575 2.9703 1.33301 3.6597 1.33301H8.35325C9.04266 1.33301 9.663 1.71575 9.97083 2.33258L10.8288 4.04965C10.9388 4.26853 10.9973 4.51432 10.9997 4.75895V4.81513C11.002 5.87323 10.1441 6.73118 9.09065 6.73118Z" fill="#BDBDBD"/>
        <path d="M8.98465 7.64225C8.37151 7.64225 7.82763 7.34735 7.4904 6.89436C7.15203 7.34735 6.60815 7.64225 5.99614 7.64225C5.38413 7.64225 4.84024 7.34735 4.50188 6.89436C4.43034 6.99079 4.34973 7.07937 4.26003 7.16122C3.87851 7.50657 3.36188 7.67925 2.84184 7.63552C2.44557 7.60188 2.08563 7.43593 1.80063 7.18589V9.11223C1.80063 10.0373 2.56252 10.7897 3.49927 10.7897H8.49187C9.42862 10.7897 10.1905 10.0373 10.1905 9.11223V7.19934C9.86577 7.47518 9.44451 7.64225 8.98465 7.64225Z" fill="#BDBDBD"/>
      </svg>
    </div>
  );
}

export default StaffIDOutlined;
