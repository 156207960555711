import React from 'react';
import { IIconProps } from '..';

const MapOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <g filter="url(#filter0_d)">
            <path d="M14 4.91304C14 6.94209 12.4213 8.61044 10.4 8.80677L10.4 18.6087C10.4 18.8248 10.2209 19 10 19C9.77909 19 9.6 18.8248 9.6 18.6087L9.6 8.80677C7.57867 8.61044 6 6.94209 6 4.91304C6 2.75193 7.79086 1 10 1C12.2091 1 14 2.75193 14 4.91304Z" fill="#FF5D5D"/>
          </g>
        </g>
        <defs>
          <filter id="filter0_d" x="4" y="-1" width="12" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </div>
  );
}

export default MapOutlined;
