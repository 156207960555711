import React from 'react';
import { IIconProps } from '..';

const ShoppingCart: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L3.5 1L4 3L6 11H5.5C4.67157 11 4 11.6716 4 12.5V12.5C4 13.3284 4.67157 14 5.5 14H17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 3H19L17 11H6L4 3Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
        <circle cx="16" cy="18" r="1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></circle>
        <circle cx="6" cy="18" r="1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></circle>
      </svg>
    </div>
  );
}

export default ShoppingCart;
