import React from 'react';
import { IIconProps } from '..';

const SelectedOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.4563 6.46931C14.7499 6.72091 14.7839 7.16282 14.5323 7.45635L9.38941 13.4563C9.26779 13.5982 9.09447 13.6856 8.90806 13.699C8.72165 13.7124 8.53763 13.6507 8.39698 13.5276L5.53984 11.0276C5.24889 10.773 5.21941 10.3308 5.47399 10.0398C5.72856 9.7489 6.1708 9.71941 6.46174 9.97399L8.78668 12.0083L13.4693 6.54524C13.7209 6.25171 14.1628 6.21772 14.4563 6.46931Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default SelectedOutlined;
