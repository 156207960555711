import React from 'react';
import { IIconProps } from '..';

const WechatOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8952 6.7256C12.0386 6.82259 10.4242 7.38538 9.11355 8.65689C7.78929 9.9415 7.18479 11.5156 7.35002 13.467C6.62437 13.3772 5.96343 13.2782 5.29873 13.2223C5.06917 13.203 4.79674 13.2304 4.60229 13.3401C3.95684 13.7043 3.33807 14.1155 2.60468 14.574C2.73924 13.9653 2.82634 13.4324 2.98054 12.9197C3.09394 12.5429 3.04142 12.3332 2.69431 12.0879C0.465671 10.5144 -0.473752 8.15965 0.229282 5.73534C0.879717 3.49259 2.47701 2.13245 4.64738 1.42344C7.60972 0.455797 10.9389 1.44284 12.7402 3.79477C13.3908 4.64433 13.7897 5.59786 13.8952 6.7256ZM5.35072 5.97019C5.36782 5.52678 4.9836 5.1273 4.52723 5.11396C4.05997 5.10024 3.67567 5.45731 3.66203 5.91775C3.64822 6.38439 4.00515 6.75834 4.47578 6.7703C4.94235 6.78211 5.33347 6.42457 5.35072 5.97019ZM9.80869 5.11366C9.35063 5.12209 8.96357 5.51221 8.9717 5.95739C8.98006 6.41882 9.35975 6.77981 9.83077 6.77413C10.303 6.76846 10.6612 6.40364 10.6567 5.93247C10.6528 5.46996 10.2748 5.10514 9.80869 5.11366Z" fill="white"/>
        <path d="M18.0662 18.2993C17.4783 18.0376 16.9391 17.6448 16.3649 17.5849C15.793 17.5251 15.1918 17.8551 14.5934 17.9163C12.7708 18.1027 11.1379 17.5948 9.79151 16.3496C7.23079 13.9811 7.59668 10.3495 10.5593 8.40855C13.1924 6.68352 17.054 7.25858 18.9105 9.65215C20.5305 11.7408 20.3401 14.5133 18.3624 16.268C17.7901 16.7758 17.5841 17.1937 17.9513 17.8631C18.0191 17.9867 18.0269 18.1432 18.0662 18.2993ZM11.3745 11.8204C11.7487 11.8207 12.0569 11.5278 12.0711 11.1582C12.086 10.7668 11.7713 10.4386 11.3794 10.437C10.9914 10.4352 10.6664 10.7681 10.6799 11.1536C10.6926 11.5219 11.0029 11.8199 11.3745 11.8204ZM15.6883 10.4385C15.3251 10.436 15.0166 10.7333 15.0018 11.1002C14.9861 11.4925 15.291 11.8147 15.6793 11.8157C16.0549 11.8169 16.3516 11.5329 16.3652 11.1592C16.3798 10.7659 16.0747 10.4412 15.6883 10.4385Z" fill="white"/>
      </svg>
    </div>
  );
}

export default WechatOutlined;
