import React from 'react';
import { IIconProps } from '..';

const BookingSettingBrand: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path d="M20.0001 11.8441C20.0001 14.8366 17.5742 17.2624 14.5818 17.2624C11.5893 17.2624 9.16345 14.8366 9.16345 11.8441C9.16345 8.85165 11.5893 6.42578 14.5818 6.42578C17.5742 6.42578 20.0001 8.85165 20.0001 11.8441Z" fill="#523838" fillOpacity="0.15"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M3.06343 6.45468C3.06343 6.41067 3.02776 6.375 2.98375 6.375H1.7609C1.73276 6.375 1.7067 6.38985 1.69235 6.41405L0.818582 7.8883C0.803984 7.91293 0.803721 7.94349 0.817895 7.96837C0.832068 7.99325 0.858498 8.00861 0.887128 8.00861H2.98375C3.02776 8.00861 3.06343 7.97293 3.06343 7.92892V6.45468ZM2.90407 6.53436V7.84924H1.02698L1.8063 6.53436H2.90407Z" fill="currentColor"/>
          <path d="M2.98375 8.49219C3.02776 8.49219 3.06343 8.52786 3.06343 8.57187C3.06343 8.61588 3.02776 8.65155 2.98375 8.65155H2.52973C2.48573 8.65155 2.45005 8.61588 2.45005 8.57187C2.45005 8.52786 2.48573 8.49219 2.52973 8.49219H2.98375Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M8.88186 3C10.2969 3 11.4437 4.14934 11.4437 5.5668C11.4437 6.57325 10.8655 7.44454 10.0236 7.86519V10.8107C10.0236 10.8547 9.98796 10.8904 9.94396 10.8904H8.86325C8.81882 11.3848 8.4041 11.7725 7.89874 11.7725C7.39339 11.7725 6.97866 11.3848 6.93424 10.8904H3.78687C3.78047 10.8904 3.77425 10.8896 3.7683 10.8882H2.90128C2.85788 11.3837 2.44268 11.7725 1.93659 11.7725C1.43049 11.7725 1.01529 11.3837 0.971888 10.8882H0.0798035C0.0357962 10.8882 0.00012207 10.8525 0.00012207 10.8085V7.79594C0.00012207 7.72373 0.0197468 7.65288 0.0568981 7.59096L1.19074 5.70124C1.26274 5.58124 1.39242 5.50781 1.53237 5.50781H3.70718V4.28281C3.70718 4.2388 3.74286 4.20312 3.78687 4.20312H6.53239C6.5764 4.20312 6.61207 4.2388 6.61207 4.28281C6.61207 4.32681 6.5764 4.36249 6.53239 4.36249H3.86655V5.56889C3.86797 5.57486 3.86873 5.58109 3.86873 5.58749V9.21875H9.86427V7.93808C9.56175 8.06405 9.22992 8.13359 8.88186 8.13359C7.46687 8.13359 6.32007 6.98425 6.32007 5.5668C6.32007 4.14934 7.46687 3 8.88186 3ZM7.89874 9.83203C7.38767 9.83203 6.96929 10.2286 6.93287 10.731H3.86873V9.37811H9.86427V10.731H8.86461C8.8282 10.2286 8.40981 9.83203 7.89874 9.83203ZM1.93659 9.83203C1.66017 9.83203 1.41087 9.94802 1.23445 10.134C1.0844 10.2913 0.98655 10.4989 0.96887 10.7288H0.159485V7.79594C0.159485 7.75261 0.17126 7.7101 0.193551 7.67295L1.32739 5.78323C1.37059 5.71123 1.4484 5.66718 1.53237 5.66718H3.70718V10.7288H2.9023C2.86483 10.2274 2.44692 9.83203 1.93659 9.83203Z" fill="currentColor"/>
          <path d="M10.502 10.443C10.502 10.399 10.5376 10.3633 10.5816 10.3633H13.6351C13.6791 10.3633 13.7148 10.399 13.7148 10.443C13.7148 10.487 13.6791 10.5226 13.6351 10.5226H10.5816C10.5376 10.5226 10.502 10.487 10.502 10.443Z" fill="currentColor"/>
          <path d="M10.502 8.56406C10.502 8.52005 10.5376 8.48438 10.5816 8.48438H13.6351C13.6791 8.48438 13.7148 8.52005 13.7148 8.56406C13.7148 8.60806 13.6791 8.64374 13.6351 8.64374H10.5816C10.5376 8.64374 10.502 8.60806 10.502 8.56406Z" fill="currentColor"/>
          <path d="M10.502 9.50546C10.502 9.46146 10.5376 9.42578 10.5816 9.42578H14.9672C15.0113 9.42578 15.0469 9.46146 15.0469 9.50546C15.0469 9.54947 15.0113 9.58514 14.9672 9.58514H10.5816C10.5376 9.58514 10.502 9.54947 10.502 9.50546Z" fill="currentColor"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M8.9202 4.18359C8.87619 4.18359 8.84052 4.21927 8.84052 4.26328V5.19365C8.65372 5.21418 8.5086 5.37277 8.5086 5.56508C8.5086 5.62476 8.52258 5.68119 8.54744 5.73125L7.95877 6.16148C7.92324 6.18744 7.91549 6.2373 7.94145 6.27283C7.96742 6.30835 8.01727 6.31611 8.0528 6.29014L8.64719 5.85574C8.71124 5.90764 8.79281 5.93875 8.88168 5.93875C8.92305 5.93875 8.96283 5.93201 9 5.91957C9.14812 5.87 9.25476 5.72993 9.25476 5.56508C9.25476 5.40023 9.14812 5.26015 9 5.21058L8.99988 4.26328C8.99988 4.21927 8.9642 4.18359 8.9202 4.18359ZM9 5.38659C8.96611 5.36395 8.92541 5.35077 8.88168 5.35077C8.7638 5.35077 8.66796 5.44657 8.66796 5.56508C8.66796 5.68359 8.7638 5.77939 8.88168 5.77939C8.92541 5.77939 8.96611 5.7662 9 5.74357C9.05748 5.70519 9.0954 5.63963 9.0954 5.56508C9.0954 5.49053 9.05748 5.42497 9 5.38659Z" fill="white"/>
          <path d="M8.30779 10.8371C8.30779 11.0535 8.13237 11.2289 7.91598 11.2289C7.69959 11.2289 7.52417 11.0535 7.52417 10.8371C7.52417 10.6207 7.69959 10.4453 7.91598 10.4453C8.13237 10.4453 8.30779 10.6207 8.30779 10.8371Z" fill="white"/>
          <path d="M1.96053 11.2289C2.17692 11.2289 2.35234 11.0535 2.35234 10.8371C2.35234 10.6207 2.17692 10.4453 1.96053 10.4453C1.74414 10.4453 1.56873 10.6207 1.56873 10.8371C1.56873 11.0535 1.74414 11.2289 1.96053 11.2289Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default BookingSettingBrand;
