import React from 'react';
import { IIconProps } from '..';

const Alipay: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
          <rect x="0.25" y="0.25" width="23.5" height="17.5" rx="1.75" fill="white" stroke="#BDBDBD" strokeWidth="0.5"/>
        </mask>
        <g mask="url(#mask0)">
          <path d="M19.9999 11.889L14.8533 10.1583C14.8533 10.1583 15.2488 9.56621 15.671 8.40543C16.0933 7.24456 16.1538 6.60712 16.1538 6.60712L12.8222 6.57966V5.44174L16.8569 5.4133V4.60884H12.8214V2.7777H10.8453V4.60892H7.08081V5.41338L10.8453 5.38664V6.60712H7.82573V7.24448H14.0409C14.0409 7.24448 13.9725 7.76175 13.7342 8.40535C13.496 9.04888 13.2506 9.61248 13.2506 9.61248C13.2506 9.61248 10.3324 8.59111 8.7946 8.59111C7.25682 8.59111 5.38658 9.20886 5.20525 11.0018C5.02478 12.7938 6.07644 13.7645 7.55806 14.1218C9.03988 14.481 10.4079 14.1183 11.5989 13.5352C12.7902 12.9529 13.959 11.6293 13.959 11.6293L19.9572 14.5423C19.7125 15.9635 18.4793 17.0014 17.0372 17H6.96262C5.32786 17.0016 4.00146 15.6776 3.99994 14.0429V3.96276C3.99842 2.32788 5.32255 1.00146 6.95727 1H17.0372C18.672 0.998504 19.9985 2.32255 19.9999 3.95729V11.889H19.9999ZM12.499 10.8862C12.499 10.8862 10.6266 13.25 8.42044 13.25C6.21333 13.25 5.74995 12.1269 5.74995 11.3191C5.74995 10.5123 6.20877 9.6345 8.08667 9.50758C9.96353 9.38084 12.5 10.8862 12.5 10.8862H12.499H12.499Z" fill="#02A9F1"/>
        </g>
      </svg>
    </div>
  );
}

export default Alipay;
