import React from 'react';
import { IIconProps } from '..';

const StoreUrlOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3108 13.6554C17.852 17.3683 14.2365 19.9973 10.0066 20C11.7222 19.9968 13.1745 18.7561 14.1718 16.9693C14.6991 16.0246 15.1174 14.8997 15.3964 13.6554H19.3108Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M19.6579 12.6028H15.5944C15.7219 11.7716 15.7895 10.8987 15.7895 10C15.7895 9.27887 15.7459 8.57441 15.6629 7.89474H19.778C19.9234 8.5735 20 9.27781 20 10C20 10.9003 19.881 11.7729 19.6579 12.6028Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M19.4912 6.84211H15.4993C15.2256 5.39967 14.7686 4.09982 14.1718 3.03073C13.1732 1.24162 11.7184 0 10 0C14.4193 0 18.1691 2.86676 19.4912 6.84211Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M4.50071 6.84211C4.77437 5.39967 5.23143 4.09982 5.82816 3.03073C6.82679 1.24162 8.28158 0 10 0C5.58066 0 1.83088 2.86676 0.508849 6.84211H4.50071Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M4.3371 7.89474H0.221997C0.0765564 8.5735 0 9.27781 0 10C0 10.9003 0.118986 11.7729 0.34209 12.6028H4.40559C4.27814 11.7716 4.21053 10.8987 4.21053 10C4.21053 9.27887 4.25406 8.57441 4.3371 7.89474Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M5.32756 12.6028C5.19397 11.7795 5.12183 10.9062 5.12183 10C5.12183 9.27424 5.16811 8.56956 5.25517 7.89474H9.47368V12.6028H5.32756Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M4.6036 13.6554H0.689178C2.14818 17.3688 5.7644 19.9979 9.99497 20C8.27873 19.9976 6.82582 18.7566 5.82816 16.9693C5.30086 16.0246 4.88262 14.8997 4.6036 13.6554Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M5.53779 13.6554H9.47368V19.0568C8.43075 18.8385 7.42688 17.9749 6.62867 16.5448C6.17244 15.7275 5.79886 14.7484 5.53779 13.6554Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M10.5263 13.6554V19.0568C11.5693 18.8385 12.5731 17.9749 13.3713 16.5448C13.8276 15.7275 14.2011 14.7484 14.4622 13.6554H10.5263Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M14.6724 12.6028C14.806 11.7795 14.8782 10.9062 14.8782 10C14.8782 9.27424 14.8319 8.56956 14.7448 7.89474H10.5263V12.6028H14.6724Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M10.5263 6.84211H14.5716C14.311 5.54787 13.8957 4.39471 13.3713 3.45517C12.5731 2.02514 11.5693 1.16149 10.5263 0.943191V6.84211Z" fill="currentColor" fillOpacity="0.85" />
        <path d="M9.47368 6.84211H5.42838C5.68903 5.54787 6.10425 4.39471 6.62867 3.45517C7.42688 2.02514 8.43075 1.16149 9.47368 0.943191V6.84211Z" fill="currentColor" fillOpacity="0.85" />
      </svg>
    </div>
  );
}

export default StoreUrlOutlined;
