import React from 'react';
import { IIconProps } from '..';

const PaymentSetting: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.7778 3.33268H2.22222C1.9154 3.33268 1.66667 3.58141 1.66667 3.88824V16.1105C1.66667 16.4173 1.9154 16.666 2.22222 16.666H17.7778C18.0846 16.666 18.3333 16.4173 18.3333 16.1105V3.88824C18.3333 3.58141 18.0846 3.33268 17.7778 3.33268ZM2.22222 1.66602C0.994923 1.66602 0 2.66094 0 3.88824V16.1105C0 17.3378 0.994923 18.3327 2.22222 18.3327H17.7778C19.0051 18.3327 20 17.3378 20 16.1105V3.88824C20 2.66094 19.0051 1.66602 17.7778 1.66602H2.22222Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.277778 6.11046C0.277778 5.65022 0.650874 5.27713 1.11111 5.27713H18.8889C19.3491 5.27713 19.7222 5.65022 19.7222 6.11046C19.7222 6.5707 19.3491 6.94379 18.8889 6.94379H1.11111C0.650874 6.94379 0.277778 6.5707 0.277778 6.11046Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.3889 13.8882C11.3889 13.428 11.762 13.0549 12.2222 13.0549H15.5556C16.0158 13.0549 16.3889 13.428 16.3889 13.8882C16.3889 14.3485 16.0158 14.7216 15.5556 14.7216H12.2222C11.762 14.7216 11.3889 14.3485 11.3889 13.8882Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default PaymentSetting;
