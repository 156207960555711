import React from 'react';
import { IIconProps } from '..';

const VisaCard: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;

  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V16C24 17.1046 23.1046 18 22 18H2C0.89543 18 0 17.1046 0 16V2Z" fill="#002999"/>
        <path d="M16.5375 6.25092C16.0729 6.08179 15.5819 5.99685 15.0875 6.00009C13.4875 6.00009 12.3583 6.80676 12.35 7.96259C12.34 8.81259 13.1533 9.29092 13.7675 9.57592C14.3983 9.86759 14.6092 10.0509 14.6075 10.3134C14.6033 10.7109 14.1033 10.8909 13.6392 10.8909C13.0008 10.8909 12.6508 10.8018 12.1133 10.5784L11.9142 10.4859L11.6883 11.8176C12.0767 11.9801 12.7767 12.1193 13.5008 12.1301C15.2017 12.1301 16.3133 11.3293 16.3267 10.0976C16.34 9.42009 15.9017 8.90592 14.9758 8.48259C14.4142 8.20759 14.0642 8.02342 14.0642 7.74426C14.0642 7.49759 14.3633 7.23426 14.9883 7.23426C15.404 7.22429 15.8171 7.3018 16.2008 7.46176L16.3508 7.52926L16.5767 6.24259L16.5375 6.25092ZM20.69 6.10926H19.44C19.0508 6.10926 18.76 6.21509 18.5892 6.60176L16.185 12.0468H17.8858L18.2258 11.1551L20.3008 11.1568C20.3517 11.3651 20.5008 12.0468 20.5008 12.0468H22L20.69 6.10926ZM10.05 6.05926H11.6692L10.6567 12.0001H9.03667L10.05 6.05759V6.05926ZM5.93417 9.33342L6.1025 10.1584L7.68667 6.10926H9.4025L6.85167 12.0384H5.14L3.74 7.01676C3.71742 6.934 3.66366 6.86322 3.59 6.81926C3.08648 6.5577 2.55238 6.35979 2 6.23009L2.02083 6.10509H4.63C4.98333 6.11842 5.26833 6.23009 5.36583 6.60842L5.93417 9.33592V9.33342ZM18.6908 9.93926L19.3367 8.27759C19.3283 8.29426 19.47 7.93426 19.5517 7.71092L19.6625 8.22342L20.0367 9.93759H18.6908V9.93926Z" fill="white"/>
      </svg>
    </div>
  );
}

export default VisaCard;
