import React from 'react';
import { IIconProps } from '..';

const QueueingSetting: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.22222 3.05599H17.7778C18.5448 3.05599 19.1667 3.67782 19.1667 4.44488V15.556C19.1667 16.3231 18.5448 16.9449 17.7778 16.9449H2.22222C1.45516 16.9449 0.833333 16.3231 0.833333 15.556V4.44488C0.833333 3.67782 1.45516 3.05599 2.22222 3.05599Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.8055 6.75628C10.4823 6.56981 10.0596 6.68169 9.87314 7.00492L7.8592 10.4858L6.67818 7.20383C6.66575 7.16653 6.65331 7.14167 6.62845 7.10437C6.57872 6.96762 6.47927 6.83087 6.33009 6.75628C6.00686 6.56981 5.58418 6.68169 5.39771 7.00492L2.62542 11.8036C2.43895 12.1268 2.55083 12.5495 2.87406 12.736C2.98594 12.7981 3.09783 12.823 3.20972 12.823C3.44592 12.823 3.66969 12.6987 3.80644 12.4749L5.84525 8.94427L7.0014 12.1641C7.01384 12.3879 7.13815 12.5992 7.33706 12.7235C7.44895 12.7857 7.56083 12.8105 7.67272 12.8105C7.90892 12.8105 8.13269 12.6862 8.26944 12.4625L11.0542 7.68866C11.2406 7.36544 11.1287 6.94276 10.8055 6.75628ZM14.3983 6.94276C12.6454 6.94276 11.2033 8.37241 11.2033 10.1377C11.2033 11.903 12.633 13.3327 14.3983 13.3327C16.1636 13.3327 17.5933 11.903 17.5933 10.1377C17.5933 8.37241 16.1512 6.94276 14.3983 6.94276ZM14.3983 11.9528C13.3913 11.9528 12.5708 11.1323 12.5708 10.1253C12.5708 9.11832 13.3913 8.29782 14.3983 8.29782C15.4053 8.29782 16.2258 9.11832 16.2258 10.1253C16.2258 11.1323 15.4053 11.9528 14.3983 11.9528Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default QueueingSetting;
