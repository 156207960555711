import React from 'react';
import { getPrimaryColor, IIconProps } from '..';
import TagPath from '../common/TagPath';
import TagDefs from '../common/TagDefs';

const TagItem: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  const color = getPrimaryColor();
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <TagPath />
        <path opacity="0.65" d="M108.209 50.0113L70.6039 45V58.052L76 59L108.209 50.0113Z" fill={color}/>
        <path opacity="0.8" d="M40.5212 51.5041L70.6056 45V58L40.5212 51.5041Z" fill={color}/>
        <path opacity="0.65" d="M41 68.1446V101.786L76.0144 110V59L70.1866 73.9968C69.9817 74.524 69.4199 74.8199 68.8692 74.6906L41 68.1446Z" fill={color}/>
        <path opacity="0.4" d="M76 59L40.5211 51.5049L33.6461 65.2548C33.3172 65.9128 33.6914 66.705 34.4085 66.8689L69.0329 74.7791C69.5867 74.9056 70.1488 74.6031 70.3483 74.0711L76 59Z" fill={color}/>
        <path opacity="0.4" d="M76 59L108.211 50.0117L116.279 63.8408C116.643 64.465 116.339 65.2662 115.652 65.4912L86.514 75.0387C86.0079 75.2045 85.4545 74.9961 85.1835 74.5377L76 59Z" fill={color}/>
        <path opacity="0.8" d="M76 59V110L108.211 101.708V67.9295L86.514 75.0387C86.0079 75.2045 85.4545 74.9961 85.1835 74.5377L76 59Z" fill={color}/>
        <TagDefs color={color} />
      </svg>
    </div>
  );
}

export default TagItem;
