import React from 'react';
import { IIconProps } from '..';

const WeChatPay: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1.33em" height="1em" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
          <rect x="0.25" y="0.25" width="23.5" height="17.5" rx="1.75" fill="white" stroke="#BDBDBD" strokeWidth="0.5"/>
        </mask>
        <g mask="url(#mask0)">
          {/* <rect x="0.25" y="0.25" width="23.5" height="17.5" rx="1.75" fill="white" stroke="#BDBDBD" strokeWidth="0.5"/> */}
          <path d="M9.54649 11.1209C9.4653 11.1623 9.37368 11.1862 9.27637 11.1862C9.05145 11.1862 8.85584 11.0613 8.75319 10.8769L8.71382 10.79L7.07537 7.16787C7.05769 7.12832 7.04663 7.08394 7.04663 7.04067C7.04663 6.87367 7.18095 6.73823 7.34671 6.73823C7.41403 6.73823 7.4761 6.76065 7.52632 6.79826L9.4596 8.18502C9.60097 8.27832 9.76963 8.33287 9.95103 8.33287C10.0595 8.33287 10.1626 8.31257 10.2589 8.27718L19.3515 4.2C17.7218 2.26484 15.0375 1 11.9999 1C7.02929 1 3 4.38299 3 8.55619C3 10.8332 4.21235 12.8826 6.10973 14.2677C6.26204 14.3771 6.36157 14.557 6.36157 14.76C6.36157 14.827 6.34727 14.8887 6.33004 14.9526C6.17849 15.5223 5.9358 16.4341 5.9245 16.4768C5.90561 16.5483 5.87597 16.623 5.87597 16.6978C5.87597 16.8647 6.0104 17 6.17616 17C6.24147 17 6.29448 16.9757 6.34951 16.9438L8.31981 15.7978C8.468 15.7115 8.62492 15.6582 8.79771 15.6582C8.88966 15.6582 8.97856 15.6724 9.06235 15.6982C9.98145 15.9646 10.9731 16.1126 11.9999 16.1126C16.9704 16.1126 21 12.7296 21 8.55623C21 7.29232 20.6283 6.10183 19.9749 5.05482L9.61213 11.0827L9.54649 11.1209Z" fill="#38B035"/>
        </g>
      </svg>
    </div>
  );
}

export default WeChatPay;
