import React from 'react';
import { IIconProps } from '..';

const AppleOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0176 5.20484C11.5797 5.20484 10.4197 6.05317 9.67055 6.05317C8.88514 6.05317 7.83389 5.25197 6.58931 5.25197C4.22098 5.25197 1.81641 7.1725 1.81641 10.7661C1.81641 13.0166 2.71057 15.3848 3.81015 16.9283C4.74057 18.2126 5.57431 19.273 6.75848 19.273C7.93056 19.273 8.45014 18.5189 9.91222 18.5189C11.3864 18.5189 11.7247 19.2494 13.0176 19.2494C14.3105 19.2494 15.1685 18.1065 15.978 16.9754C16.8722 15.6676 17.2589 14.3951 17.271 14.3362C17.1985 14.3126 14.7335 13.3347 14.7335 10.5776C14.7335 8.19756 16.6668 7.13715 16.7755 7.05467C15.5068 5.25197 13.5614 5.20484 13.0176 5.20484ZM12.353 3.68491C12.933 2.98975 13.3439 2.04716 13.3439 1.09279C13.3439 0.963187 13.3318 0.83358 13.3076 0.727539C12.3651 0.762886 11.2051 1.34022 10.5164 2.12964C9.98472 2.73054 9.47722 3.68491 9.47722 4.63929C9.47722 4.78067 9.50139 4.93385 9.51347 4.98098C9.57389 4.99276 9.67055 5.00454 9.76722 5.00454C10.6251 5.00454 11.7126 4.43899 12.353 3.68491Z" fill="#333333"/>
      </svg>
    </div>
  );
}

export default AppleOutlined;
