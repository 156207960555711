import React from 'react';
import { IIconProps } from '..';

const PickUpOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.1778 7.46426H6.83062C6.2692 7.46426 5.79665 7.88447 5.73106 8.44204L4.82928 16.1071H15.1791L14.2773 8.44205C14.2117 7.88448 13.7392 7.46426 13.1778 7.46426ZM6.83062 5.71426C5.3818 5.71426 4.16233 6.79868 3.99304 8.23757L3.04904 16.2617C2.94912 17.1109 3.61269 17.8571 4.46782 17.8571H15.5406C16.3957 17.8571 17.0593 17.1109 16.9593 16.2617L16.0153 8.23757C15.8461 6.79868 14.6266 5.71426 13.1778 5.71426H6.83062Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.331 9.46967C7.62389 9.17678 8.09877 9.17678 8.39166 9.46967L8.51421 9.59222C9.3371 10.4151 10.6713 10.4151 11.4942 9.59222L11.6167 9.46967C11.9096 9.17678 12.3845 9.17678 12.6774 9.46967C12.9703 9.76256 12.9703 10.2374 12.6774 10.5303L12.5548 10.6529C11.1461 12.0616 8.86223 12.0616 7.45355 10.6529L7.331 10.5303C7.03811 10.2374 7.03811 9.76256 7.331 9.46967Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.85589 3.69694C8.55496 4.04951 8.25419 4.77438 8.25419 6.25H6.75419C6.75419 4.61902 7.07842 3.46889 7.71499 2.72311C8.37625 1.94839 9.25067 1.75 10.0042 1.75C10.7577 1.75 11.6321 1.94839 12.2934 2.72311C12.93 3.46889 13.2542 4.61902 13.2542 6.25H11.7542C11.7542 4.77438 11.4534 4.04951 11.1525 3.69694C10.8763 3.37331 10.5007 3.25 10.0042 3.25C9.50771 3.25 9.13213 3.37331 8.85589 3.69694Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default PickUpOutlined;
