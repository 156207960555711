import React from 'react';
import { IIconProps } from '..';

const DeferredInvoiceOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1225 3.04118C15.1225 2.28797 15.1225 1.33206 15.1225 0.536432C14.7981 0.856721 14.3845 1.4279 14.0626 1.75067C13.6596 2.15468 13.1852 2.15495 12.7849 1.75259C12.3952 1.3607 11.9411 0.925846 11.5489 0.536432C11.3712 0.716818 11.1954 0.896929 11.017 1.07484C10.7921 1.29929 10.5687 1.52566 10.3379 1.74461C9.94881 2.11364 9.43441 2.10483 9.05111 1.72753C8.65698 1.33977 8.22841 0.929151 7.83428 0.541114C7.66958 0.700845 7.50154 0.862229 7.33572 1.02554C7.09602 1.26128 6.86021 1.50115 6.61829 1.73469C6.22666 2.11254 5.7106 2.10923 5.32147 1.72753C4.9565 1.36924 4.59487 1.00736 4.23157 0.647693C4.1963 0.612717 4.15852 0.580496 4.10047 0.527344C3.86244 0.770796 3.62885 1.01094 3.39387 1.24944C3.21611 1.42983 3.03835 1.61049 2.85698 1.78757C2.49396 2.14256 2.00956 2.14091 1.6482 1.78399C1.32046 1.46012 0.883554 1.02361 0.556641 0.698917C0.556641 3.07644 0.556641 15.341 0.556641 17.7186C0.556641 17.9441 0.614135 18.0017 0.844391 18.0017C2.51979 18.002 4.19518 18.0017 5.87058 18.0017C7.72152 18.0017 9.57273 18.0017 11.4237 18.0017C12.5619 18.0017 13.7001 18.002 14.8383 18.0017C15.063 18.0017 15.1189 17.9469 15.1189 17.7277C15.1189 17.0163 15.1189 16.3049 15.1189 15.5933" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="15.1353" cy="9.26033" r="4.92049" stroke="currentColor"/>
        <path d="M15.4132 13.0141H14.8702V12.4673C14.3218 12.4265 13.8815 12.2592 13.5492 11.9653C13.2196 11.6715 13.0535 11.2975 13.0508 10.8431H13.7437C13.7653 11.1152 13.8747 11.3383 14.0719 11.5124C14.2691 11.6865 14.5352 11.794 14.8702 11.8348V9.71275L14.546 9.62297C14.1111 9.50871 13.7761 9.321 13.5411 9.05983C13.3088 8.79866 13.1926 8.47899 13.1926 8.10084C13.1926 7.65467 13.3425 7.28876 13.6424 7.00311C13.9422 6.71745 14.3515 6.54878 14.8702 6.49709V5.92578H15.4132V6.49301C15.9183 6.53654 16.3249 6.70385 16.6328 6.99495C16.9435 7.28604 17.1056 7.65195 17.1191 8.09268H16.4302C16.4221 7.83967 16.3249 7.62475 16.1385 7.44791C15.9548 7.26836 15.713 7.1609 15.4132 7.12553V9.12104L15.7576 9.20673C16.2546 9.34004 16.622 9.53456 16.8597 9.79029C17.1002 10.046 17.2204 10.3793 17.2204 10.7901C17.2204 11.2716 17.061 11.6593 16.7422 11.9531C16.4262 12.2469 15.9831 12.417 15.4132 12.4632V13.0141ZM16.5194 10.8472C16.5194 10.5779 16.4302 10.3602 16.2519 10.1943C16.0736 10.0283 15.794 9.90455 15.4132 9.82293V11.847C15.7643 11.828 16.0358 11.7314 16.2276 11.5573C16.4221 11.3832 16.5194 11.1465 16.5194 10.8472ZM13.8896 8.06003C13.8896 8.54973 14.2164 8.86395 14.8702 9.00269V7.12553C14.5865 7.14186 14.3515 7.23843 14.1651 7.41527C13.9814 7.58938 13.8896 7.8043 13.8896 8.06003Z" fill="currentColor"/>
        <path d="M2.55664 6H9.55664" stroke="currentColor" strokeLinecap="round"/>
        <path d="M2.55664 9H8.55664" stroke="currentColor" strokeLinecap="round"/>
        <path d="M2.55664 12H6.55664" stroke="currentColor" strokeLinecap="round"/>
      </svg>
    </div>
  );
}

export default DeferredInvoiceOutlined;
