import React from 'react';
import { IIconProps } from '..';

const PlusCircleOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5665 9.43276H13.0691C13.3822 9.43276 13.636 9.68656 13.636 9.99964C13.636 10.3127 13.3822 10.5665 13.0691 10.5665H10.5665V13.0691C10.5665 13.3822 10.3127 13.636 9.99964 13.636C9.68656 13.636 9.43276 13.3822 9.43276 13.0691V10.5665H6.93017C6.61708 10.5665 6.36328 10.3127 6.36328 9.99964C6.36328 9.68656 6.61708 9.43276 6.93017 9.43276H9.43276V6.93017C9.43276 6.61708 9.68656 6.36328 9.99964 6.36328C10.3127 6.36328 10.5665 6.61708 10.5665 6.93017V9.43276Z" fill="white"/>
      </svg>
    </div>
  );
}

export default PlusCircleOutlined;
