import React from 'react';
import { IIconProps } from '..';

const ScanOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#scan-clip0)">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.000501601 2.5646C0.000501601 1.14965 1.15317 0 2.5696 0H4.75572C5.23167 0.00759049 5.61413 0.394887 5.6142 0.870775C5.61426 1.34666 5.23189 1.73402 4.75595 1.74174L4.75146 1.74181L2.5696 1.74177C2.11492 1.74177 1.74517 2.11144 1.74517 2.5646V4.75226C1.74504 5.23345 1.35428 5.62295 0.872983 5.62308C0.641725 5.62314 0.419845 5.5315 0.256188 5.36823C0.0925202 5.20494 0.000501601 4.98338 0.000501601 4.75226V2.5646ZM20.0005 2.5646C20.0005 1.14965 18.8478 0 17.4314 0H15.2453C14.7693 0.00759049 14.3869 0.394887 14.3868 0.870775C14.3867 1.34666 14.7691 1.73402 15.2451 1.74174L15.2495 1.74181L17.4314 1.74177C17.8861 1.74177 18.2558 2.11144 18.2558 2.5646V4.75226C18.256 5.23345 18.6467 5.62295 19.128 5.62308C19.3593 5.62314 19.5812 5.5315 19.7448 5.36823C19.9085 5.20494 20.0005 4.98338 20.0005 4.75226V2.5646ZM3.04297 9.67662C3.04297 9.06582 3.53849 8.57067 4.14975 8.57067H15.771C16.3823 8.57067 16.8778 9.06582 16.8778 9.67662C16.8778 10.2874 16.3823 10.7826 15.771 10.7826H4.14975C3.53849 10.7826 3.04297 10.2874 3.04297 9.67662ZM2.5691 19.9995C1.15267 19.9995 0 18.8499 0 17.4349V15.2473C0 15.0161 0.0920197 14.7946 0.255688 14.6313C0.419344 14.468 0.641223 14.3764 0.87248 14.3764C1.35378 14.3766 1.74454 14.7661 1.74466 15.2473V17.4349C1.74466 17.8881 2.11441 18.2577 2.5691 18.2577L4.75096 18.2577L4.75545 18.2578C5.23139 18.2655 5.61376 18.6529 5.61369 19.1287C5.61363 19.6046 5.23116 19.9919 4.75522 19.9995H2.5691ZM17.4309 19.9995C18.8473 19.9995 20 18.8499 20 17.4349V15.2473C20 15.0161 19.908 14.7946 19.7443 14.6313C19.5807 14.468 19.3588 14.3764 19.1275 14.3764C18.6462 14.3766 18.2555 14.7661 18.2553 15.2473V17.4349C18.2553 17.8881 17.8856 18.2577 17.4309 18.2577L15.249 18.2577L15.2446 18.2578C14.7686 18.2655 14.3862 18.6529 14.3863 19.1287C14.3864 19.6046 14.7688 19.9919 15.2448 19.9995H17.4309Z" fill="currentColor"/>
        </g>
        <defs>
          <clipPath id="scan-clip0">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default ScanOutlined;
