import React from 'react';
import { getPrimaryColor, IIconProps } from '..';
import StatusPath from '../common/StatusPath';
import StatusDefs from '../common/StatusDefs';

const StatusSuccess: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <StatusPath tag="success" />
        <path d="M96.603 64.9613L70.7655 91.7738C70.4902 92.0625 70.1118 92.2305 69.713 92.241C69.3143 92.2515 68.9275 92.1038 68.6374 91.83L68.5811 91.7738L53.253 75.8363C52.965 75.5288 52.8047 75.1232 52.8047 74.7019C52.8047 74.2806 52.965 73.875 53.253 73.5675L58.0624 68.6082C58.3393 68.3189 58.7192 68.1507 59.1195 68.1401C59.5198 68.1296 59.9081 68.2776 60.1999 68.5519L60.2561 68.6082L69.6311 78.3769L89.553 57.7519C89.8283 57.4632 90.2067 57.2952 90.6054 57.2847C91.0042 57.2742 91.3909 57.4219 91.6811 57.6957L91.7374 57.7519L96.5561 62.7488C96.849 63.0557 97.0124 63.4636 97.0124 63.8879C97.0124 64.3121 96.849 64.72 96.5561 65.0269L96.603 64.9613Z" fill="white"/>
        <StatusDefs tag="success" color={getPrimaryColor()} />
      </svg>
    </div>
  );
}

export default StatusSuccess;
