import React from 'react';
import { IIconProps } from '..';

const MemberTagOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0H8.12361L9.1859 13.5433L15.6846 0H18.7778L9.52958 20H2.15602L1 0Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default MemberTagOutlined;
