import React from 'react';
import { IIconProps } from '..';

const CategoryOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1" fill="white">
          <rect x="11" y="11" width="9" height="9" rx="1"/>
        </mask>
        <rect x="11" y="11" width="9" height="9" rx="1" stroke="currentColor" strokeWidth="3" mask="url(#path-1-inside-1)"/>
        <mask id="path-2-inside-2" fill="white">
          <rect width="9" height="9" rx="1"/>
        </mask>
        <rect width="9" height="9" rx="1" stroke="currentColor" strokeWidth="3" mask="url(#path-2-inside-2)"/>
        <circle cx="4.5" cy="15.5" r="3.75" stroke="currentColor" strokeWidth="1.5"/>
        <circle cx="15.5" cy="4.5" r="3.75" stroke="currentColor" strokeWidth="1.5"/>
      </svg>
    </div>
  );
}

export default CategoryOutlined;
