import React from 'react';
import { IIconProps } from '..';

const PhoneOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 1.66602H6.5C5.67188 1.66602 5 2.35469 5 3.20352V16.5285C5 17.3773 5.67188 18.066 6.5 18.066H13.5C14.3281 18.066 15 17.3773 15 16.5285V3.20352C15 2.35469 14.3281 1.66602 13.5 1.66602ZM10 17.041C9.44687 17.041 9 16.583 9 16.016C9 15.4491 9.44687 14.991 10 14.991C10.5531 14.991 11 15.4491 11 16.016C11 16.583 10.5531 17.041 10 17.041ZM13.5 13.5816C13.5 13.793 13.3312 13.966 13.125 13.966H6.875C6.66875 13.966 6.5 13.793 6.5 13.5816V3.58789C6.5 3.37648 6.66875 3.20352 6.875 3.20352H13.125C13.3312 3.20352 13.5 3.37648 13.5 3.58789V13.5816Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default PhoneOutlined;
