import React from 'react';
import { IIconProps } from '..';

const UpOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7964 11.7686L10.3394 7.14608C10.1518 6.95131 9.84869 6.95131 9.66058 7.14608L5.20358 11.7686C4.93214 12.0497 4.93214 12.5072 5.20358 12.7889C5.47501 13.07 5.91555 13.07 6.18698 12.7889L10.0002 8.83456L13.8125 12.7889C14.0845 13.07 14.525 13.07 14.7964 12.7889C15.0679 12.5072 15.0679 12.0497 14.7964 11.7686Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default UpOutlined;
