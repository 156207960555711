import React from 'react';
import { IIconProps } from '..';

const FacebookOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3.63636C0 1.62806 1.62806 0 3.63636 0H16.3636C18.3719 0 20 1.62806 20 3.63636V16.3636C20 18.3719 18.3719 20 16.3636 20H3.63636C1.62806 20 0 18.3719 0 16.3636V3.63636Z" fill="white"/>
        <path d="M11.1928 20V12.9297H13.5514L13.9045 10.1743H11.1927V8.41507C11.1927 7.61731 11.4129 7.07368 12.5499 7.07368L14 7.07299V4.60856C13.7492 4.57504 12.8883 4.5 11.8869 4.5C9.7961 4.5 8.36469 5.78407 8.36469 8.14226V10.1743H6V12.9297H8.36469V19.9999L11.1928 20Z" fill="#485A96"/>
      </svg>
    </div>
  );
}

export default FacebookOutlined;
