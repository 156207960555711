import React from 'react';
import { IIconProps } from '..';

const MenusOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3.11111C0 2.49913 0.497504 2 1.11111 2H18.8889C19.3172 2 19.6889 2.24306 19.8743 2.59896C19.9546 2.75087 20 2.92448 20 3.11111C20 3.72309 19.5025 4.22222 18.8889 4.22222H1.11111C0.730523 4.22222 0.394694 4.03125 0.194363 3.74045C0.0717502 3.5625 0 3.34549 0 3.11111Z" fill="currentColor" />
        <path d="M0 9.77778C0 9.1658 0.497504 8.66667 1.11111 8.66667H18.8889C19.5025 8.66667 20 9.1658 20 9.77778C20 10.3898 19.5025 10.8889 18.8889 10.8889H1.11111C0.497504 10.8889 0 10.3898 0 9.77778Z" fill="currentColor" />
        <path d="M0.218506 15.7847C0.0812446 15.967 0 16.197 0 16.4444C0 17.0564 0.497504 17.5556 1.11111 17.5556H18.8889C19.3453 17.5556 19.7375 17.2778 19.9086 16.8828C19.9674 16.7483 20 16.6007 20 16.4444C20 15.8325 19.5025 15.3333 18.8889 15.3333H1.11111C0.745443 15.3333 0.421007 15.5113 0.218506 15.7847Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default MenusOutlined;
