import React from 'react';
import { IIconProps } from '..';

const BookingSettingBrand: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.2864 13.8739C11.0489 13.729 10.7655 13.6789 10.4927 13.7334C10.2199 13.788 9.97759 13.9433 9.81407 14.1684C9.5196 14.5924 9.06613 14.987 8.50077 14.8633C7.37593 14.6189 5.93895 12.9817 5.93895 12.9817C5.93895 12.9817 4.81117 11.739 4.69633 10.5936C4.63154 9.97228 5.15569 9.55708 5.65038 9.30679C5.85628 9.2 6.01361 9.0186 6.09024 8.79968C6.16686 8.58075 6.15698 8.34083 6.06263 8.12895L5.11152 5.99116C5.0404 5.83552 4.93686 5.69687 4.80782 5.58449C4.67878 5.4721 4.52722 5.38858 4.36329 5.33952C4.19936 5.29045 4.02684 5.27696 3.85727 5.29997C3.68771 5.32297 3.52502 5.38193 3.38008 5.47291C2.68515 5.90871 1.89305 6.44757 1.63982 6.77737C0.544419 8.20256 0.606257 10.9411 3.82472 15.0606C7.85295 19.5246 10.3971 19.2508 11.943 18.3232C12.3052 18.1053 12.9412 17.3633 13.4566 16.7154C13.56 16.5844 13.6343 16.4328 13.6747 16.2708C13.7151 16.1088 13.7206 15.94 13.6908 15.7757C13.661 15.6114 13.5966 15.4554 13.5019 15.3179C13.4072 15.1803 13.2843 15.0645 13.1415 14.9781L11.2864 13.8739Z" stroke="currentColor" strokeMiterlimit="10" />
        <path d="M15.3855 2.37484C12.5646 1.30594 9.45799 2.60157 8.45094 5.26351C8.17807 6.00013 8.08123 6.79035 8.16814 7.57107C8.25506 8.35178 8.52333 9.10136 8.95152 9.75993C8.97736 9.8047 8.99097 9.85548 8.99097 9.90716C8.99097 9.95885 8.97736 10.0096 8.95152 10.0544L8.55694 10.7199C8.53056 10.7704 8.51942 10.8276 8.52489 10.8843C8.53036 10.9411 8.55219 10.9951 8.58775 11.0397C8.6233 11.0843 8.67103 11.1176 8.72516 11.1356C8.77928 11.1535 8.83746 11.1554 8.89263 11.141L9.66706 10.8759C9.71691 10.8584 9.77055 10.8547 9.82236 10.865C9.87417 10.8754 9.92224 10.8995 9.96152 10.9348C10.4857 11.4035 11.0915 11.7718 11.7489 12.0214C14.5698 13.0903 17.6735 11.7947 18.6835 9.12979C19.6935 6.46491 18.2064 3.44079 15.3855 2.37484Z" stroke="currentColor" strokeMiterlimit="10" />
        <path d="M12.0962 4.89453V9.95338" stroke="currentColor" strokeWidth="0.6" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M13.0476 4.98828C13.0476 5.5772 13.1595 6.06601 12.3291 6.06601" stroke="currentColor" strokeWidth="0.6" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M11.1538 4.98828C11.1538 5.5772 11.039 6.06307 11.8723 6.06601" stroke="currentColor" strokeWidth="0.6" strokeMiterlimit="10" strokeLinecap="round" />
        <path d="M15.4358 9.8401V4.78125C15.0598 5.42969 14.75 6.11432 14.5112 6.82481C14.199 7.86132 15.0353 7.9791 15.0353 7.9791" stroke="currentColor" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}

export default BookingSettingBrand;
