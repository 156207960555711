import React from 'react';
import { IIconProps } from '..';

const DefaultAvatarOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99994 11.6667C12.4565 11.6667 14.724 9.52055 14.9781 6.38178C15.2439 3.09758 13.0628 0 9.99994 0C6.93719 0 4.75598 3.09758 5.02195 6.38178C5.276 9.52055 7.54346 11.6667 9.99994 11.6667ZM9.95545 13.3608C4.45719 13.3608 0 15.5472 0 18.2444C0 19.1667 0.847871 19.5222 3.34143 19.7917C5.26908 20 7.57051 20 10 20C12.4886 20 14.9566 20 16.6929 19.7917C19.149 19.4969 20 19.1667 20 18.2444C20 15.5472 15.4535 13.3608 9.95545 13.3608Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default DefaultAvatarOutlined;
