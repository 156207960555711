import React from 'react';
import { getPrimaryColor, IIconProps } from '..';
import TagPath from '../common/TagPath';
import TagDefs from '../common/TagDefs';

const TagStore: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <TagPath />
        <path opacity="0.9" d="M108.056 56.811L102.649 45.387C101.391 42.701 98.8403 41.001 96.0177 41.001H53.9842C51.1955 41.001 48.611 42.701 47.3527 45.387L41.9455 56.811C38.9528 64.2231 43.3738 72.9611 50.8555 73.6411C55.0384 74.0151 59.0513 71.9411 61.3979 68.2011C62.146 67.0111 63.6424 66.7051 64.7646 67.4871C65.0367 67.6911 65.2407 67.9291 65.4108 68.2011C67.4852 71.4991 71.022 73.6751 75.0009 73.6751C78.9798 73.6751 82.5166 71.4991 84.5911 68.2011C85.3393 67.0111 86.8356 66.7051 87.9579 67.4871C88.2299 67.6911 88.434 67.9291 88.604 68.2011C90.9506 71.9411 94.9975 74.0151 99.1464 73.6411C106.628 72.9611 111.049 64.2231 108.056 56.811ZM100.235 78.7751C99.5545 78.8771 98.8744 78.9111 98.1942 78.9111C94.5554 78.9111 91.0186 77.6191 88.1279 75.2051C87.2437 74.4571 85.9514 74.4571 85.0672 75.2051C82.1766 77.6191 78.6398 78.9111 75.0009 78.9111C71.3621 78.9111 67.8253 77.6191 64.9347 75.2051C64.0505 74.4571 62.7582 74.4571 61.874 75.2051C58.4392 78.0611 54.0862 79.3531 49.8012 78.7751C48.4749 78.6051 47.2847 79.5911 47.1146 81.0531C47.1146 81.1551 47.0806 81.2571 47.0806 81.3931V103.697C47.0806 106.621 49.2231 109.001 51.8757 109.001H98.1262C100.779 109.001 102.921 106.621 102.921 103.697V81.3931C102.921 79.8291 101.663 78.5711 100.235 78.7751ZM90.1684 98.5632H59.8335C58.5072 98.5632 57.419 97.3732 57.419 95.9452C57.419 94.5172 58.5072 93.3272 59.8335 93.3272H90.1684C91.4947 93.3272 92.5829 94.5172 92.5829 95.9452C92.5829 97.3732 91.4947 98.5632 90.1684 98.5632Z" fill="url(#paint8_linear)"/>
        <TagDefs color={getPrimaryColor()} />
      </svg>
    </div>
  );
}

export default TagStore;
