import React from 'react';
import { IIconProps } from '..';

const FoodOrderingOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.766 14.2745H18.2186C17.8636 10.0016 14.4372 6.63147 10.2298 6.5207C10.3723 6.47025 10.4968 6.38321 10.5889 6.26958C10.681 6.15595 10.737 6.02032 10.7505 5.87825C10.7835 5.68116 10.7335 5.47978 10.611 5.31654C10.4884 5.1533 10.303 5.04096 10.0937 5.00318C9.99003 4.99409 9.88548 5.00455 9.78622 5.03396C9.68696 5.06337 9.59502 5.11112 9.51584 5.17438C9.43665 5.23764 9.37184 5.31514 9.32523 5.40227C9.27862 5.4894 9.25117 5.58439 9.2445 5.68163C9.22094 5.85415 9.25893 6.02913 9.35257 6.17936C9.4462 6.32959 9.59024 6.44667 9.76229 6.51239C5.54892 6.63424 2.12556 10.0016 1.77345 14.2662H1.29708C1.13435 14.2662 1.0012 14.5431 1.0012 14.6982C0.997493 14.7366 1.00239 14.7753 1.01559 14.8118C1.02879 14.8483 1.05 14.8819 1.07785 14.9103C1.10571 14.9387 1.1396 14.9614 1.17735 14.9768C1.2151 14.9923 1.25588 15.0002 1.29708 15H18.7039C18.7858 14.9964 18.8628 14.9627 18.9183 14.9061C18.9737 14.8496 19.003 14.7748 18.9998 14.6982C18.9998 14.5458 18.9287 14.2745 18.766 14.2745Z" stroke="currentColor" strokeMiterlimit="10"/>
      </svg>
    </div>
  );
}

export default FoodOrderingOutlined;
