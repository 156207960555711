import React from 'react';
import { IIconProps } from '..';

const RightOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.23116 14.7964L12.8536 10.3394C13.0484 10.1518 13.0484 9.84869 12.8536 9.66058L8.23116 5.20358C7.94999 4.93214 7.49254 4.93214 7.21087 5.20358C6.92971 5.47501 6.92971 5.91555 7.21087 6.18698L11.1652 10.0002L7.21087 13.8125C6.92971 14.0845 6.92971 14.525 7.21087 14.7964C7.49254 15.0679 7.94999 15.0679 8.23116 14.7964Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default RightOutlined;
