import React from 'react';
import { IIconProps } from '..';

const BecomePartnerSetting: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.9145 7.4339C18.9145 7.35952 18.9034 7.29005 18.8858 7.22436L17.695 3.07161L17.694 3.06758C17.6351 2.84169 17.5097 2.36067 17.2133 1.93436C16.9077 1.4948 16.418 1.11133 15.651 1.11133H4.34976C3.84175 1.11133 3.39474 1.29708 3.04141 1.64209C2.69147 1.9838 2.44254 2.47233 2.30647 3.0679L1.16394 7.22885C1.14455 7.29828 1.13672 7.36639 1.13672 7.4339C1.13672 8.71824 1.79143 9.84263 2.7681 10.4736V17.0399C2.7681 18.0603 3.56407 18.8891 4.54397 18.8891H15.5092C16.4891 18.8891 17.2851 18.0603 17.2851 17.0399V10.4755C18.26 9.84432 18.9145 8.71804 18.9145 7.4339ZM15.8541 10.989V17.0962C15.8288 17.2718 15.6827 17.4072 15.5072 17.4072H4.54203C4.3489 17.4072 4.19128 17.243 4.19128 17.0419V10.989C4.31646 11.0041 4.4439 11.0122 4.57311 11.0122C5.64446 11.0122 6.65282 10.486 7.29784 9.61503C7.94536 10.4907 8.951 11.0122 10.0227 11.0122C11.0963 11.0122 12.1043 10.4789 12.7475 9.61228C13.3907 10.4789 14.3987 11.0122 15.4723 11.0122C15.6015 11.0122 15.7289 11.0041 15.8541 10.989ZM15.4762 9.53023C14.6961 9.53023 14.0023 9.0763 13.6626 8.34478C13.538 8.07735 13.3851 7.89974 13.2198 7.79026C13.0542 7.6806 12.8885 7.64745 12.7514 7.64745C12.6142 7.64745 12.4484 7.68061 12.2827 7.79066C12.1174 7.90044 11.9648 8.07828 11.8403 8.34642C11.4986 9.07836 10.8047 9.53214 10.0269 9.53225C9.29829 9.53034 8.62829 9.1215 8.27123 8.46255C7.98123 7.92776 7.60011 7.78902 7.30955 7.78902C7.01481 7.78902 6.62813 7.93386 6.31998 8.48458C5.95933 9.13074 5.29445 9.53034 4.5767 9.53225C3.49848 9.53209 2.61398 8.64329 2.56486 7.53186L3.68662 3.45239L3.68874 3.4431C3.76047 3.12806 3.86005 2.91416 3.97312 2.78209C4.08041 2.65675 4.20388 2.59935 4.35365 2.59935H15.651C15.7897 2.59935 15.883 2.61669 15.9747 2.7039C16.0785 2.80272 16.2039 3.01448 16.3251 3.47989L17.4901 7.53676C17.4368 8.644 16.5541 9.53023 15.4762 9.53023Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default BecomePartnerSetting;
