import React from 'react';
import { IIconProps } from '..';

const MapOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6536 17.2381L11.45 17.278L11.3344 17.4504L10.0003 19.4402L8.6662 17.4505L8.55065 17.2781L8.34701 17.2383C4.44182 16.4735 1.5 13.0506 1.5 8.94891C1.5 4.28539 5.30289 0.5 10 0.5C14.6971 0.5 18.5 4.28539 18.5 8.94891C18.5 13.0504 15.5585 16.4731 11.6536 17.2381ZM9.93737 19.534C9.93744 19.5339 9.9375 19.5338 9.93757 19.5337L9.93737 19.534Z" fill="currentColor" stroke="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.99929 12.2026C11.8068 12.2026 13.272 10.7457 13.272 8.94848C13.272 7.15127 11.8068 5.69434 9.99929 5.69434C8.19181 5.69434 6.72656 7.15127 6.72656 8.94848C6.72656 10.7457 8.19181 12.2026 9.99929 12.2026Z" fill="white"/>
      </svg>
    </div>
  );
}

export default MapOutlined;
