import React from 'react';
import { IIconProps } from '..';
import Config from '@/Config';

const UnSelectedOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      {/* <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z" fill="currentColor" fillOpacity="0.2"/>
      </svg> */}
      <img src={`${Config.cdnUrl}/images/unselected.png`} style={{ height: '20PX' }} alt="" />
    </div>
  );
}

export default UnSelectedOutlined;
