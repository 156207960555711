import React from 'react';
import { IIconProps } from '..';

const Loading: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg style={{margin: 'auto', background: 'rgba(242, 243, 248, 0)', display: 'block', shapeRendering: 'auto'}} width="1em" height="1em" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="30" cy="50" fill="currentColor" r="20">
          <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30" begin="-0.5s"></animate>
        </circle>
        <circle cx="70" cy="50" fill="#ced9f2" r="20">
          <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30" begin="0s"></animate>
        </circle>
        <circle cx="30" cy="50" fill="currentColor" r="20">
          <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30" begin="-0.5s"></animate>
          <animate attributeName="fill-opacity" values="0;0;1;1" calcMode="discrete" keyTimes="0;0.499;0.5;1" dur="1s" repeatCount="indefinite"></animate>
        </circle>
      </svg>
    </div>
  );
}

export default Loading;
