import React from 'react';
import { IIconProps } from '..';

const ShoppingCartOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.63274 12.1572C7.61947 12.2747 7.58202 12.3882 7.52273 12.4905L6.88821 13.5895H16.055C16.2829 13.5895 16.5015 13.6801 16.6627 13.8412C16.8239 14.0024 16.9144 14.221 16.9144 14.4489C16.9144 14.6769 16.8239 14.8954 16.6627 15.0566C16.5015 15.2178 16.2829 15.3083 16.055 15.3083H5.45573C5.33924 15.3083 5.22395 15.2847 5.11689 15.2387C5.00983 15.1928 4.91322 15.1256 4.83293 15.0412C4.75265 14.9568 4.69036 14.8569 4.64986 14.7477C4.60936 14.6385 4.59149 14.5222 4.59733 14.4058C4.5867 14.2372 4.62622 14.0693 4.71092 13.9231L5.7548 12.1151L5.5961 11.523C5.56184 11.4542 5.53459 11.3821 5.51474 11.3078L3.98 5.57848C3.96027 5.50493 3.94789 5.4296 3.94304 5.3536L3.2803 2.88039L1.26672 2.34083C1.04923 2.27948 0.864652 2.13494 0.752953 1.93849C0.641254 1.74205 0.611423 1.50952 0.669919 1.29124C0.728415 1.07296 0.87053 0.886509 1.06549 0.772249C1.26046 0.657988 1.49258 0.625116 1.71161 0.680751L4.20186 1.34793C4.35576 1.38913 4.49517 1.47229 4.60454 1.58813C4.71391 1.70397 4.78892 1.84794 4.82121 2.00394C4.83023 2.02843 4.83825 2.0535 4.84513 2.07928L5.39629 4.13612H17.8562C18.0319 4.13611 18.2053 4.17653 18.363 4.25426C18.5206 4.33198 18.6583 4.44493 18.7653 4.58437C18.8723 4.7238 18.9458 4.88598 18.9801 5.05836C19.0144 5.23074 19.0086 5.4087 18.9631 5.57848L17.4283 11.3078C17.3631 11.5515 17.2192 11.7668 17.0191 11.9204C16.819 12.0739 16.5738 12.1572 16.3216 12.1572H7.63274ZM6.31513 19.4621C5.36592 19.4621 4.59633 18.6925 4.59633 17.7433C4.59633 16.7941 5.36592 16.0245 6.31513 16.0245C7.26434 16.0245 8.03393 16.7941 8.03393 17.7433C8.03393 18.6925 7.26434 19.4621 6.31513 19.4621ZM15.6253 19.4621C14.6761 19.4621 13.9065 18.6925 13.9065 17.7433C13.9065 16.7941 14.6761 16.0245 15.6253 16.0245C16.5745 16.0245 17.3441 16.7941 17.3441 17.7433C17.3441 18.6925 16.5745 19.4621 15.6253 19.4621Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default ShoppingCartOutlined;
