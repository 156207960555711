import React from 'react';
import { IIconProps } from '..';

const LeftArrowOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 13C1 12.4477 1.44772 12 2 12H9.53636C9.59159 12 9.63636 11.9552 9.63636 11.9V6.61056C9.63636 6.36143 9.92246 6.22085 10.1197 6.37309L19.3037 13.4625C19.5303 13.6374 19.4066 14 19.1204 14H2C1.44771 14 1 13.5523 1 13V13Z" fill="currentColor"/>
      </svg>
    </div>
  );
}

export default LeftArrowOutlined;
