import React from 'react';
import { IIconProps } from '..';

const NotFavoriteOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0611 2.21371C12.5273 0.357921 15.8851 0.680912 18.143 2.83646L18.1531 2.84608L18.1629 2.85602C20.6199 5.35794 20.6548 9.46211 18.0111 11.859L11.7505 18.2341C10.7476 19.2553 9.13517 19.2553 8.13233 18.2341L1.8535 11.8404C-0.617835 9.32391 -0.617835 5.37256 1.8535 2.85602L1.86327 2.84608L1.87334 2.83646C4.14589 0.666968 7.5009 0.369204 10.0611 2.21371ZM16.9591 4.09634C15.1228 2.3524 12.438 2.27444 10.6619 3.96996L10.0925 4.51354L9.5072 3.98766C7.58245 2.25829 4.88289 2.36264 3.05726 4.09635C1.2634 5.93327 1.26664 8.77676 3.06699 10.61L9.34581 17.0037C9.67887 17.3429 10.2039 17.3429 10.537 17.0037V17.0037L16.8347 10.5908L16.8548 10.5728C18.7289 8.88891 18.7608 5.94168 16.9591 4.09634Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default NotFavoriteOutlined;
