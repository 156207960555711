import React from 'react';
import { IIconProps } from '..';

const ShoppingOutlined: React.FC<IIconProps> = props => {
  const { className = '', onClick } = props;
  return (
    <div className={`bm-icon ${className}`} onClick={onClick}>
      <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7441 7.38566C16.6958 7.00341 16.5033 6.65135 16.203 6.39595C15.9027 6.14054 15.5154 5.99948 15.1141 5.99938H4.88551C4.48429 5.99957 4.09702 6.14068 3.7968 6.39608C3.49659 6.65147 3.3042 7.00348 3.25595 7.38566L2.01192 17.2341C1.98398 17.4554 2.00529 17.6799 2.07444 17.8927C2.14358 18.1054 2.25899 18.3017 2.41304 18.4686C2.56709 18.6354 2.75628 18.769 2.96811 18.8605C3.17993 18.952 3.40958 18.9993 3.64189 18.9994H16.3585C16.5908 18.9993 16.8204 18.9519 17.0322 18.8604C17.244 18.7688 17.4331 18.6353 17.5871 18.4684C17.7411 18.3016 17.8565 18.1053 17.9256 17.8926C17.9947 17.6798 18.016 17.4554 17.9881 17.2341L16.7441 7.38566ZM16.4999 17.422C16.4999 17.422 16.5001 17.4256 16.499 17.4291C16.4978 17.4328 16.4943 17.4408 16.4849 17.451M16.4999 17.422L15.2559 7.57364C15.2559 7.57364 15.2536 7.55758 15.2312 7.53858C15.2074 7.51828 15.1661 7.4994 15.1137 7.49938H4.88622V7.49938C4.83397 7.49944 4.79257 7.51832 4.76874 7.53859C4.74641 7.55758 4.74414 7.57353 4.74414 7.57353L3.50011 17.422C3.50011 17.422 3.49989 17.4257 3.50101 17.4291C3.50222 17.4328 3.50567 17.4408 3.51509 17.451C3.52467 17.4614 3.54022 17.4737 3.56292 17.4835C3.58574 17.4933 3.61297 17.4994 3.64224 17.4994H16.3578C16.3871 17.4994 16.4143 17.4933 16.4371 17.4835C16.4598 17.4736 16.4753 17.4613 16.4849 17.451" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 2.5C9.49123 2.5 9.01022 2.69283 8.66109 3.02534C8.31319 3.35666 8.125 3.79801 8.125 4.25V8.75H6.625V4.25C6.625 3.37591 6.98993 2.54548 7.6266 1.93913C8.26203 1.33396 9.11638 1 10 1C10.8836 1 11.738 1.33396 12.3734 1.93913C13.0101 2.54548 13.375 3.37591 13.375 4.25V8.75H11.875V4.25C11.875 3.79801 11.6868 3.35666 11.3389 3.02534C10.9898 2.69283 10.5088 2.5 10 2.5ZM7 15.5V14H13V15.5H7Z" fill="currentColor" />
      </svg>
    </div>
  );
}

export default ShoppingOutlined;
